import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Theme from './theme';

const ConfirmDialog = ({ show, proceed, dismiss, cancel, title, message, theme, options }) => {
  return (
    <Theme>
      <Dialog className={theme} open={show} onClose={cancel} >
        <DialogTitle className={theme}>{title}</DialogTitle>
        <DialogContent className={theme}>
          <DialogContentText className={theme}>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={theme}  onClick={() => cancel()} color="primary">No</Button>
          <Button className={theme}  onClick={() => proceed()} color="primary">Yes</Button>
        </DialogActions>
      </Dialog>
    </Theme>
  )
}

ConfirmDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
  title: PropTypes.string,         // arguments of your confirm function
  message: PropTypes.string,       // arguments of your confirm function
  theme: PropTypes.string,         // arguments of your confirm function
  options: PropTypes.object        // arguments of your confirm function
}

export default confirmable(ConfirmDialog);
