import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Converter from '../../../util/converter.js'
import AsyncButton from '../../../components/async_button.js';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MultilineTextField from '../../../components/multiline_textfield.js';
import EventBus from '../../../components/event_bus.js';
import ErrorHandler from '../../../util/error_handler.js';
import { hasType } from '../../../services/authentication_service.js';
import DomUtil from '../../../util/dom_util.js';
import confirm from '../../../components/confirm.js'
import { getCachedEnvironment } from '../../../services/environment_service.js';
import { priceQuote, acceptQuote, declineQuote } from '../../../services/quote_service.js';
import { isReadOnly } from '../../../services/authentication_service.js';

class ProvideQuoteContainer extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      environment: getCachedEnvironment(),
      is_editing: false,
      quote_price: {},
      review_quote: {}
    };
  }

  componentDidUpdate(prevProps) {
    if(!prevProps.quote && this.props.quote) {
      if(this.props.quote.status === "pending" || this.props.quote.status === "quoted") {
        this.setQuotePricing();
      }
    }
  }

  setQuotePricing() {
    if(!this.props.quote.shipping_costs || this.props.quote.shipping_costs.length === 0) {
      this.props.quote.shipping_costs = [ { key: this.getUniqueKey(), name: "Shipping costs" } ];
    }

    var quote_price = {
      shipping_costs: this.props.quote.shipping_costs,
      taxes: this.props.quote.taxes,
      validity_days: 30,
      insurance_costs: this.props.quote.insurance_costs,
      insured_value: this.props.quote.insured_value,
      etd: this.props.quote.etd,
      eta: this.props.quote.eta,
      transit_time_days: this.props.quote.transit_time_days,
      comment: this.props.quote.costs_comment
    };

    this.setState({ quote_price: quote_price }, () => {
      this.calculateInsurance();
    });
  }
  
  priceQuote(e) {
    e.preventDefault();
    var form = e.currentTarget;
    confirm("Please confirm", "Are you sure to submit this quote?").then(() => {
      DomUtil.disableFormSubmitButtonClass(form);
      var quote_price = this.state.quote_price;
      priceQuote(this.props.quote_id, quote_price).then((quote) => {
        EventBus.dispatch("updateQuote");
        quote_price.comment = undefined;
        this.setState({ quote_price: quote_price, is_editing: false });
        this.props.quoteUpdated(quote);
        DomUtil.enableFormSubmitButtonClass(form);
      }).catch(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      });
    });
  }
  
  acceptQuote(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirm("Please confirm", "Are you sure to accept this quote?").then(() => {
      DomUtil.disableClass(button);

      var comment = { message: this.state.review_quote.comment };
      acceptQuote(this.props.quote_id, comment).then((quote) => {
        this.setState({ accepted: true });
        EventBus.dispatch("updateQuote");
        this.props.quoteUpdated(quote);
        DomUtil.enableClass(button);
      }).catch(() => {
        DomUtil.enableClass(button);
      });
    });
  }

  declineQuote(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirm("Please confirm", "Are you sure to decline this quote?").then(() => {
      DomUtil.disableClass(button);

      var comment = { message: this.state.review_quote.comment };
      declineQuote(this.props.quote_id, comment).then((quote) => {
        EventBus.dispatch("updateQuote");
        this.props.quoteUpdated(quote);
        DomUtil.enableClass(button);
      }).catch(() => {
        DomUtil.enableClass(button);
      });
    });
  }

  hasStatus(status) {
    if(!this.props.quote) {
      return false;
    }
    return this.props.quote.status === status;
  }

  isForwarderEditable() {
    if(!this.props.quote) {
      return false;
    }
    return this.props.quote.status === "quoted";
  }

  forwarderEditQuote(e) {
    e.preventDefault();
    this.setState({ is_editing: true});
  }

  forwarderCancelEditQuote(e) {
    e.preventDefault();
    this.setState({ is_editing: false});
  }

  calculateQuote(e) {
    this.props.history.push(`/calculator?base_quote_id=${this.props.quote_id}`);
  }
  
  handleQuoteProvidedChange(e) {
    var name = e.target.name;
    var value = e.target.value === "" ? null : e.target.value;
    this.state.quote_price[name] = value;
    this.setState({ quote_price: this.state.quote_price });
  }

  handleQuoteReviewChange(e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.state.review_quote[e.target.name] = value;
    this.setState({ review_quote: this.state.review_quote })
  }
  
  calculateInsurance() {
    var quote_price = this.state.quote_price;

    var insured_value = 0;
    if(this.props.quote.insurance === "cargo_value") {
      insured_value = this.props.quote.cargo_value;
    } else if(this.props.quote.insurance === "full") {
      insured_value = Number(this.props.quote.cargo_value) + Number(this.getTotalCosts(this.state.quote_price.shipping_costs));
    }

    if(!this.state.environment || !this.state.environment.insurance_percentage || !this.state.environment.min_insurance_amount) {
      quote_price.insured_value = insured_value;
      this.setState({ quote_price: quote_price });
      return;
    }

    var insurance_costs = insured_value * (Number(this.state.environment.insurance_percentage) / 100);
    var min_insurance_amount = Number(this.state.environment.min_insurance_amount);
    if(insurance_costs < min_insurance_amount) {
      insurance_costs = min_insurance_amount;
    }

    quote_price.insurance_costs = insurance_costs;
    quote_price.insured_value = insured_value;
    this.setState({ quote_price: quote_price });
  }

  getTotalCostRows() {
    var rows = [];
    
    rows.push(
      <tr className="row-no-top-border-and-padding row-bold">
        <td width={300} className="header-text">Total costs*</td>
        <td>{Converter.toCurrency("eur", this.getTotal())}</td>
      </tr>
    );
    
    
    rows.push(
      <tr>
        <td width={300} className="header-text">Shipping costs</td>
        <td>{Converter.toCurrency("eur", this.getTotalCosts(this.props.quote.shipping_costs))}</td>
      </tr>
    );
    
    if(this.props.quote.shipping_costs) {
      for(const shipping_cost of this.props.quote.shipping_costs) {
        rows.push(
          <tr>
            <td width={300} className="header-text">{this.indented(shipping_cost.name)}</td>
            <td>{this.indented(Converter.toCurrency("eur", shipping_cost.value))}</td>
          </tr>
        );
      }
    }
    
    if(this.props.quote.taxes && this.props.quote.taxes.length > 0) {
      rows.push(
        <tr>
          <td width={300} className="header-text">Taxes</td>
          <td>{Converter.toCurrency("eur", this.getTotalCosts(this.props.quote.taxes))}</td>
        </tr>
      );
    
      for(const tax of this.props.quote.taxes) {
        rows.push(
          <tr>
            <td width={300} className="header-text">{this.indented(tax.name)}</td>
            <td>{this.indented(Converter.toCurrency("eur", tax.value))}</td>
          </tr>
        );
      }
    }

    if(this.props.quote.insurance && (this.props.quote.insurance === "full" || this.props.quote.insurance === "cargo_value")) {
      rows.push(
        <tr>
          <td width={300} className="header-text">Insurance costs</td>
          <td>{Converter.toCurrency("eur", this.props.quote.insurance_costs)}</td>
        </tr>
      );
    }

    if(this.props.quote.platform_fee) {
      rows.push(
        <tr>
          <td width={300} className="header-text">Platform fee</td>
          <td>{Converter.toCurrency("eur", this.props.quote.platform_fee)}</td>
        </tr>
      );
    }
  
    return rows;
  }

  getTotal() {
    var total = this.getTotalCosts(this.props.quote.shipping_costs);
    total += this.getTotalCosts(this.props.quote.taxes);
    if(this.props.quote.insurance_costs) {
      total += this.props.quote.insurance_costs;
    }
    if(this.props.quote.platform_fee) {
      total += this.props.quote.platform_fee;
    }
    return total;
  }

  getTotalCosts(costs) {
    var total = 0;
    if(costs) {
      for(const cost of costs) {
        if(cost.value) {
          total += Number(cost.value);
        }
      }
    }
    return total;
  }

  indented(element) {
    return <i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{element}</i>;
  }

  addCostToList(property) {
    if(!this.state.quote_price[property]) {
      this.state.quote_price[property] = [];
    }
    this.state.quote_price[property].push({ key: this.getUniqueKey() });
    this.setState({ quote_price: this.state.quote_price });
  }

  removeCostFromList(property, index) {
    this.state.quote_price[property].splice(index, 1);
    this.setState({ quote_price: this.state.quote_price }, () => {
      this.calculateInsurance();
    });
  }

  handleCostChange(property, index, sub_property, e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.state.quote_price[property][index][sub_property] = value;
    this.setState({ quote_price: this.state.quote_price }, () => {
      this.calculateInsurance();
    });
  }

  getUniqueKey() {
    return new Date().getTime() + Math.random();
  }

  render() {
    return (
      <div>        
        { (!isReadOnly() && this.props.quote && (this.hasStatus("pending") || this.state.is_editing)) && hasType("forwarder") &&
            <div className="container-body">
              <div className="row">
                <div className="col-md">
                  <div className="form-header">
                    <label>Provide quote</label>
                  </div>
                </div>
              </div>

              <form autoComplete="off" onSubmit={this.priceQuote.bind(this)}>
                
                <div className="row row-small">
                  <div className="col-md-6 col-lg-6">
                    <table className="table table-details" >
                      <tbody>
                        <tr className="row-no-top-border">
                          <td width={200} className="header-text">Total shipping costs</td>
                          <td>{Converter.toCurrency("eur", this.getTotalCosts(this.state.quote_price.shipping_costs))}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                
                {this.state.quote_price.shipping_costs && this.state.quote_price.shipping_costs.map((shipping_cost, index) => {
                  return (
                    <div key={shipping_cost.key} className="row row-small">
                      <div className="col-md-6 col-lg-4">
                        <TextField
                          label="Shipping cost name"
                          variant="outlined"
                          required
                          fullWidth
                          margin="dense"
                          value={shipping_cost.name}
                          onChange={this.handleCostChange.bind(this, "shipping_costs", index, "name")}
                        />
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <TextField
                          label="Shipping cost"
                          variant="outlined"
                          required
                          fullWidth
                          type="number"
                          margin="dense"
                          value={shipping_cost.value}
                          onChange={this.handleCostChange.bind(this, "shipping_costs", index, "value")}
                        />
                      </div>
                      { index > 0 &&
                        <div className="end-of-col">
                          <Button
                            className="button-icon-only button-inline clear-list-row-item-button"
                            color="secondary"
                            type="button"
                            onClick={this.removeCostFromList.bind(this, "shipping_costs", index)}
                            startIcon={<Icon>clear</Icon>} />
                        </div>
                      }                        
                    </div>
                  );
                })}

                <div className="row row-small">
                  <div className="col-md">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={this.addCostToList.bind(this, "shipping_costs")}
                      startIcon={<Icon>playlist_add</Icon>}>
                      Add shipping cost
                    </Button>
                  </div>
                </div>
                
                <div className="row row-small">
                  <div className="col-md-6 col-lg-6">
                    <table className="table table-details" >
                      <tbody>
                        <tr className="row-no-top-border">
                          <td width={200} className="header-text">Total taxes</td>
                          <td>{Converter.toCurrency("eur", this.getTotalCosts(this.state.quote_price.taxes))}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                
                {this.state.quote_price.taxes && this.state.quote_price.taxes.map((tax, index) => {
                  return (
                    <div key={tax.key} className="row row-small">
                      <div className="col-md-6 col-lg-4">
                        <TextField
                          label="Tax name"
                          variant="outlined"
                          required
                          fullWidth
                          margin="dense"
                          value={tax.name}
                          onChange={this.handleCostChange.bind(this, "taxes", index, "name")}
                        />
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <TextField
                          label="Tax"
                          variant="outlined"
                          required
                          fullWidth
                          type="number"
                          margin="dense"
                          value={tax.value}
                          onChange={this.handleCostChange.bind(this, "taxes", index, "value")}
                        />
                      </div>
                      <div className="end-of-col">
                        <Button
                          className="button-icon-only button-inline clear-list-row-item-button"
                          color="secondary"
                          type="button"
                          onClick={this.removeCostFromList.bind(this, "taxes", index)}
                          startIcon={<Icon>clear</Icon>} />
                      </div>                 
                    </div>
                  );
                })}

                <div className="row row-small">
                  <div className="col-md">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={this.addCostToList.bind(this, "taxes")}
                      startIcon={<Icon>playlist_add</Icon>}>
                      Add taxes
                    </Button>
                  </div>
                </div>

                <div className="row row-small">
                  <div className="col-md-6 col-lg-4">
                    <TextField
                        label="Validity days"
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        value={this.state.quote_price.validity_days}
                        name="validity_days"
                        onChange={this.handleQuoteProvidedChange.bind(this)}
                      />
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <TextField
                      label="Transit time (days)"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      type="number"
                      value={this.state.quote_price.transit_time_days}
                      name="transit_time_days"
                      onChange={this.handleQuoteProvidedChange.bind(this)}
                    />
                  </div>
                </div>
                
                
                { this.props.quote.insurance && (this.props.quote.insurance === "full" || this.props.quote.insurance === "cargo_value") &&
                  <div className="row row-small">
                    <div className="col-md-6 col-lg-4">
                      <TextField
                          label="Insurance costs"
                          variant="outlined"
                          required
                          fullWidth
                          margin="dense"
                          value={this.state.quote_price.insurance_costs}
                          name="insurance_costs"
                          onChange={this.handleQuoteProvidedChange.bind(this)}
                          InputProps={{
                            inputComponent: Converter.numberFormatter,
                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                          }}
                        />
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <TextField
                          label="Insured value"
                          variant="outlined"
                          required
                          fullWidth
                          margin="dense"
                          value={this.state.quote_price.insured_value}
                          name="insured_value"
                          disabled={true}
                          onChange={this.handleQuoteProvidedChange.bind(this)}
                          InputProps={{
                            inputComponent: Converter.numberFormatter,
                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                          }}
                        />
                    </div>
                  </div>
                }
                
                <div className="row row-small">
                  <div className="col-md-6 col-lg-4">
                    <TextField
                      label="ETD"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.quote_price.etd}
                      name="etd"
                      onChange={this.handleQuoteProvidedChange.bind(this)}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <TextField
                      label="ETA"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.quote_price.eta}
                      name="eta"
                      onChange={this.handleQuoteProvidedChange.bind(this)}
                    />
                  </div>
                </div>
                
                <div className="row row-small">
                  <div className="col-md-12 col-lg-8">
                    <MultilineTextField
                      label="Comment"
                      variant="outlined"
                      fullWidth
                      min_rows={4}
                      margin="dense"
                      value={this.state.quote_price.comment}
                      name="comment"
                      onChange={this.handleQuoteProvidedChange.bind(this)}
                      InputLabelProps={{
                        shrink: this.state.quote_price.comment !== undefined,
                      }}
                    />
                  </div>
                </div>

                <div className="row row-small">
                  <div className="col-md">
                    <AsyncButton
                      type="submit"
                      variant="outlined"
                      color="primary"
                      icon="check"
                      text="Submit" />
                    { this.state.is_editing &&
                      <AsyncButton
                        className="button-danger-outlined"
                        variant="outlined"
                        color="secondary"
                        onClick={this.forwarderCancelEditQuote.bind(this)}
                        icon="close"
                        text="Cancel" />
                    }
                    <AsyncButton
                      variant="outlined"
                      color="secondary"
                      onClick={this.calculateQuote.bind(this)}
                      icon="calculate"
                      text="Calculate" />
                  </div>
                </div>

              </form>
            
            </div>
          }

          { this.props.quote && this.hasStatus("pending") && hasType("customer") && this.props.quote.costs_comment &&
            <div className="container-body">
              <div className="row">
                <div className="col-md">
                  <div className="form-header">
                    <label>Proposed quote</label>
                  </div>
                </div>
              </div>
              
              <div className="row row-small">
                <div className="col-md-6 col-lg-6">
                  <Alert severity="warning">{this.props.quote.costs_comment}</Alert>
                </div>
              </div>   
            </div>
          }

          { this.props.quote && !this.hasStatus("pending") && !this.state.is_editing  &&
            <div className="container-body">
              <div className="row">
                <div className="col-md">
                  <div className="form-header">
                    <label>Proposed quote</label>
                  </div>
                </div>
              </div>
              
              {!isReadOnly() && hasType("forwarder") && this.isForwarderEditable() &&
                <div className="row row-small">
                  <div className="col-md">
                    { this.isForwarderEditable() &&
                      <AsyncButton
                        variant="outlined"
                        color="secondary"
                        onClick={this.forwarderEditQuote.bind(this)}
                        icon="edit"
                        text="Edit" />
                    }
                    <AsyncButton
                      variant="outlined"
                      color="secondary"
                      onClick={this.calculateQuote.bind(this)}
                      icon="calculate"
                      text="Calculate" />
                  </div>
                </div>
              }

              {this.props.quote.costs_comment &&
                <div className="row row-small">
                  <div className="col-md-6 col-lg-6">
                    <Alert severity="warning">{this.props.quote.costs_comment}</Alert>
                  </div>
                </div>
              }
              
              <div className="row row-small">
                <div className="col-md-6 col-lg-6">
                  <table className="table table-details" >
                    <tbody>
                      {this.getTotalCostRows()}
                    </tbody>
                  </table>
                </div>
                <div className="col-md-6 col-lg-6">
                  <table className="table table-details" >
                    <tbody>
                      <tr className="row-no-top-border-and-padding">
                        <td width={200} className="header-text">Valid until</td>
                        <td>{Converter.toDate(this.props.quote.valid_until)}</td>
                      </tr>
                      { this.props.quote.insurance && (this.props.quote.insurance === "full" || this.props.quote.insurance === "cargo_value") &&
                        <tr>
                          <td width={200} className="header-text">Insured value</td>
                          <td>{Converter.toCurrency("eur", this.props.quote.insured_value)}</td>
                        </tr>
                      }
                      { this.props.quote.transit_time_days &&
                        <tr>
                          <td width={200} className="header-text">Transit time (days)</td>
                          <td>{Converter.toDigits(this.props.quote.transit_time_days, 0)}</td>
                        </tr>
                      }
                      { this.props.quote.etd &&
                        <tr>
                          <td width={200} className="header-text">ETD</td>
                          <td>{Converter.toDate(this.props.quote.etd)}</td>
                        </tr>
                      }
                      { this.props.quote.eta &&
                        <tr>
                          <td width={200} className="header-text">ETA</td>
                          <td>{Converter.toDate(this.props.quote.eta)}</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>

              { this.state.accepted && this.props.quote.shipment && this.props.quote.shipment.id &&
                <div className="row row-small">
                  <div className="col-md-12 col-lg-12">
                    <Alert severity="success">A new shipment has been created, you can view it <Link to={`/shipments/all/${this.props.quote.shipment.id}`}>here</Link>.</Alert>
                  </div>
                </div>
              }
              
              {!isReadOnly() && this.hasStatus("quoted") && hasType("customer")  &&
                <form autoComplete="off" onSubmit={this.acceptQuote.bind(this)}>
                  
                  <div className="row row-small">
                    <div className="col-md-12 col-lg-6">
                      <MultilineTextField
                        label="Comment"
                        variant="outlined"
                        fullWidth
                        min_rows={4}
                        margin="dense"
                        value={this.state.review_quote.comment}
                        name="comment"
                        onChange={this.handleQuoteReviewChange.bind(this)}
                        InputLabelProps={{
                          shrink: this.state.review_quote.comment !== undefined,
                        }}
                      />
                    </div>
                  </div>
                  
                  <div className="row row-small">
                    <div className="col-md">
                      <AsyncButton
                        type="submit"
                        variant="outlined"
                        color="primary"
                        icon="check"
                        text="Accept" />
                      <AsyncButton
                        className="button-danger-outlined"
                        variant="outlined"
                        onClick={this.declineQuote.bind(this)}
                        icon="clear"
                        text="Decline" />
                    </div>
                  </div>

                </form>
              }

              <div className="row row-small">
                <div className="col-md-12">
                  <span className='quote-disclaimer'>*Storage and transport are subject to the logistics provider's terms. Contact us for details.</span>
                </div>
              </div>
            
            </div>
          }
      </div>
    );
  }
}

export default ProvideQuoteContainer;
