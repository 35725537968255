import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AsyncButton from '../../../../shared/components/async_button';
import MultilineTextField from '../../../../shared/components/multiline_textfield';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import Mapper from '../../../../shared/util/mapper'
import Converter from '../../../../shared/util/converter'
import Calculator from '../../../../shared/util/calculator.js';
import AddressForm from './address_form'
import WareHouseForm from './warehouse_form'
import PortForm from './port_form'
import confirm from '../../../../shared/components/confirm.js'
import confirmPallet from '../../../../shared/components/confirm_pallet.js'
import PalletsTable from '../../../../shared/components/pallets_table.js';
import confirmPackage from '../../../../shared/components/confirm_package.js'
import PackagesTable from '../../../../shared/components/packages_table.js';
import confirmContainer from '../../../../shared/components/confirm_container.js'
import ContainersTable from '../../../../shared/components/containers_table.js';
import confirmQuotePalletsImport from '../../../../shared/components/quote_confirm_pallets_import.js'
import confirmQuoteColliImport from '../../../../shared/components/quote_confirm_colli_import.js'
import { hasModule } from '../../../../shared/services/environment_service';

class QuoteForm extends React.Component {

  constructor(props) {
    super(props)
    this.onLocationUpdate = this.onLocationUpdate.bind(this);

    if(!this.props.quote.pickup_location) {
      this.props.quote.pickup_location = { type: "none" };
    }
    if(!this.props.quote.delivery_location) {
      this.props.quote.delivery_location = { type: "none" };
    }
    if(!this.props.quote.currency) {
      this.props.quote.currency = "eur";
    }

    var shipper_details = "basic";
    if(this.props.quote.shipper) {
      shipper_details = "account";
    }

    var consignee_details = "basic";
    if(this.props.quote.consignee) {
      consignee_details = "account";
    }

    this.state = {
      countries: this.props.countries,
      warehouses: this.props.warehouses,
      ports: this.props.ports,
      account: this.props.account,
      quote: this.props.quote,
      shipper_details: shipper_details,
      consignee_details: consignee_details
    };
  }
  
  firstLetter(option) {
    return option.name[0].toUpperCase();
  }

  handleChange(e) {
    this.state.quote[e.target.name] = e.target.value;
    if(this.state.quote.transport_type !== "fcl") {
      this.state.quote.containers = [];
    }
    if(this.state.quote.transport_type !== "ftl") {
      this.state.quote.truck_quantity = undefined;
    }
    this.setState({ quote: this.state.quote });
  }

  handleShipperDetails(e) {
    this.setState({ shipper_details: e.target.value });
  }

  handleConsigneeDetails(e) {
    this.setState({ consignee_details: e.target.value });
  }

  handleAddressChange(property, e) {
    this.state.quote[property] = { type: e.target.value, country: null };
    this.setState({ quote: this.state.quote });
  }
  
  handleAutoCompleteChange(property, e, newValue) {
    if(newValue) {
      this.state.quote[property].country = newValue;
    } else {
      this.state.quote[property].country = { name: "" };
    }
    this.setState({ quote: this.state.quote });
  }

  handleAutoSelectionChanged(property, e, newValue) {
    if(newValue) {
      this.state.quote[property] = newValue;
    } else {
      this.state.quote[property] = { id: undefined };
    }
    this.setState({ quote: this.state.quote });
  }

  firstAccountLetter(option) {
    return option.name[0].toUpperCase();
  }

  getRelationOptionLabel(value) {
    var account;
    if(value.id) {
      account = this.props.account.relations.find(a => a.id === value.id);
    }
    if(!account) {
      return "";
    }
    return account.name;
  }

  onLocationUpdate(property, location) {
    this.state.quote[property] = location;
    this.setState({ quote: this.state.quote });
  }

  getUniqueKey() {
    return new Date().getTime() + Math.random();
  }

  getTotalContainers() {
    var total = 0;
    this.state.quote.containers.forEach(container => {
      if(container.quantity) {
        total += Number(container.quantity);
      }
    });
    return total;
  }

  addContainerToList() {
    var container = { key: this.getUniqueKey(), index: this.state.quote.containers.length };
    confirmContainer("Add container", container, [ "quantity", "size", "type" ]).then((response_container) => {
      this.state.quote.containers.push(response_container);
      this.setState({ quote: this.state.quote });
    });
  }

  editContainerOfList(index) {
    const clone = JSON.parse(JSON.stringify(this.state.quote.containers[index]));
    clone.index = index;

    confirmContainer("Edit container", clone, [ "quantity", "size", "type" ]).then((response_container) => {
      this.state.quote.containers[index] = response_container;
      this.setState({ quote: this.state.quote });
    });
  }
  
  cloneContainerOfList(index) {
    const clone = JSON.parse(JSON.stringify(this.state.quote.containers[index]));
    clone.key = this.getUniqueKey()
    clone.index = this.state.quote.containers.length;
    
    confirmContainer("Clone container", clone, [ "quantity", "size", "type" ]).then((response_container) => {
      this.state.quote.containers.push(response_container);
      this.setState({ quote: this.state.quote });
    });
  }

  removeContainerFromList(index) {
    confirm("Please confirm", `Are you sure you want to delete Container ${index + 1}?`).then(() => {
      this.state.quote.containers.splice(index, 1);
      this.setState({ quote: this.state.quote });
    }).catch(() => {
      // required to handle cancel
    });
  }

  isLessThanTransport() {
    return this.state.quote.transport_type === "ltl" || this.state.quote.transport_type === "lcl";
  }

  addPalletToList() {
    if(!this.state.quote.pallets) {
      this.state.quote.pallets = [];
    }

    var _package = { key: this.getUniqueKey(), index: this.state.quote.pallets.length };
    confirmPallet("Add pallet", _package, this.isLessThanTransport()).then((response_pallet) => {
      this.state.quote.pallets.push(response_pallet);
      this.setState({ quote: this.state.quote });
    });
  }

  editPalletOfList(index) {
    const clone = JSON.parse(JSON.stringify(this.state.quote.pallets[index]));
    clone.index = index;

    confirmPallet("Edit pallet", clone, this.isLessThanTransport(), false).then((response_pallet) => {
      this.state.quote.pallets[index] = response_pallet;
      this.setState({ quote: this.state.quote });
    });
  }

  clonePalletOfList(index) {
    const clone = JSON.parse(JSON.stringify(this.state.quote.pallets[index]));
    clone.key = this.getUniqueKey()
    clone.index = this.state.quote.pallets.length;
    
    confirmPallet("Clone pallet", clone, this.isLessThanTransport(), false).then((response_pallet) => {
      this.state.quote.pallets.push(response_pallet);
      this.setState({ quote: this.state.quote });
    });
  }

  removePalletFromList(index) {
    confirm("Please confirm", `Are you sure you want to delete Pallet ${index + 1}?`).then(() => {
      this.state.quote.pallets.splice(index, 1);
      this.setState({ quote: this.state.quote });
    }).catch(() => {
      // required to handle cancel
    });
  }

  importPallets() {
    confirmQuotePalletsImport().then((data) => {
      if(!data.pallets) {
        return;
      }

      if(!this.state.quote.pallets) {
        this.state.quote.pallets = [];
      }

      if(data.replace) {
        this.state.quote.pallets = [];
      }

      for(const _package of data.pallets) {
        _package.key = this.getUniqueKey();
        this.state.quote.pallets.push(_package);
      }
      
      this.setState({ quote: this.state.quote });
    });
  }

  getTotalPallets() {
    if(!this.state.quote.pallets) {
      return 0;
    }
    return this.state.quote.pallets.length;
  }

  addPackageToList() {
    if(!this.state.quote.colli) {
      this.state.quote.colli = [];
    }
    var _package = { key: this.getUniqueKey(), index: this.state.quote.colli.length };
    confirmPackage("Add package", _package, this.state.quote.transport_type, false).then((response_package) => {
      this.state.quote.colli.push(response_package);
      this.setState({ quote: this.state.quote });
    });
  }

  editPackageOfList(index) {
    const clone = JSON.parse(JSON.stringify(this.state.quote.colli[index]));
    clone.index = index;

    confirmPackage("Edit package", clone, this.state.quote.transport_type, false).then((response_package) => {
      this.state.quote.colli[index] = response_package;
      this.setState({ quote: this.state.quote });
    });
  }

  clonePackageOfList(index) {
    const clone = JSON.parse(JSON.stringify(this.state.quote.colli[index]));
    clone.key = this.getUniqueKey()
    clone.index = this.state.quote.colli.length;
    
    confirmPackage("Clone package", clone, this.state.quote.transport_type, false).then((response_package) => {
      this.state.quote.colli.push(response_package);
      this.setState({ quote: this.state.quote });
    });
  }

  removePackageFromList(index) {
    confirm("Please confirm", `Are you sure you want to delete Package ${index + 1}?`).then(() => {
      this.state.quote.colli.splice(index, 1);
      this.setState({ quote: this.state.quote });
    }).catch(() => {
      // required to handle cancel
    });
  }

  importColli() {
    confirmQuoteColliImport().then((data) => {
      if(!data.colli) {
        return;
      }

      if(!this.state.quote.colli) {
        this.state.quote.colli = [];
      }

      if(data.replace) {
        this.state.quote.colli = [];
      }

      for(const _package of data.colli) {
        _package.key = this.getUniqueKey();
        this.state.quote.colli.push(_package);
      }
      
      this.setState({ quote: this.state.quote });
    });
  }

  getTotalPackages() {
    if(!this.state.quote.colli) {
      return 0;
    }
    return this.state.quote.colli.length;
  }

  getTotalParts() {
    if(!this.state.quote.colli) {
      return 0;
    }

    var total = 0;
    this.state.quote.colli.forEach(_package => {
      if(_package.parts) {
        total += _package.parts.length;
      }
    });
    return total;
  }

  getTotalWeight() {
    if(!this.state.quote.pallets) {
      return 0;
    }

    var total = 0;
    this.state.quote.pallets.forEach(pallet => {
        total += this.getTotalWeightOfPallet(pallet);
    });
    return total;
  }

  getTotalWeightOfPallet(pallet) {
    if(!pallet.weight_kg) {
      return 0;
    }
    return Number(pallet.weight_kg);
  }

  getTotalVolume() {
    if(!this.state.quote.pallets) {
      return 0;
    }

    var total = 0;
    this.state.quote.pallets.forEach(pallet => {
        total += this.getTotalVolumeOfPallet(pallet);
    });
    return total;
  }

  getTotalVolumeOfPallet(pallet) {
    var volume = 0;
    if(pallet.length_cm && pallet.width_cm && pallet.height_cm) {
      volume = (Number(pallet.length_cm) / 100) * (Number(pallet.width_cm) / 100) * (Number(pallet.height_cm) / 100);
    }
    return volume;
  }

  getLocations() {
    var locations = Object.keys(Mapper.locationTypes());
    if(!hasModule("warehouse")) {
      return locations.filter(function(e) { return e !== 'warehouse' })
    }
    return locations;
  }

  render() {
    return (
      <form autoComplete="off" onSubmit={this.props.saveQuote}>
          
        <div className="container-body">

          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>{this.props.title}</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              
              <div className="row row-small">
                <div className="col-md-6 col-lg-4">
                  <TextField
                    label="Reference"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    value={this.state.quote.reference}
                    name="reference"
                    onChange={this.handleChange.bind(this)} 
                  />
                </div>
                <div className="col-md-6 col-lg-4">
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    fullWidth>
                    <InputLabel>Incoterms *</InputLabel>
                    <Select
                      native
                      required
                      label="Incoterms *"
                      value={this.state.quote.incoterms}
                      name="incoterms"
                      onChange={this.handleChange.bind(this)} >
                        <option key={"incoterms_empty"} value=""></option>
                        {Object.keys(Mapper.incoterms()).map(incoterms => {
                          return (<option key={"incoterms_"+incoterms} value={incoterms}>{Mapper.fromIncoterms(incoterms)}</option>);
                        })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              
              <div className="row row-small">
                <div className="col-md-6 col-lg-4">
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    fullWidth>
                    <InputLabel>Business Type *</InputLabel>
                    <Select
                      native
                      required
                      label="Business Type *"
                      value={this.state.quote.business_type}
                      name="business_type"
                      onChange={this.handleChange.bind(this)} >
                        <option key={"business_type_empty"} value=""></option>
                        {Object.keys(Mapper.businessTypes()).map(business_type => {
                          return (<option key={"business_type_" + business_type} value={business_type}>{Mapper.fromBusinessType(business_type)}</option>);
                        })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              
              <div className="list-row-item">
                <span className="list-row-item-header-text">Shipper details</span>

                <div className="row row-small">
                  <div className="col-md">
                    <FormControl>
                      <RadioGroup
                        row
                        name="shipper_details"
                        value={this.state.shipper_details}
                        onChange={this.handleShipperDetails.bind(this)}
                      >
                        <FormControlLabel  value="basic" control={<Radio />} label="Basic" />
                        <FormControlLabel value="account" control={<Radio />} label="Account" />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                
                {this.state.shipper_details === "basic" &&
                  <div className="row row-small">
                    <div className="col-md-6 col-lg-4">
                      <TextField
                        label="Shipper name"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={this.state.quote.shipper_name}
                        name="shipper_name"
                        onChange={this.handleChange.bind(this)} 
                      />
                    </div>
                  </div>
                }

                {this.state.shipper_details === "basic" &&
                  <div className="row row-small">
                    <div className="col-md-6 col-lg-4">
                      <TextField
                        label="Shipper phone"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={this.state.quote.shipper_phone}
                        name="shipper_phone"
                        onChange={this.handleChange.bind(this)} 
                      />
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <TextField
                        label="Shipper email"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={this.state.quote.shipper_email}
                        name="shipper_email"
                        onChange={this.handleChange.bind(this)} 
                      />
                      </div>
                    </div>
                  }
                  
                  {this.state.shipper_details === "account" &&
                    <div className="row row-small">
                      <div className="col-md-6 col-lg-4">
                        <Autocomplete
                          key="id"
                          options={this.props.account.relations}
                          groupBy={this.firstAccountLetter.bind(this)}
                          getOptionLabel={(option) => this.getRelationOptionLabel(option)}
                          value={this.props.quote.shipper}
                          onChange={this.handleAutoSelectionChanged.bind(this, "shipper")}
                          renderInput={(params) => 
                            <TextField {...params}
                              label="Shipper account"
                              variant="outlined"
                              required
                              fullWidth
                              margin="dense"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                  }
              </div>
              
              <div className="list-row-item">
                <span className="list-row-item-header-text">Consignee details</span>

                <div className="row row-small">
                  <div className="col-md">
                    <FormControl>
                      <RadioGroup
                        row
                        name="consignee_details"
                        value={this.state.consignee_details}
                        onChange={this.handleConsigneeDetails.bind(this)}
                      >
                        <FormControlLabel  value="basic" control={<Radio />} label="Basic" />
                        <FormControlLabel value="account" control={<Radio />} label="Account" />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                
                {this.state.consignee_details === "basic" &&
                  <div className="row row-small">
                    <div className="col-md-6 col-lg-4">
                      <TextField
                        label="Consignee name"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={this.state.quote.consignee_name}
                        name="consignee_name"
                        onChange={this.handleChange.bind(this)} 
                      />
                    </div>
                  </div>
                }

                {this.state.consignee_details === "basic" &&
                  <div className="row row-small">
                    <div className="col-md-6 col-lg-4">
                      <TextField
                        label="Consignee phone"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={this.state.quote.consignee_phone}
                        name="consignee_phone"
                        onChange={this.handleChange.bind(this)} 
                      />
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <TextField
                        label="Consignee email"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={this.state.quote.consignee_email}
                        name="consignee_email"
                        onChange={this.handleChange.bind(this)} 
                      />
                      </div>
                    </div>
                  }
                  
                  {this.state.consignee_details === "account" &&
                    <div className="row row-small">
                      <div className="col-md-6 col-lg-4">
                        <Autocomplete
                          key="id"
                          options={this.props.account.relations}
                          groupBy={this.firstAccountLetter.bind(this)}
                          getOptionLabel={(option) => this.getRelationOptionLabel(option)}
                          value={this.props.quote.consignee}
                          onChange={this.handleAutoSelectionChanged.bind(this, "consignee")}
                          renderInput={(params) => 
                            <TextField {...params}
                              label="Consignee account"
                              variant="outlined"
                              required
                              fullWidth
                              margin="dense"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                  }
              </div>

              <div className="list-row-item">
                <span className="list-row-item-header-text">Pickup location</span>

                <div className="row row-small">
                  <div className="col-md">
                    <FormControl>
                      <RadioGroup
                        row
                        name="pickup_location"
                        value={this.state.quote.pickup_location.type}
                        onChange={this.handleAddressChange.bind(this, "pickup_location")}
                      >
                      {this.getLocations().map(location_type => {
                          return (<FormControlLabel 
                            value={location_type}
                            control={<Radio />}
                            label={Mapper.fromLocationType(location_type)}
                            />);
                        })}
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                
                { this.state.quote.pickup_location.type === "address" &&
                  <AddressForm 
                    property={"pickup_location"}
                    location={this.state.quote.pickup_location}
                    account={this.state.account}
                    countries={this.state.countries}
                    onLocationUpdate={this.onLocationUpdate}
                  />
                }
                
                { this.state.quote.pickup_location.type === "warehouse" &&
                  <WareHouseForm 
                    property={"pickup_location"}
                    location={this.state.quote.pickup_location}
                    warehouses={this.state.warehouses}
                    onLocationUpdate={this.onLocationUpdate}
                  />
                }
                
                { this.state.quote.pickup_location.type === "port" &&
                  <PortForm 
                    property={"pickup_location"}
                    location={this.state.quote.pickup_location}
                    ports={this.state.ports}
                    onLocationUpdate={this.onLocationUpdate}
                  />
                }
                
              </div>
              
              <div className="list-row-item">
                <span className="list-row-item-header-text">Delivery location</span>
      
                <div className="row row-small">
                  <div className="col-md">
                    <FormControl>
                      <RadioGroup
                        row
                        name="delivery_location"
                        value={this.state.quote.delivery_location.type}
                        onChange={this.handleAddressChange.bind(this, "delivery_location")}
                      >
                      {this.getLocations().map(location_type => {
                          return (<FormControlLabel 
                            value={location_type}
                            control={<Radio />}
                            label={Mapper.fromLocationType(location_type)}
                            />);
                        })}
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                
                { this.state.quote.delivery_location.type === "address" &&
                  <AddressForm 
                    property={"delivery_location"}
                    location={this.state.quote.delivery_location}
                    account={this.state.account}
                    countries={this.state.countries}
                    onLocationUpdate={this.onLocationUpdate}
                  />
                }
                
                { this.state.quote.delivery_location.type === "warehouse" &&
                  <WareHouseForm 
                    property={"delivery_location"}
                    location={this.state.quote.delivery_location}
                    warehouses={this.state.warehouses}
                    onLocationUpdate={this.onLocationUpdate}
                  />
                }
                
                { this.state.quote.delivery_location.type === "port" &&
                  <PortForm 
                    property={"delivery_location"}
                    location={this.state.quote.delivery_location}
                    ports={this.state.ports}
                    onLocationUpdate={this.onLocationUpdate}
                  />
                }
                
              </div>
            </div>
          </div>
        
        </div>
        
        <div className="container-body">

          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Cargo Details</label>
              </div>
            </div>
          </div>

          <div className="row row-small">
            <div className="col-md-6 col-lg-4">
              <FormControl
                variant="outlined"
                margin="dense"
                fullWidth>
                <InputLabel>Currency *</InputLabel>
                <Select
                  native
                  required
                  label="Currency *"
                  value={this.state.quote.currency}
                  name="currency"
                  onChange={this.handleChange.bind(this)} >
                    {Object.keys(Mapper.currencies()).map(currency => {
                      return (<option key={"currency_"+currency} value={currency}>{Mapper.fromCurrency(currency)}</option>);
                    })}
                </Select>
              </FormControl>
            </div>
            <div className="col-md-6 col-lg-4">
              <TextField
                label="Cargo Value"
                variant="outlined"
                required={this.state.quote.insurance === "full" || this.state.quote.insurance === "cargo_value"}
                fullWidth
                margin="dense"
                value={this.state.quote.cargo_value}
                name="cargo_value"
                onChange={this.handleChange.bind(this)}
                InputProps={{
                  inputComponent: Converter.numberFormatter,
                  startAdornment: <InputAdornment position="start">{Mapper.fromCurrencyShort(this.state.quote.currency)}</InputAdornment>,
                }}
              />
            </div>
          </div>

          <div className="row row-small">
            <div className="col-md-6 col-lg-4">
              <FormControl
                variant="outlined"
                margin="dense"
                fullWidth>
                <InputLabel>Transport type *</InputLabel>
                <Select
                  native
                  required
                  label="Transport type *"
                  value={this.state.quote.transport_type}
                  name="transport_type"
                  onChange={this.handleChange.bind(this)} >
                    <option key={"transport_type_empty"} value=""></option>
                    {Object.keys(Mapper.transportTypes()).map(transport_type => {
                      return (<option key={"transport_type_"+transport_type} value={transport_type}>{Mapper.fromTransportType(transport_type)}</option>);
                    })}
                </Select>
              </FormControl>
            </div>
            <div className="col-md-6 col-lg-4">
              <FormControl
                variant="outlined"
                margin="dense"
                fullWidth>
                <InputLabel>Insurance *</InputLabel>
                <Select
                  native
                  required
                  label="Insurance. *"
                  value={this.state.quote.insurance}
                  name="insurance"
                  onChange={this.handleChange.bind(this)} >
                    <option key="insurance_empty" value=""></option>
                    {Object.keys(Mapper.insuranceTypes()).map(insurance_type => {
                      return (<option key={"insurance_type_"+insurance_type} value={insurance_type}>{Mapper.fromInsuranceType(insurance_type)}</option>);
                    })}
                </Select>
              </FormControl>
            </div>
          </div>
          
          <div className="row row-small">
            <div className="col-md-12 col-lg-8">
              <MultilineTextField
                label="Cargo description"
                variant="outlined"
                required
                fullWidth
                min_rows={4}
                margin="dense"
                value={this.state.quote.cargo_description}
                name="cargo_description"
                onChange={this.handleChange.bind(this)}
                InputLabelProps={{
                  shrink: this.state.quote.cargo_description !== undefined,
                }}
              />
            </div>
          </div>
        
        </div>

        
        {(this.state.quote.transport_type === "fcl") &&
          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Container Details</label>
                </div>
              </div>
            </div>

            <div className="row row-small">
              <div className="col-md-6 col-lg-4">
                <table className="table table-details" >
                  <tbody>
                    <tr className="row-no-top-border-and-padding">
                      <td width={200} className="header-text">Total containers</td>
                      <td>{Converter.formatNumber(this.getTotalContainers(), 0)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row">
              <div className="col-md">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={this.addContainerToList.bind(this)}
                  startIcon={<Icon>playlist_add_check</Icon>}>
                  Add container
                </Button>
              </div>
            </div>
            
            <div className="row">
              <div className="col-sm">
                <ContainersTable
                    name="quote_form_containers_table_state"
                    data={this.state.quote}
                    headers={[ "quantity", "size", "type" ]}
                    editable={true}
                    onEdit={this.editContainerOfList.bind(this)}
                    onClone={this.cloneContainerOfList.bind(this)}
                    onDelete={this.removeContainerFromList.bind(this)}
                />
              </div>
            </div>
          </div>
        }

        {(this.state.quote.transport_type === "ftl") &&
          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Truck Details</label>
                </div>
              </div>
            </div>

            <div className="row row-small">
              <div className="col-md-6 col-lg-4">
                <TextField
                  label="Number of trucks"
                  variant="outlined"
                  required
                  fullWidth
                  type="number"
                  margin="dense"
                  value={this.state.quote.truck_quantity}
                  name="truck_quantity"
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            </div>

          </div>
        }
        
        <div className="container-body">

          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Pallets</label>
              </div>
            </div>
          </div>

          <div className="row row-small">
            <div className="col-md-6 col-lg-4">
              <table className="table table-details" >
                <tbody>
                  <tr className="row-no-top-border-and-padding">
                    <td width={200} className="header-text">Total pallets</td>
                    <td>{Converter.formatNumber(this.getTotalPallets(), 0)}</td>
                  </tr>
                  <tr>
                    <td width={200} className="header-text">Total volume (m3)</td>
                    <td>{Converter.formatNumber(this.getTotalVolume(), 2)} m3</td>
                  </tr>
                  <tr>
                    <td width={200} className="header-text">Total weight</td>
                    <td>{Converter.formatNumber(this.getTotalWeight(), 2)} kg</td>
                  </tr>
                  <tr>
                    <td width={200} className="header-text">Loading meter</td>
                    <td>{Converter.formatNumber(Calculator.calculateLoadingMeters(this.state.quote.pallets), 2)} ldm</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <Button
                variant="outlined"
                color="primary"
                onClick={this.addPalletToList.bind(this)}
                startIcon={<Icon>playlist_add_check</Icon>}>
                Add pallet
              </Button>
              <Button
                className='align_right'
                variant="outlined"
                color="primary"
                onClick={this.importPallets.bind(this)}
                startIcon={<Icon>upload_file</Icon>}>
                Import
              </Button>
            </div>
          </div>
          
          <div className="row">
            <div className="col-sm">
              <PalletsTable
                  name="quote_form_pallets_table_state"
                  data={this.state.quote}
                  headers={[
                    "length_cm",
                    "width_cm",
                    "height_cm",
                    "volume_m3",
                    "weight_kg",
                    "hs_codes",
                    "stackable"
                  ]}
                  editable={true}
                  onEdit={this.editPalletOfList.bind(this)}
                  onClone={this.clonePalletOfList.bind(this)}
                  onDelete={this.removePalletFromList.bind(this)}
              />
            </div>
          </div>
        
        </div>
        
        <div className="container-body">

          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Colli Details</label>
              </div>
            </div>
          </div>

          <div className="row row-small">
            <div className="col-md-6 col-lg-4">
              <table className="table table-details" >
                <tbody>
                  <tr className="row-no-top-border-and-padding">
                    <td width={200} className="header-text">Total packages</td>
                    <td>{Converter.formatNumber(this.getTotalPackages(), 0)}</td>
                  </tr>
                  <tr>
                    <td width={200} className="header-text">Total parts</td>
                    <td>{Converter.formatNumber(this.getTotalParts(), 0)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <Button
                variant="outlined"
                color="primary"
                onClick={this.addPackageToList.bind(this)}
                startIcon={<Icon>playlist_add_check</Icon>}>
                Add package
              </Button>
              <Button
                className='align_right'
                variant="outlined"
                color="primary"
                onClick={this.importColli.bind(this)}
                startIcon={<Icon>upload_file</Icon>}>
                Import
              </Button>
            </div>
          </div>
          
          <div className="row">
            <div className="col-sm">
              <PackagesTable
                  name="quote_form_packages_table_state"
                  data={this.state.quote}
                  headers={[ 
                    "number",
                    "loading_number",
                    "order_number",
                    "quantity",
                    "reference",
                    "part_price",
                    "country_of_origin",
                    "description"
                  ]}
                  editable={true}
                  onEdit={this.editPackageOfList.bind(this)}
                  onClone={this.clonePackageOfList.bind(this)}
                  onDelete={this.removePackageFromList.bind(this)}
              />
            </div>
          </div>
        
        </div>
        
        <div className="container-body">

          <div className="row">
            <div className="col-md">

              <div className="row">
                <div className="col-md-12 col-lg-8">
                  <MultilineTextField
                    label="Comment"
                    variant="outlined"
                    fullWidth
                    min_rows={4}
                    margin="dense"
                    value={this.state.quote.comment}
                    name="comment"
                    onChange={this.handleChange.bind(this)}
                    InputLabelProps={{
                      shrink: this.state.quote.comment !== undefined,
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md">
                  <AsyncButton
                    type="submit"
                    variant="outlined"
                    color="primary"
                    icon="check"
                    text="Request" />
                </div>
              </div>

            </div>
          </div>
        
        </div>
        
      </form>
    );
  }
}

export default QuoteForm;
