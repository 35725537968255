import React from 'react';
import { Link } from 'react-router-dom';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { isReadOnly } from '../../../services/authentication_service.js';
import Mapper from '../../../util/mapper.js';
import LoadingContent from '../../../components/loading_content.js';
import Converter from '../../../util/converter.js'
import MultilineTextField from '../../../components/multiline_textfield.js';
import AsyncButton from '../../../components/async_button.js';
import { commentShipment } from '../../../services/shipment_service.js';
import ErrorHandler from '../../../util/error_handler.js';
import EventBus from '../../../components/event_bus.js';
import DomUtil from '../../../util/dom_util.js';
import confirm from '../../../components/confirm.js'
import { hasModule } from '../../../services/environment_service.js';

class GeneralTab extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      provide_shipment_patch: {
        validity_days: 30
      },
      review_shipment_patch: {},
      selected_correspondent_id: this.getSelectedCorrespondent(props.shipment),
      is_commenting: false,
      comment_shipment: {}
    };
  }

  componentDidUpdate(prevProps) {
    if(!prevProps.shipment && this.props.shipment && !this.state.selected_correspondent_id) {
      this.setState({ selected_correspondent_id: this.getSelectedCorrespondent(this.props.shipment)})
    }
  }

  getSelectedCorrespondent(shipment) {
    if(!shipment || !shipment.comments || shipment.comments.length === 0) {
      return;
    }
    var result = shipment.comments[0].correspondent.id;
    return result;
  }

  getCorrespondents(comments) {
    return comments.map(c => { 
      c.correspondent.comment_count = c.comments.length;
      return c.correspondent
    });
  }

  handleCorrespondentChange(e) {
    this.setState({ selected_correspondent_id: e.target.value });
  }  

  getComments(comments, selected_correspondent_id) {
    var comments_correspondent = [];
    if(selected_correspondent_id) {
      var result = comments.find(c => c.correspondent.id === selected_correspondent_id);
      if(result) {
        comments_correspondent = result.comments;
      }
    }
    if(!comments_correspondent || comments_correspondent.length === 0) {
      return (
        <div className='spacing-bottom' key="no_comments">
          <span><i>There are no comments</i></span>
        </div>
      );
    };

    var sorted_comments = comments_correspondent.sort((a, b) => a.created_at.localeCompare(b.created_at));

    return (
      <div className='spacing-bottom'>
        { sorted_comments.map((comment, index) => {
            return (
              <div key={"comment" + index}>
                <span><b>{comment.from.name}:</b>&nbsp;&nbsp;<i>({Converter.toDatetime(comment.created_at)})</i></span>
                <br/>
                <span style={{ whiteSpace: "pre", textWrap: "wrap" }}>{comment.message}</span>
              </div>
            );
          }) }
      </div>
    );
  }

  addComment(e) {
    e.preventDefault();
    this.setState({ is_commenting: true });
  }

  cancelAddComment(e) {
    e.preventDefault();
    this.setState({ is_commenting: false, comment_shipment: {} });
  }

  handleCommentShipmentChange(e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.state.comment_shipment[e.target.name] = value;
    this.setState({ comment_shipment: this.state.comment_shipment })
  }

  commentShipment(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirm("Please confirm", "Are you sure to add this comment?").then(() => {
      DomUtil.disableClass(button);

      var comment = { correspondent: { id: this.state.selected_correspondent_id }, message: this.state.comment_shipment.comment };
      commentShipment(this.props.shipment.id, comment).then((shipment) => {
        EventBus.dispatch("updateShipment");
        this.setState({ is_commenting: false, comment_shipment: {} });
        this.props.shipmentUpdated(shipment);
        DomUtil.enableClass(button);
      }).catch(() => {
        DomUtil.enableClass(button);
      });
    });
  }
  
  isShipmentOfType(shipment, types) {
    if(!shipment) {
      return false;
    }
    return types.includes(shipment.type);
  }

  getAgents(shipment) {
    if(!shipment.agents || shipment.agents.length == 0) {
      return "-";
    }
    return shipment.agents.map(a => a.name).join(', ');
  }

  getShipperDetails(shipment) {
    if(shipment.shipper) {
      return shipment.shipper.name;
    }
    var result = [];
    if(shipment.shipper_name) {
      result.push(shipment.shipper_name);
    }
    if(shipment.shipper_phone) {
      result.push(shipment.shipper_phone);
    }
    if(shipment.shipper_email) {
      result.push(shipment.shipper_email);
    }
    return result.join(" / ");
  }

  getConsigneeDetails(shipment) {    
    if(shipment.consignee) {
      return shipment.consignee.name;
    }
    var result = [];
    if(shipment.consignee_name) {
      result.push(shipment.consignee_name);
    }
    if(shipment.consignee_phone) {
      result.push(shipment.consignee_phone);
    }
    if(shipment.consignee_email) {
      result.push(shipment.consignee_email);
    }
    return result.join(" / ");
  }

  render() {
    return (
      <div>

        <div className="row row-small">
          <div className="col-md-6 col-lg-6">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td width={200} className="header-text">Status</td>
                  <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => <span className={"badge " + Mapper.toShipmentStatusBadge(this.props.shipment.status)} >{Mapper.fromShipmentStatus(this.props.shipment.status)}</span> } /></td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Created at</td>
                  <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => Converter.toDate(this.props.shipment.created_at)} /></td>
                </tr>
                { (this.isShipmentOfType(this.props.shipment, [ "forwarder", "admin" ])) &&
                  <tr>
                    <td width={200} className="header-text">Customer</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => this.props.shipment.customer.name} /></td>
                  </tr>
                }
                { (this.isShipmentOfType(this.props.shipment, [ "forwarder", "admin" ])) &&
                  <tr>
                    <td width={200} className="header-text">Customer contact</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => (this.props.shipment.customer_contact ? this.props.shipment.customer_contact.full_name : "-")} /></td>
                  </tr>
                }
                { (this.isShipmentOfType(this.props.shipment, [ "forwarder", "admin" ])) &&
                  <tr>
                    <td width={200} className="header-text">Agents</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => (this.getAgents(this.props.shipment))} /></td>
                  </tr>
                }
                { (hasModule("quotation") && this.isShipmentOfType(this.props.shipment, [ "customer", "forwarder", "admin" ])) &&
                  <tr>
                    <td width={200} className="header-text">Quote</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => ((this.props.shipment.quote && this.props.shipment.quote.id) ? <Link to={`/quotes/all/${this.props.shipment.quote.id}`}>Quote {this.props.shipment.quote.id}</Link> : "-")} /></td>
                  </tr>
                }
                <tr>
                  <td width={200} className="header-text">Reference</td>
                  <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => this.props.shipment.reference} /></td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Incoterms</td>
                  <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => Mapper.fromIncoterms(this.props.shipment.incoterms)} /></td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Business type</td>
                  <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => Mapper.fromBusinessType(this.props.shipment.business_type)} /></td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Shipper</td>
                  <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => this.getShipperDetails(this.props.shipment)} /></td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Consignee</td>
                  <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => this.getConsigneeDetails(this.props.shipment)} /></td>
                </tr>
                { (this.isShipmentOfType(this.props.shipment, [ "forwarder", "admin" ])) &&
                  <tr>
                    <td width={200} className="header-text">Internal notes</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => <span style={{ whiteSpace: "pre", textWrap: "wrap" }}>{this.props.shipment.internal_notes}</span>} /></td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
          <div className="col-md-6 col-lg-6">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td width={200} className="header-text">Comments</td>
                  <td>
                    { (this.isShipmentOfType(this.props.shipment, [ "forwarder", "admin" ])) &&
                      <FormControl
                        className='correspondent-selector'
                        variant="outlined"
                        margin="dense"
                        fullWidth>
                        <InputLabel>Correspondent</InputLabel>
                        <Select
                          native
                          label="Correspondent"
                          value={this.state.selected_correspondent_id}
                          name="selected_correspondent_id"
                          onChange={this.handleCorrespondentChange.bind(this)} 
                          >
                            {this.getCorrespondents(this.props.shipment.comments).map(correspondent => {
                              return (<option key={"correspondent_" + correspondent.id} value={correspondent.id}>{`${correspondent.name} (${correspondent.comment_count} comments)`}</option>);
                            })}
                        </Select>
                      </FormControl>
                    }

                    <LoadingContent rows={3} mustBeLoaded={this.props.shipment} content={() => this.getComments(this.props.shipment.comments, this.state.selected_correspondent_id)} />
                    { !isReadOnly() && this.props.shipment && !this.state.is_commenting &&
                      <AsyncButton
                        variant="outlined"
                        color="secondary"
                        onClick={this.addComment.bind(this)}
                        icon="message"
                        text="Add Comment" />
                    }
                    {this.state.is_commenting &&
                      <form autoComplete="off" onSubmit={this.commentShipment.bind(this)}>                            
                        <div className="row">
                          <div className="col-md-12">
                            <MultilineTextField
                              label="Comment"
                              variant="outlined"
                              fullWidth
                              required
                              min_rows={4}
                              margin="dense"
                              value={this.state.comment_shipment.comment}
                              name="comment"
                              onChange={this.handleCommentShipmentChange.bind(this)}
                              InputLabelProps={{
                                shrink: this.state.comment_shipment.comment !== undefined,
                              }}
                            />
                          </div>
                        </div>                            
                        <div className="row">
                          <div className="col-md-12">
                            <AsyncButton
                              type="submit"
                              variant="outlined"
                              color="primary"
                              icon="check"
                              text="Submit" />
                            <AsyncButton
                              className="button-danger-outlined"
                              variant="outlined"
                              color="secondary"
                              onClick={this.cancelAddComment.bind(this)}
                              icon="close"
                              text="Cancel" />
                          </div>
                        </div>
                      </form>
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default GeneralTab;
