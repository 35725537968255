import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getAccounts } from '../../../shared/services/account_service';
import Mapper from '../../../shared/util/mapper';
import RowDataTable from '../../../shared/components/row_data_table';
import TableUtil from '../../../shared/util/table_util';

class Accounts extends React.Component {

  constructor(props) {
    super(props)
    this.rowTableRef = React.createRef();
  }
  
  openAccount(event) {
    var newTab = event.event.ctrlKey || event.event.metaKey;
    var url = `/accounts/${event.data.id}${this.props.location.search}`
    if(newTab) {
      window.open(url, "_blank", "noreferrer");
    } else {
      this.props.history.push(url);
    }
  }

  onPaginationChanged(page, size) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", page);
    searchParams.set("size", size);
    this.props.history.replace(`${location.pathname}?${searchParams.toString()}`);
  }

  onSortChanged(sort) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("sort", sort);
    this.props.history.replace(`${location.pathname}?${searchParams.toString()}`);
  }

  onFilterChanged(filters) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("filters", JSON.stringify(filters));
    this.props.history.replace(`${location.pathname}?${searchParams.toString()}`);
  }
  
  getAccountHeaders() {
    var headers = [];
  
    headers.push(TableUtil.getTextColumn("name", "Name"));
    headers.push(TableUtil.getSetColumn(
      "type",
      "Account type",
      (params) => Mapper.fromAccountType(params.value),
      Object.keys(Mapper.accountTypes()),
      (value) => Mapper.fromAccountType(value)
    ));
    headers.push(TableUtil.getTextColumn("environment", "Environment"));
    
    return headers;
  }

  getRows(page, blockSize, sort, filters) {
    return new Promise((resolve, reject) => {
      return getAccounts(page, blockSize, sort, JSON.stringify(filters))
      .then((response) => {
        if(!response) {
          reject("no data");
        }

        resolve({ content: response.content, total_elements: response.total_elements });
      }).catch(error => {
        reject(error);
      });
    });
  };

  getPage() {
    const params = new URLSearchParams(this.props.location.search);
    return params.get('page');
  }

  getPageSize() {
    const params = new URLSearchParams(this.props.location.search);
    return params.get('size');
  }

  getSort() {
    const params = new URLSearchParams(this.props.location.search);
    return params.get('sort');
  }

  getFilters() {
    const params = new URLSearchParams(this.props.location.search);
    var filters = params.get('filters');
    if(filters) {
      return JSON.parse(filters);
    }
  }
  
  render() {
    return (
      <div>
        
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem active>Accounts</BreadcrumbItem>
          </Breadcrumb>

          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Accounts</label>
                </div>
              </div>
            </div>
            
            <div className="row">
              <div className="col-sm">
                <RowDataTable
                  ref={this.rowTableRef}
                  name="admin_accounts_table_state"
                  rowModelType="serverSide"
                  rowData={{ totalElements: 0, ready: false }}
                  getRows={this.getRows.bind(this)}
                  columnDefs={this.getAccountHeaders()}
                  overlayNoRowsTemplate="There are no accounts"
                  pagination={true}
                  initialPage={this.getPage()}
                  initialPageSize={this.getPageSize()}
                  initialSort={this.getSort()}
                  initialFilters={this.getFilters()}
                  defaultPageSize={100}
                  onRowClicked={this.openAccount.bind(this)}
                  onPaginationChanged={this.onPaginationChanged.bind(this)}
                  onSortChanged={this.onSortChanged.bind(this)}
                  onFilterChanged={this.onFilterChanged.bind(this)}
                />
              </div>
            </div>

          </div>
        </div>
      </div >
    );
  }
}

export default Accounts;
