import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { getWarehouse, getWarehouseStock, getCurrentWarehouseStock, exportStockToDocument } from '../../services/warehouse_service';
import { hasType } from '../../services/authentication_service';
import confirmStockHistory from '../../components/confirm_stock_history.js';
import ErrorHandler from '../../util/error_handler';
import TableUtil from '../../util/table_util';
import RowDataTable from '../../components/row_data_table';
import Converter from '../../util/converter'
import AsyncButton from '../../components/async_button.js';
import DomUtil from '../../util/dom_util.js';
import fileDownload from 'js-file-download';
import Skeleton from 'react-loading-skeleton';
import confirmInput from '../../components/confirm_input.js'
import alertDialog from '../../components/alert.js';
import alert from '../../components/alert.js';
import MatchUtil from '../../util/match_util.js';
import PrintUtil from '../../util/print_util.js';

class WarehouseStock extends React.Component {

  constructor(props) {
    super(props)

    const params = new URLSearchParams(this.props.location.search);
    var show =  params.get('show');
    if(!show) {
      show = 'in_warehouse';
    }

    this.state = {
      warehouse_code: this.props.match.params.warehouse_code,
      warehouse: undefined,
      show: show,
      stock: undefined,
      all_current_stock: undefined,
    }

    this.rowTableRef = React.createRef();
  }

  componentDidMount() {
    this.getWarehouse();
    this.getCurrentWarehouseStock();
  }

  getWarehouse() {
    getWarehouse(this.state.warehouse_code).then((warehouse) => {
      if (warehouse) {
        this.setState({ warehouse: warehouse });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getCurrentWarehouseStock() {
    getCurrentWarehouseStock(this.state.warehouse_code).then((stock) => {
      if (stock) {
        this.setState({ all_current_stock: stock.content });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getPage() {
    const params = new URLSearchParams(this.props.location.search);
    return params.get('page');
  }

  getPageSize() {
    const params = new URLSearchParams(this.props.location.search);
    return params.get('size');
  }

  getSort() {
    const params = new URLSearchParams(this.props.location.search);
    return params.get('sort');
  }

  getFilters() {
    const params = new URLSearchParams(this.props.location.search);
    var filters = params.get('filters');
    if(filters) {
      return JSON.parse(filters);
    }
  }

  onPaginationChanged(page, size) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", page);
    searchParams.set("size", size);
    this.props.history.replace(`${location.pathname}?${searchParams.toString()}`);
  }

  onSortChanged(sort) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("sort", sort);
    this.props.history.replace(`${location.pathname}?${searchParams.toString()}`);
  }

  onFilterChanged(filters) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("filters", JSON.stringify(filters));
    this.props.history.replace(`${location.pathname}?${searchParams.toString()}`);
  }

  getRows(page, blockSize, sort, filters) {
    return new Promise((resolve, reject) => {
      filters = filters.concat(this.getShowFilters());

      return getWarehouseStock(this.state.warehouse_code, page, blockSize, sort, JSON.stringify(filters))
      .then((response) => {
        if(!response) {
          reject("no data");
          return;
        }

        resolve({ content: response.content, total_elements: response.total_elements });
      }).catch(error => {
        reject(error);
      });
    });
  };

  getStockHeaders() {
    var headers = [];
  
    headers.push(TableUtil.getTextColumn("part_number", "Part number"));
    headers.push(TableUtil.getTextColumn("package_number", "Package number"));
    headers.push(TableUtil.getTextColumn("order_number", "Order number"));
    headers.push(TableUtil.getTextColumn("reference", "Reference", p => p && p.value ? p.value : "", { minWidth: 250 }));
    headers.push(TableUtil.getNumberColumn("quantity", "Quantity"));
    headers.push(TableUtil.getNumberColumn("in_quantity", "In quantity"));
    headers.push(TableUtil.getNumberColumn("out_quantity", "Out quantity"));
    headers.push(TableUtil.getNumberColumn("part_price", "Part price", (params) => Converter.formatNumber(params.value, 2)));
    headers.push(TableUtil.getTextColumn("country_of_origin", "Origin"));
    headers.push(TableUtil.getTextColumn("description", "Description"));
    headers.push(TableUtil.getTextColumn("loading_number", "Loading number"));
    headers.push(TableUtil.getTextColumn("arrival_date", "Arrival date", (params) => Converter.toDate(params.value), { disable: true }));
    headers.push(TableUtil.getTextColumn("ready_date", "Ready date", (params) => Converter.toDate(params.value), { disable: true }));
    headers.push(TableUtil.getTextColumn("dispatch_date", "Dispatch date", (params) => Converter.toDate(params.value), { disable: true }));
    headers.push(TableUtil.getTextColumn("history.shipment_id", "Shipments", (params) => params.data.history.map(v => v.shipment_id).join(', '), { minWidth: 250 }));
    
    return headers;
  }

  exportStock(e) {
    e.preventDefault();
    var button = e.currentTarget;
    DomUtil.disableClass(button);

    var sort = this.rowTableRef.current.getSort();
    var filters = this.rowTableRef.current.getFilters();
    filters = filters.concat(this.getShowFilters());

    exportStockToDocument(this.state.warehouse_code, sort, JSON.stringify(filters)).then((blob) => {
      fileDownload(blob, `warehouse_stock_export.xlsx`);
      DomUtil.enableClass(button);
    }).catch((error) => {
      ErrorHandler.showError(error);
      DomUtil.enableClass(button);
    });
  }

  handleShowChange(e) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("show", e.target.value);
    this.props.history.replace(`${location.pathname}?${searchParams.toString()}`);

    this.setState({ show: e.target.value }, () => {
      this.rowTableRef.current.reloadData();
    });
  }

  isLoaded() {
    return this.state.warehouse;
  }

  getShowFilters() {
    var filters = [];
    if(this.state.show === 'in_warehouse') {
      filters.push({
        "field":"quantity",
        "comparator":"greater_than",
        "value":0
      });
      filters.push({
        "field":"arrival_date",
        "comparator":"not_blank"
      });
    }
    return filters;
  }

  showStockDetails(event) {
    confirmStockHistory(`History of part ${event.data.part_number} with order number ${event.data.order_number} in package ${event.data.package_number}`, event.data.history);
  }

  currentStockLoaded() {
    return this.state.all_current_stock !== undefined;
  }

  printPackageVerifiedLabel(e) {
    e.preventDefault();
    var target = e.currentTarget;
    DomUtil.disableClass(target);
    
    confirmInput("Please specify", "Please specify the package number to print verified label for:", "Package number", "text", undefined).then(package_number => {
      if(!package_number) {
        DomUtil.enableClass(target);
        return;
      }
  
      var _package = this.findPackage(package_number)

      if(!_package) {
        alertDialog(<span>✘&nbsp;&nbsp;Package not found</span>, `Package with number ${package_number} not found.`, "theme-red");
        DomUtil.enableClass(target);
        return;
      }
      
      var references = [];
      for(const part of _package.parts) {
        if(part.reference) {
          if(!references.find(r => r === part.reference)) {
            references.push(part.reference);
          }
        }
      }
      
      var params = new Map();
      params.set("PackageNumber", _package.package_number);
      params.set("BarcodeData", _package.package_number);
      params.set("References", references.join('\n'));
      params.set("ReferencesZebra", references.join('\\&'));
      params.set("ReferencesSize", Math.min(220 / references.length, 70));
      params.set("ReferencesLines", Math.max(references.length, 3));
      params.set("ShipmentNumber", "-");

      PrintUtil.printLabel("/labels/inbound_confirmation.zpl", "/labels/inbound_confirmation.xml", params, 3).then(() => {
        DomUtil.enableClass(target);
      }).catch((error) => {
        console.log(error);
        alert("Unable to print label", error);
        DomUtil.enableClass(target);
      });
    }).catch(() => {
      DomUtil.enableClass(target);
    });
  }

  findPackage(number) {
    var matches = MatchUtil.matchNumber(this.state.all_current_stock, "package_number", number);
    if(matches.length === 1) {
      return matches[0];
    }
  }

  render() {
    return (
      <div>
        
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`/warehouses/` + this.props.location.search}>Warehouses</Link></BreadcrumbItem>
            <BreadcrumbItem>{this.state.warehouse ? this.state.warehouse.name : ""}</BreadcrumbItem>
            <BreadcrumbItem active>Stock</BreadcrumbItem>
          </Breadcrumb>
          
          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>{`Warehouse ${this.state.warehouse ? this.state.warehouse.name : ""} - Stock`}</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md">
                <AsyncButton
                  variant="outlined"
                  color="secondary"
                  onClick={this.exportStock.bind(this)}
                  icon="download"
                  text="Export" />
                <label className="button-line-header-text">Show</label>
                <FormControl
                  variant="outlined"
                  margin="dense"
                  style={{marginRight: "25px", minWidth: 150}}
                  className='no-margin' >
                  <Select
                    native
                    onChange={this.handleShowChange.bind(this)} >
                      <option selected={this.state.show === 'in_warehouse'} key={"stock_in_warehouse"} value="in_warehouse">In warehouse</option>
                      <option selected={this.state.show === 'all'} key={"stock_all"} value="all">All</option>
                  </Select>
                </FormControl>
                {(hasType("admin") || hasType("forwarder") || hasType("agent")) &&
                  <AsyncButton
                    variant="outlined"
                    color="secondary"
                    disabled={!this.currentStockLoaded()}
                    onClick={this.printPackageVerifiedLabel.bind(this)}
                    icon="print"
                    text="Verified label" />
                  }
              </div>
            </div>
            
            <div className="row">
              <div className="col-sm">
                {this.isLoaded() &&
                  <RowDataTable
                    ref={this.rowTableRef}
                    name="warehouse_stock_table_state"
                    rowModelType="serverSide"
                    rowData={{ totalElements: 0, ready: false }}
                    getRows={this.getRows.bind(this)}
                    columnDefs={this.getStockHeaders()}
                    overlayNoRowsTemplate="There is no stock"
                    pagination={true}
                    initialPage={this.getPage()}
                    initialPageSize={this.getPageSize()}
                    initialSort={this.getSort()}
                    initialFilters={this.getFilters()}
                    defaultPageSize={100}
                    onRowClicked={this.showStockDetails.bind(this)}
                    onPaginationChanged={this.onPaginationChanged.bind(this)}
                    onSortChanged={this.onSortChanged.bind(this)}
                    onFilterChanged={this.onFilterChanged.bind(this)}
                  />
                }
                {!this.isLoaded() &&
                  <Skeleton count={5}/>
                }
              </div>
            </div>
            
          </div>
          
        </div>
      </div >
    );
  }
}

export default WarehouseStock;
