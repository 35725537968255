import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getAllPendingShipmentTasks  } from '../../../shared/services/shipment_service';
import Converter from '../../../shared/util/converter';
import RowDataTable from '../../../shared/components/row_data_table';
import TableUtil from '../../../shared/util/table_util';
import ErrorHandler from '../../../shared/util/error_handler';

class PendingTasks extends React.Component {
  
  constructor(props) {
    super(props)

    this.state = {
      tasks: undefined
    };
  }

  componentDidMount() {
    this.getAllPendingShipmentTasks();
  }

  getAllPendingShipmentTasks() {
    getAllPendingShipmentTasks().then((response) => {
      if (response) {
        this.setState({ tasks: response.tasks });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getInvoicesRows() {    
    if(!this.state.tasks) {
      return { data: [], totalElements: 0, ready: false };
    }
    
    return { data: this.state.tasks, totalElements: this.state.tasks.length, ready: true };
  }
  
  getInvoiceHeaders() {
    var headers = [];
  
    headers.push(TableUtil.getTextColumn("title", "Task"));
    headers.push(TableUtil.getDateColumn(
      "date",
      "Date",
      (params) => {
        var className;
        if(new Date(params.value) < new Date())  {
          className = "invoice-due-date-expired"
        }
        return <span className={className}>{Converter.toDate(params.value)}</span>;
      }
    ));
    headers.push(TableUtil.getTextColumn("shipment_id", "Shipment"));
    headers.push(TableUtil.getTextColumn("shipment_reference", "Shipment reference"));
    
    return headers;
  }

  openTask(event) {
    var newTab = event.event.ctrlKey || event.event.metaKey;
    var url = `/shipments/all/${event.data.shipment_id}?tab=tasks`;
    if(newTab) {
      window.open(url, "_blank", "noreferrer");
    } else {
      this.props.history.push(url);
    }
  }

  render() {
    return (
      <div>
        
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem active>Pending tasks</BreadcrumbItem>
          </Breadcrumb>

          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Pending tasks</label>
                </div>
              </div>
            </div>
            
            <div className="row">
              <div className="col-sm">
                <RowDataTable
                  name="pending_tasks_table_state"
                  rowData={this.getInvoicesRows()}
                  columnDefs={this.getInvoiceHeaders()}
                  onRowClicked={this.openTask.bind(this)}
                  overlayNoRowsTemplate="There are no pending tasks"
                />
              </div>
            </div>

          </div>

        </div>
      </div >
    );
  }
}

export default PendingTasks;
