import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import PackagesTable from '../../../../components/packages_table.js';
import alertDialog from '../../../../components/alert.js';
import MatchUtil from '../../../../util/match_util.js';
import confirm from '../../../../components/confirm.js'

class AssignColliTab extends React.Component {

  constructor(props) {
    super(props)

    this.setInboundShipments(props.stock);
    
    this.state = {
      shipment: props.shipment,
      stock: props.stock,
      package_number: ""
    };
  }

  onKeyDown(e) {
    if (e.key === 'Enter') {
      if(e.target.name === 'package_number') {
        this.getPackage(e);
      }
    }
  }

  setInboundShipments(stock) {
    for(const _package of stock) {
      var package_shipment_ids = [];

      for(const part of _package.parts) {
        var part_shipment_ids = [];
        if(part.history) {
          for(const history of part.history) {
            if(history.type === 'in') {
              part_shipment_ids.push(history.shipment_id);
              package_shipment_ids.push(history.shipment_id);
            }
          }
        }
        part.inbound_shipment = [...new Set(part_shipment_ids)].join(", ");
      }

      _package.inbound_shipment = [...new Set(package_shipment_ids)].join(", ");
    }
  }

  handleChange(e) {
    var value = e.target.value;
    this.setState({ [e.target.name]: value });
  }

  getPackage(e) {
    e.preventDefault();

    if(!this.state.package_number) {
      return;
    }

    var _package = this.findPackage(this.state.package_number);
    if(!_package) {
      alertDialog(<span>✘&nbsp;&nbsp;Package not found</span>, `Package with number ${this.state.package_number} not found.`, "theme-red");
      this.setState({ package_number: "" })
      return;
    }

    var existing = this.state.shipment.colli.find(c => c.package_number === _package.package_number);
    if(existing) {
      alertDialog(<span>✘&nbsp;&nbsp;Package already added</span>, `Package with number ${this.state.package_number} already added to colli.`, "theme-red");
      this.setState({ package_number: "" })
      return;
    }

    alertDialog(<span>✓&nbsp;&nbsp;Package added</span>, `Package with number ${_package.package_number} added to colli.`, "theme-green");
    _package.ready_date = new Date();
    for(const part of _package.parts) {
      part.ready_date = new Date();
    }
    this.state.shipment.colli.push(_package);

    this.setState({ package_number: "" })
    this.props.updateShipment(this.state.shipment);
  }

  findPackage(number) {
    var matches = MatchUtil.matchNumber(this.state.stock, "package_number", number);
    if(matches.length === 1) {
      return matches[0];
    }
  }
  
  removePackageFromList(index) {
    confirm("Please confirm", `Are you sure you want to delete Package ${index + 1}?`).then(() => {
      this.state.shipment.colli.splice(index, 1);
      this.props.updateShipment(this.state.shipment);
    }).catch(() => {
      // required to handle cancel
    });
  }

  getReferences() {
    var references = [];
    for(const _package of this.state.shipment.colli) {
      for(const part of _package.parts) {
        references.push(part.reference);
      }
    }
    return [...new Set(references)];
  }

  render() {
    return (
      <div>        
        <div className="row row-small">
          <div className="col-md-12 col-lg-6">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td width={200} className="header-text">Packages</td>
                  <td>{this.state.shipment.colli.length}</td>
                </tr>
                <tr>
                  <td width={200} className="header-text">References</td>
                  <td>
                    {this.getReferences().map(reference => {
                      return (<span>{reference}<br/></span>);
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row row-small">
          <div className="col-md-3">
            <span className='text-padding'>Scan or enter package number..</span>
            <div className='file-input-download-parent'>
              <TextField
                label="Package number"
                variant="outlined"
                fullWidth
                required
                autoFocus
                margin="dense"
                name="package_number"
                value={this.state.package_number}
                onKeyDown={this.onKeyDown.bind(this)}
                onChange={this.handleChange.bind(this)} 
              />
              <Button
                className="button-icon-only button-inline clear-form-button"
                color="primary"
                type="button"
                disabled={!this.state.package_number}
                onClick={this.getPackage.bind(this)}
                startIcon={<Icon>search</Icon>} />
            </div>                      
          </div>
        </div>
        
        <div className="row">
          <div className="col-sm">
            <PackagesTable
                name="shipment_assign_packages_table_state"
                data={this.state.shipment}
                headers={[
                  "number",
                  "loading_number",
                  "inbound_shipment",
                  "order_number",
                  "quantity",
                  "reference",
                  "part_price",
                  "country_of_origin",
                  "description",
                  "ready_date"
                ]}
                editable={true}
                onDelete={this.removePackageFromList.bind(this)}
                groupDefaultExpanded={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AssignColliTab;
