import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import RowDataTable from './row_data_table';
import Mapper from '../util/mapper'
import Converter from '../util/converter'
import TableUtil from '../util/table_util'

class PalletsTable extends React.Component {

  getPalletsRows() {
    var pallets = [];
    var data = this.props.data;
    
    if(!data) {
      return;
    }
    
    if(!data.pallets) {
      return { data: [], totalElements: 0 };
    }
    
    data.pallets.forEach((pallet, index) => {
      pallets.push({
        ... pallet,
        index: index,
        id: `Pallet ${index + 1}`
      })
    });

    return { data: pallets, totalElements: pallets.length, ready: true };
  }

  getPalletsHeaders() {
    var headers = [];
    
    if(this.props.editable) {
      headers.push( {
        headerName: "Actions",
        field: "actions",
        cellRenderer: (params) => this.getPalletsActionButtons(params),
        minWidth: 125,
        width: 125,
        pinned: 'left',
        lockPinned: true,
        lockVisible: true,
        sortable: false
      });
    }

    headers.push(TableUtil.getTextColumn("id", "#", undefined, { lockVisible: true }));

    if(this.includesHeader("length_cm")) {
      headers.push(TableUtil.getNumberColumn("length_cm", "Length (cm)", (params) => Converter.formatNumber(params.value, 2)));
    }

    if(this.includesHeader("width_cm")) {
      headers.push(TableUtil.getNumberColumn("width_cm", "Width (cm)", (params) => Converter.formatNumber(params.value, 2)));
    }

    if(this.includesHeader("height_cm")) {
      headers.push(TableUtil.getNumberColumn("height_cm", "Height (cm)", (params) => Converter.formatNumber(params.value, 2)));
    }

    if(this.includesHeader("volume_m3")) {
    headers.push(TableUtil.getNumberColumn("volume_m3", "Volume (m3)", (params) => Converter.formatNumber(this.getVolumeOfPallet(params.data), 2), { hide: true, disable: true }));
    }

    if(this.includesHeader("weight_kg")) {
      headers.push(TableUtil.getNumberColumn("weight_kg", "Weight (kg)", (params) => Converter.formatNumber(params.value, 2)));
    }

    if(this.includesHeader("hs_codes")) {
      headers.push(TableUtil.getTextColumn("hs_codes", "HS codes", (params) => this.getHsCodes(params.data), { disable: true }));
    }

    if(this.includesHeader("stackable")) {
      headers.push(TableUtil.getSetColumn("stackable", "Stackable", (params) => Mapper.fromBoolean(params.value), Object.keys(Mapper.booleans()), (value) => Mapper.fromBoolean(value)));
    }

    return headers;
  }

  autoGroupColumnDef() {
    return {
      headerName: "#",
      cellRendererParams: {
        suppressCount: true
      },
      minWidth: 135,
      filter: "agTextColumnFilter",
      filterParams: {
        maxNumConditions: 1,
      },
      floatingFilter: true
    };
  }

  getPalletsActionButtons(params) {    
    return (
      <div className='table-cell-button-container'>
        <IconButton
          className='table-cell-button'
          size="small"
          onClick={() => this.props.onEdit(params.data.index)}
        >
          <Icon>edit</Icon>
        </IconButton>
        <IconButton
          className='table-cell-button'
          size="small"
          onClick={() => this.props.onClone(params.data.index)}
        >
          <Icon>content_copy</Icon>
        </IconButton>
        <IconButton
          className='table-cell-button'
          size="small"
          onClick={() => this.props.onDelete(params.data.index)}
        >
          <Icon>delete</Icon>
        </IconButton>
      </div>
    );
  }

  includesHeader(name) {
    return this.props.headers && this.props.headers.includes(name);
  }

  getVolumeOfPallet(pallet) {
    var volume = 0;
    if(pallet.length_cm && pallet.width_cm && pallet.height_cm) {
      volume = (Number(pallet.length_cm) / 100) * (Number(pallet.width_cm) / 100) * (Number(pallet.height_cm) / 100);
    }
    return volume;
  }
  
  getHsCodes(pallet) {
    if(!pallet.hs_codes) {
      return "";
    }
    return pallet.hs_codes.join(', ');
  }

  render() {
    return (
      <RowDataTable
        name={this.props.name}
        rowData={this.getPalletsRows()}
        columnDefs={this.getPalletsHeaders()}
        overlayNoRowsTemplate={"There are no pallets"}
  />
    );
  }
}

export default PalletsTable;
