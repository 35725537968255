import React from 'react';
import { logout } from '../../shared/services/authentication_service';
import { getQuoteCount } from '../../shared/services/quote_service';
import { getShipmentCount, getShipmentTasksCount } from '../../shared/services/shipment_service';
import { getInvoiceCount } from '../../shared/services/invoice_service';
import EventBus from '../../shared/components/event_bus';
import ErrorHandler from '../../shared/util/error_handler';
import ParentMenu from '../../shared/components/parent_menu';
import { hasModule } from '../../shared/services/environment_service';

class Menu extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      quote_count: {},
      shipment_count: {},
      task_count: {},
      invoice_count: {}
    }
  }

  componentDidMount() {
    this.getQuoteCount();
    this.getShipmentCount();
    this.getTaskCount();
    this.getInvoiceCount();

    EventBus.on("updateQuote", (data) => {
      this.getQuoteCount();
      this.getShipmentCount();
    });
    
    EventBus.on("updateShipment", (data) => {
      this.getShipmentCount();
    });
    
    EventBus.on("updateTask", (data) => {
      this.getTaskCount();
    });
    
    EventBus.on("updateInvoice", (data) => {
      this.getInvoiceCount();
    });
  }

  getQuoteCount() {
    if(!hasModule("quotation")) {
      return;
    }

    getQuoteCount(["pending"]).then((quote_count) => {
      if (quote_count) {
        this.setState({ quote_count: quote_count });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getShipmentCount() {
    getShipmentCount().then((shipment_count) => {
      if (shipment_count) {
        this.setState({ shipment_count: shipment_count });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getTaskCount() {
    if(!hasModule("tasks")) {
      return;
    }

    getShipmentTasksCount().then((task_count) => {
      if (task_count) {
        this.setState({ task_count: task_count });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getInvoiceCount() {
    if(!hasModule("invoicing")) {
      return;
    }

    getInvoiceCount().then((invoice_count) => {
      if (invoice_count) {
        this.setState({ invoice_count: invoice_count });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getActiveQuotesCount() {
    return this.state.quote_count.pending;
  }

  getActiveShipmentsCount() {
    return this.state.shipment_count.opened + this.state.shipment_count.ready + this.state.shipment_count.received + this.state.shipment_count.in_transit + this.state.shipment_count.at_destination + this.state.shipment_count.delivered;
  }

  getPendingAndExpiredTaskCount() {
    return this.state.task_count.pending_and_expired;
  }

  getOpenInvoiceCount() {
    return this.state.invoice_count.open;
  }

  getSidebarItems() {
    var items = [];

    var servicesItem = {
      title: "Services",
      items: []
    };

    servicesItem.items.push(
      {
        name: "Dashboard",
        icon: "fas fa-chart-area",
        link: ""
      }
    );

    servicesItem.items.push(
      {
        name: "Quotes",
        icon: "fas fa-tag",
        link: "quotes/active",
        required_module: "quotation",
        sub_items: [
          {
            name: "Active",
            link: "quotes/active",
            counter: this.getActiveQuotesCount()
          },
          {
            name: "All",
            link: "quotes/all"
          }
        ]
      }
    );

    servicesItem.items.push(
      {
        name: "Shipments",
        icon: "fas fa-truck",
        link: "shipments/active",
        sub_items: [
          {
            name: "Active",
            link: "shipments/active",
            counter: this.getActiveShipmentsCount()
          },
          {
            name: "All",
            link: "shipments/all"
          }
        ]
      }
    );

    servicesItem.items.push(
      {
        name: "Warehouses",
        icon: "fas fa-warehouse",
        link: "warehouses",
        required_module: "warehouse"
      }
    );

    servicesItem.items.push(
      {
        name: "Tasks",
        icon: "fas fa-check-square",
        link: "tasks",
        required_module: "tasks",
        counter: this.getPendingAndExpiredTaskCount()
      }
    );

    servicesItem.items.push(
      {
        name: "Calculator",
        icon: "fas fa-calculator",
        link: "calculator",
        required_module: "quotation"
      }
    );

    servicesItem.items.push(
      {
        name: "Accounts",
        icon: "fas fa-user-friends",
        link: "accounts"
      }
    );

    servicesItem.items.push(
      {
        name: "Reports",
        icon: "fas fa-chart-pie",
        link: "reports",
        required_module: "invoicing"
      }
    );
    
    items.push(servicesItem);

    var companyItem = {
      title: "Company",
      items: []
    };

    companyItem.items.push(
      {
        name: "Account",
        icon: "fas fa-building",
        link: "account"
      }
    );

    companyItem.items.push(
      {
        name: "Invoices",
        icon: "fas fa-file-alt",
        link: "invoices/open",
        required_module: "invoicing",
        sub_items: [
          {
            name: "Open",
            link: "invoices/open",
            counter: this.getOpenInvoiceCount()
          },
          {
            name: "All",
            link: "invoices/all"
          }
        ]
      }      
    );

    items.push(companyItem);
    
    return items;
  }

  render() {
    return <ParentMenu
      children={this.props.children}
      location={this.props.location}
      topbar_items={[
        {
          name: "Settings",
          icon: "fas fa-cog",
          link: "settings"
        },
        {
          name: "Sign out",
          icon: "fas fa-sign-out-alt",
          onClick: () => logout()
        }
      ]}
      sidebar_items={ this.getSidebarItems() }
    />;
  }
}

export default Menu;
