import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Checkbox from '@material-ui/core/Checkbox';
import { addShipmentTask, updateShipmentTask, deleteShipmentTask } from '../../../services/shipment_service.js';
import { getUserId } from '../../../services/authentication_service.js';
import RowDataTable from '../../../components/row_data_table.js';
import confirm from '../../../components/confirm.js'
import confirmTask from '../../../components/confirm_task.js'
import Converter from '../../../util/converter.js'
import ErrorHandler from '../../../util/error_handler';

class TasksTab extends React.Component {

  constructor(props) {
    super(props)
    this.sortTasks = this.sortTasks.bind(this);

    this.rowTableRef = React.createRef();

    this.state = {
      account: props.account,
      tasks: props.tasks
    };
  }

  componentDidUpdate(prevProps) {
    if(!this.state.tasks || this.state.tasks.length === 0) {
      if(this.props.tasks && this.props.tasks.length > 0) {
        this.setState({ tasks: this.props.tasks});
      }
    }
    if(!this.state.account) {
      if(this.props.account) {
        this.setState({ account: this.props.account});
      }
    }
  }

  getCurrentAccount() {
    getCurrentAccount().then((account) => {
      if (account) {
        this.setState({ account: account });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }


  getTasksRows() {
    return { data: this.state.tasks, totalElements: this.state.tasks.length, ready: true };
  }

  getTasksHeaders() {
    var headers = [];
    
    headers.push( {
      headerName: "Actions",
      field: "actions",
      cellRenderer: (params) => this.getActionButtons(params),
      minWidth: 120,
      maxWidth: 120,
      width: 120,
      pinned: 'left',
      lockPinned: true,
      lockVisible: true,
      sortable: false,
    } );

    headers.push({
      field: "date",
      headerName: "Date",
      minWidth: 130,
      maxWidth: 130,
      width: 130,
      cellRenderer: params => {
        var dateClassName;
        if(params.data.status === 'completed')  {
          dateClassName = "task-checked"
        } else if(new Date(params.data.date) < new Date())  {
          dateClassName = "task-expired"
        }

        return (
          <span className={dateClassName}>{Converter.toDate(params.data.date)}</span>
        );
      },
      lockPinned: true,
      lockVisible: true,
      sortable: false
    });

    headers.push({
      field: "assignee",
      headerName: "Assignee",
      minWidth: 200,
      maxWidth: 200,
      width: 200,
      cellRenderer: params => {
        var titleClassName;
        if(params.data.status === 'completed')  {
          titleClassName = "task-checked"
        }

        return (
          <span className={titleClassName}>{params.data.assignee.full_name}</span>
        );
      },
      lockPinned: true,
      lockVisible: true,
      sortable: false
    });

    headers.push({
      field: "title",
      headerName: "Title",
      minWidth:  500,
      cellRenderer: params => {
        var titleClassName;
        if(params.data.status === 'completed')  {
          titleClassName = "task-checked"
        }

        return (
          <span className={titleClassName}>{params.data.title}</span>
        );
      },
      lockPinned: true,
      lockVisible: true,
      sortable: false
    });

    return headers;
  }

  handleCheck(e, task) {
    if(e.target.checked) {
      task.status = "completed";
    } else {
      task.status = "pending";
    }
    this.updateTask(task);
  }

  getActionButtons(params) {
    var checked = params.data.status === "completed";

    return (
      <div className='table-cell-button-container'>
        <Checkbox
          className='table-cell-checkbox'
          checked={checked}
          onClick={(e) => this.handleCheck(e, params.data)} />
        <IconButton
          className='table-cell-button'
          size="small"
          onClick={() => this.editTask(params.data)} >
          <Icon>edit</Icon>
        </IconButton>
        <IconButton
          className='table-cell-button'
          size="small"
          onClick={() => this.removeTask(params.data)} >
          <Icon>delete</Icon>
        </IconButton>
      </div>
    );
  }

  addTask() {
    confirmTask("Add task", this.state.account, { status: "pending", assignee: { id: getUserId() } }).then((task) => {      
      addShipmentTask(this.props.shipment_id, task).then((new_task) => {
        this.state.tasks.push(new_task);
        this.sortTasks();
        this.rowTableRef.current.setGridOption("rowData", this.state.tasks);
        this.props.tasksUpdated(this.state.tasks);
      }).catch(error => {
        ErrorHandler.showError(error);
      });
    }).catch(() => {
      // required to handle cancel
    });
  }

  editTask(task) {
    confirmTask("Edit task", this.state.account, task).then((updatedTask) => {
      this.updateTask(updatedTask);
    }).catch(() => {
      // required to handle cancel
    });
  }

  removeTask(task) {
    confirm("Remove task", "Are you sure you want to remove this task?").then(() => {
      deleteShipmentTask(this.props.shipment_id, task.id).then(() => {
        const index = this.state.tasks.indexOf(task);
        this.state.tasks.splice(index, 1);
        this.rowTableRef.current.setGridOption("rowData", this.state.tasks);
        this.props.tasksUpdated(this.state.tasks);
      }).catch(error => {
        ErrorHandler.showError(error);
      });
    }).catch(() => {
      // required to handle cancel
    });
  }
  
  updateTask(task) {
    updateShipmentTask(this.props.shipment_id, task.id, task).then(() => {
      this.sortTasks();
      this.rowTableRef.current.setGridOption("rowData", this.state.tasks);
      this.props.tasksUpdated(this.state.tasks);
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  sortTasks() {
    this.setState({ tasks: this.state.tasks.sort((a, b) => a.date.localeCompare(b.date)) });
  }

  render() {
    return (
      <div>

        <div className="row">
          <div className="col-md">
            <Button
              variant="outlined"
              color="primary"
              onClick={this.addTask.bind(this)}
              startIcon={<Icon>playlist_add_check</Icon>}>
              Add task
            </Button>
          </div>
        </div>

        <div className="row">
          <div className="col-sm">
            <RowDataTable
              ref={this.rowTableRef}
              name="shipment_tasks_table"
              rowData={this.getTasksRows()}
              columnDefs={this.getTasksHeaders()}
              overlayNoRowsTemplate={"There are no tasks"}
            />
          </div>
        </div>
        
      </div>
    );
  }
}

export default TasksTab;
