import React from 'react';
import { Prompt } from 'react-router-dom';
import { Link } from 'react-router-dom';
import DomUtil from '../../../shared/util/dom_util';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import { getShipment, createShipment } from '../../../shared/services/shipment_service';
import { getCountries, getPorts } from '../../../shared/services/location_service';
import { getWarehouses } from '../../../shared/services/warehouse_service.js';
import { getShippingLines } from '../../../shared/services/shipping_line_service.js';
import { getCurrentAccount, getAllAccounts } from '../../../shared/services/account_service';
import ErrorHandler from '../../../shared/util/error_handler';
import Mapper from '../../../shared/util/mapper'
import confirm from '../../../shared/components/confirm.js'
import ShipmentForm from './components/form'
import EventBus from '../../../shared/components/event_bus';
import { hasModule } from '../../../shared/services/environment_service';

class CreateShipment extends React.Component {

  constructor(props) {
    super(props)
    this.shipmentUpdated = this.shipmentUpdated.bind(this);
    this.createShipment = this.createShipment.bind(this);

    const params = new URLSearchParams(props.location.search);
    let clone_id = params.get('clone_id') ? params.get('clone_id') : undefined;

    this.state = {
      clone_id: clone_id,
      shipment: undefined,
      countries: undefined,
      warehouses: undefined,
      ports: undefined,
      shipping_lines: undefined,
      account: undefined,
      accounts: undefined,
      changes: false
    };
  }

  componentWillMount() {
    this.getCountries();
    this.getPorts();
    this.getShippingLines();
    this.getCurrentAccount();
    this.getAllAccounts();
    if(hasModule("warehouse")) {
      this.getWarehouses();
    } else {
      this.setState({ warehouses: [] });
    }

    if(this.state.clone_id) {
      this.getShipmentClone();
    } else {
      var initialShipment = {
        status: "opened",
        currency: "eur",
        pickup_location: {
          type: "address",
          address: "",
          postal_code: "",
          city: "",
          country: null
        },
        delivery_location: {
          type: "address"
        },
        containers: [],
        pallets: [],
        colli: []
      };
      this.setState({ shipment: initialShipment });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeunload);
  }

  beforeunload(e) {
    e.preventDefault();
    e.returnValue = true;
  }

  getShipmentClone() {
    getShipment(this.state.clone_id).then((shipment) => {
      if (shipment) {
        shipment.id = undefined;
        shipment.quote = undefined;
        shipment.status = "opened";
        shipment.reference = undefined;
        shipment.loading_reference = undefined;
        shipment.unloading_reference = undefined;
        shipment.proof_of_delivery = undefined;
        shipment.containers = [];
        shipment.pallets = [];
        shipment.colli = [];
        shipment.internal_notes = undefined;
        shipment.internal_documents = undefined;
        shipment.public_documents = undefined;
        this.setState({ shipment: shipment });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getCountries() {
    getCountries().then((countries) => {
      if (countries) {
        this.setState({ countries: countries });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getWarehouses() {
    getWarehouses().then((warehouses) => {
      if (warehouses) {
        this.setState({ warehouses: warehouses });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getPorts() {
    getPorts().then((ports) => {
      if (ports) {
        this.setState({ ports: ports });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getShippingLines() {
    getShippingLines().then((data) => {
      if (data) {
        this.setState({ shipping_lines: data.shipping_lines });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getCurrentAccount() {
    getCurrentAccount().then((account) => {
      if (account) {
        this.setState({ account: account });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getAllAccounts() {
    getAllAccounts().then((accounts) => {
      if (accounts) {
        this.setState({ accounts: accounts.accounts });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  shipmentUpdated() {
    if(!this.state.changes) {
      window.addEventListener('beforeunload', this.beforeunload);
      this.setState({ changes: true });
    }
  }
  
  createShipment(e) {
    e.preventDefault();
    var form = e.currentTarget;
    confirm("Please confirm", "Are you sure to create this shipment?").then(() => {
      DomUtil.disableFormSubmitButtonClass(form);
      var new_shipment = Mapper.toShipmentRequest(this.state.shipment);
      createShipment(new_shipment).then((shipment) => {
        EventBus.dispatch("updateShipment");
        this.setState({ changes: false }, () => {
          this.props.history.push(`${this.getBasePath()}/${shipment.id}`);
        });
      }).catch(() => {
        DomUtil.enableFormSubmitButtonClass(form);
      });
    });
  }

  isLoaded() {
    return this.state.countries &&
      this.state.warehouses &&
      this.state.ports &&
      this.state.shipping_lines &&
      this.state.account &&
      this.state.accounts &&
      this.state.shipment;
  }

  isActivePath() {    
    return this.props.location.pathname.startsWith('/shipments/active');
  }

  getBasePath() {
    if(this.isActivePath()) {
      return '/shipments/active';
    }
    return '/shipments/all';
  }

  getBreadCrumbTitle() {
    if(this.isActivePath()) {
      return 'Active Shipments';
    }
    return 'All Shipments';
  }

  render() {
    return (
      <div>

        <div className="container-content">
        
          <Prompt when={this.state.changes} message="Are you sure you want to leave this page, data is not saved." />

          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.getBasePath()}${this.props.location.search}`}>{this.getBreadCrumbTitle()}</Link></BreadcrumbItem>
            <BreadcrumbItem active>New Shipment</BreadcrumbItem>
          </Breadcrumb>
          
          {this.isLoaded() &&
            <ShipmentForm
              title={"New Shipment"}
              countries={this.state.countries}
              warehouses={this.state.warehouses}
              ports={this.state.ports}
              shipping_lines={this.state.shipping_lines}
              account={this.state.account}
              accounts={this.state.accounts}
              shipment={this.state.shipment}
              changes={this.state.changes}
              shipmentUpdated={this.shipmentUpdated}
              saveShipment={this.createShipment}
            />
          }
          
          {!this.isLoaded() &&
            <div className="container-body">
              <div className="row">
                <div className="col-md">
                  <div className="form-header">
                    <label>New Shipment</label>
                  </div>
                </div>
              </div>          
              <div className="row row-small">
                <div className="col-md-12 col-lg-8">
                  <Skeleton count={5}/>
                </div>
              </div>
            </div>
          }

        </div>
      </div>
    );
  }
}

export default CreateShipment;
