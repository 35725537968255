import React from 'react';
import { Link } from 'react-router-dom';
import Converter from '../../../util/converter.js'
import AsyncButton from '../../../components/async_button.js';
import MultilineTextField from '../../../components/multiline_textfield.js';
import EventBus from '../../../components/event_bus.js';
import ErrorHandler from '../../../util/error_handler.js';
import { hasType, isReadOnly } from '../../../services/authentication_service.js';
import DomUtil from '../../../util/dom_util.js';
import confirm from '../../../components/confirm.js'
import LoadingContent from '../../../components/loading_content.js';
import Mapper from '../../../util/mapper';
import { deleteQuote, commentQuote } from '../../../services/quote_service';

class QuoteContainer extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      is_commenting: false,
      comment_quote: {}
    };
  }

  addComment(e) {
    e.preventDefault();
    this.setState({ is_commenting: true });
  }

  cancelAddComment(e) {
    e.preventDefault();
    this.setState({ is_commenting: false, comment_quote: {} });
  }

  commentQuote(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirm("Please confirm", "Are you sure to add this comment?").then(() => {
      DomUtil.disableClass(button);

      var comment = { message: this.state.comment_quote.comment };
      commentQuote(this.props.quote_id, comment).then((quote) => {
        EventBus.dispatch("updateQuote");
        this.setState({ is_commenting: false, comment_quote: {} });
        this.props.quoteUpdated(quote);
        DomUtil.enableClass(button);
      }).catch(() => {
        DomUtil.enableClass(button);
      });
    });
  }

  editQuote(e) {
    e.preventDefault();
    this.props.history.push(`${this.getBasePath()}/${this.props.quote_id}/edit${this.props.location.search}`);
  }

  deleteQuote(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirm("Please confirm", "Are you sure to delete this quote?").then(() => {
      DomUtil.disableClass(button);

      deleteQuote(this.props.quote_id).then(() => {
        EventBus.dispatch("updateQuote");
        this.props.history.push(`${this.getBasePath()}${this.props.location.search}`);
      }).catch(() => {
        DomUtil.enableClass(button);
      });
    });
  }

  getBasePath() {
    if(this.isActivePath()) {
      return '/quotes/active';
    }
    return '/quotes/all';
  }

  isActivePath() {    
    return this.props.location.pathname.startsWith('/quotes/active');
  }

  handleQuoteCommentChange(e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.state.comment_quote[e.target.name] = value;
    this.setState({ comment_quote: this.state.comment_quote })
  }

  formatLocation(location) {
    if(!location) {
      return "-";
    }
    if(location.type === "address") {
      return location.address + ", " + location.postal_code + " " + location.city + ", " + location.country.name;
    }
    if(location.type === "warehouse") {
      return location.name + " (" + location.address + ", " + location.postal_code + " " + location.city + ", " + location.country.name + ")";
    }
    if(location.type === "port") {
      return location.name + ", " + location.country.name;
    }
    return "-";
  }

  getComments() {
    if(!this.props.quote.comments || this.props.quote.comments.length === 0) {
      return <span/>
    };

    var sorted_comments = this.props.quote.comments.sort((a, b) => a.created_at.localeCompare(b.created_at));

    return (
      <div className='spacing-bottom'>
        { sorted_comments.map((comment, index) => {
            return (
              <div key={"comment" + index}>
                <span><b>{comment.from.name}:</b>&nbsp;&nbsp;<i>({Converter.toDatetime(comment.created_at)})</i></span>
                <br/>
                <span style={{ whiteSpace: "pre", textWrap: "wrap" }}>{comment.message}</span>
              </div>
            );
          }) }
      </div>
    );
  }

  cloneQuote() {
    this.props.history.push(`${this.getBasePath()}/create?clone_id=${this.props.quote_id}`);
  }
  
  isEditable() {
    if(!this.props.quote) {
      return false;
    }
    return ["pending", "quoted", "expired"].includes(this.props.quote.status);
  }
  
  isDeletable() {
    if(!this.props.quote) {
      return false;
    }
    return ["pending", "quoted", "expired"].includes(this.props.quote.status);
  }

  getShipperDetails(quote) {
    if(quote.shipper) {
      return quote.shipper.name;
    }
    var result = [];
    if(quote.shipper_name) {
      result.push(quote.shipper_name);
    }
    if(quote.shipper_phone) {
      result.push(quote.shipper_phone);
    }
    if(quote.shipper_email) {
      result.push(quote.shipper_email);
    }
    return result.join(" / ");
  }

  getConsigneeDetails(quote) {    
    if(quote.consignee) {
      return quote.consignee.name;
    }
    var result = [];
    if(quote.consignee_name) {
      result.push(quote.consignee_name);
    }
    if(quote.consignee_phone) {
      result.push(quote.consignee_phone);
    }
    if(quote.consignee_email) {
      result.push(quote.consignee_email);
    }
    return result.join(" / ");
  }

  render() {
    return (
      <div className="container-body">
        <div className="row">
          <div className="col-md">
            <div className="form-header">
              <label>Quote {this.props.quote_id}</label>
            </div>
          </div>
        </div>

        {!isReadOnly() && hasType("customer") &&
          <div className="row row-small">
            <div className="col-md">
              { this.isEditable() &&
                <AsyncButton
                  variant="outlined"
                  color="secondary"
                  onClick={this.editQuote.bind(this)}
                  icon="edit"
                  text="Edit" />
              }
              <AsyncButton
                variant="outlined"
                color="secondary"
                onClick={this.cloneQuote.bind(this)}
                icon="content_copy"
                text="Clone" />
              { this.isDeletable() &&
                <AsyncButton
                  className="button-danger-outlined"
                  variant="outlined"
                  onClick={this.deleteQuote.bind(this)}
                  icon="delete"
                  text="Delete" />
              }
            </div>
          </div>
        }
                
        <div className="row row-small">
          <div className="col-md-6 col-lg-6">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td width={200} className="header-text">Status</td>
                  <td><LoadingContent mustBeLoaded={this.props.quote} content={() => <span className={"badge " + Mapper.toQuoteStatusBadge(this.props.quote.status)} >{Mapper.fromQuoteStatus(this.props.quote.status)}</span> } /></td>
                </tr>
                { this.props.quote && this.props.quote.shipment && this.props.quote.shipment.id &&
                <tr>
                  <td width={200} className="header-text">Shipment</td>
                  <td><Link to={`/shipments/all/${this.props.quote.shipment.id}`}>Shipment {this.props.quote.shipment.id}</Link></td>
                </tr>
                }
                <tr>
                  <td width={200} className="header-text">Created at</td>
                  <td><LoadingContent mustBeLoaded={this.props.quote} content={() => Converter.toDate(this.props.quote.created_at)} /></td>
                </tr>
                { (hasType("forwarder") || hasType("admin")) &&
                  <tr>
                    <td width={200} className="header-text">Customer</td>
                    <td><LoadingContent mustBeLoaded={this.props.quote} content={() => this.props.quote.customer.name} /></td>
                  </tr>
                }
                { (hasType("forwarder") || hasType("admin")) && this.props.quote &&
                  <tr>
                    <td width={200} className="header-text">Created by</td>
                    <td><LoadingContent mustBeLoaded={this.props.quote} content={() => this.props.quote.created_by ? this.props.quote.created_by.full_name : "-"} /></td>
                  </tr>
                }
                { (hasType("forwarder") || hasType("admin")) && this.props.quote &&
                  <tr>
                    <td width={200} className="header-text">Accepted by</td>
                    <td><LoadingContent mustBeLoaded={this.props.quote} content={() => this.props.quote.accepted_by ? this.props.quote.accepted_by.full_name : "-"} /></td>
                  </tr>
                }
                <tr>
                  <td width={200} className="header-text">Reference</td>
                  <td><LoadingContent mustBeLoaded={this.props.quote} content={() => this.props.quote.reference} /></td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Incoterms</td>
                  <td><LoadingContent mustBeLoaded={this.props.quote} content={() => Mapper.fromIncoterms(this.props.quote.incoterms)} /></td>
                </tr>
                <tr>
                  <td width={200} className="header-text">{"Pickup " + (this.props.quote && this.props.quote.pickup_location ? this.props.quote.pickup_location.type : " location")}</td>
                  <td><LoadingContent mustBeLoaded={this.props.quote} content={() => this.formatLocation(this.props.quote.pickup_location)} /></td>
                </tr>
                <tr>
                  <td width={200} className="header-text">{"Delivery " + (this.props.quote && this.props.quote.delivery_location ? this.props.quote.delivery_location.type : " location")}</td>
                  <td><LoadingContent mustBeLoaded={this.props.quote} content={() => this.formatLocation(this.props.quote.delivery_location)} /></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6 col-lg-6">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td width={200} className="header-text">Business type</td>
                  <td><LoadingContent mustBeLoaded={this.props.quote} content={() => Mapper.fromBusinessType(this.props.quote.business_type)} /></td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Shipper</td>
                  <td><LoadingContent mustBeLoaded={this.props.quote} content={() => this.getShipperDetails(this.props.quote)} /></td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Consignee</td>
                  <td><LoadingContent mustBeLoaded={this.props.quote} content={() => this.getConsigneeDetails(this.props.quote)} /></td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Comments</td>
                  <td>
                    <LoadingContent row={3} mustBeLoaded={this.props.quote} content={() => this.getComments()} />
                    {!isReadOnly() && this.props.quote && !this.state.is_commenting &&
                    <AsyncButton
                      variant="outlined"
                      color="secondary"
                      onClick={this.addComment.bind(this)}
                      icon="message"
                      text="Add Comment" />
                    }
                    {this.state.is_commenting &&
                      <form autoComplete="off" onSubmit={this.commentQuote.bind(this)}>                            
                        <div className="row">
                          <div className="col-md-12">
                            <MultilineTextField
                              label="Comment"
                              variant="outlined"
                              fullWidth
                              required
                              min_rows={4}
                              margin="dense"
                              value={this.state.comment_quote.comment}
                              name="comment"
                              onChange={this.handleQuoteCommentChange.bind(this)}
                              InputLabelProps={{
                                shrink: this.state.comment_quote.comment !== undefined,
                              }}
                            />
                          </div>
                        </div>                            
                        <div className="row">
                          <div className="col-md-12">
                            <AsyncButton
                              type="submit"
                              variant="outlined"
                              color="primary"
                              icon="check"
                              text="Submit" />
                            <AsyncButton
                              className="button-danger-outlined"
                              variant="outlined"
                              color="secondary"
                              onClick={this.cancelAddComment.bind(this)}
                              icon="close"
                              text="Cancel" />
                          </div>
                        </div>
                      </form>
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default QuoteContainer;
