import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ComposedChart, Bar, Line, XAxis, YAxis, Label, CartesianGrid, Tooltip, Legend, PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { getAllInvoiceGroups, getInvoicesReport } from '../../../shared/services/invoice_service.js';
import { getAllAccounts } from '../../../shared/services/account_service';
import ErrorHandler from '../../../shared/util/error_handler.js';
import Converter from '../../../shared/util/converter.js';
import AsyncButton from '../../../shared/components/async_button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Mapper from '../../../shared/util/mapper';
import DomUtil from '../../../shared/util/dom_util.js';
import RowDataTable from '../../../shared/components/row_data_table';
import TableUtil from '../../../shared/util/table_util'

class InvoicesRevenueReport extends React.Component {

  constructor(props) {
    super(props)

    var default_selection = "this_year";

    this.state = {
      range: {
        selection: default_selection,
        from: this.getFrom(default_selection),
        to: this.getTo(default_selection)
      },
      accounts: [],
      invoice_groups: [],
      counterparty: undefined,
      invoices: [],
      invoices_statistics: undefined,
      sale_per_counterparty_statistics: undefined,
      sale_active_index: 0,
      purchase_per_counterparty_statistics: undefined,
      purchase_active_index: 0,
    };
  }

  componentWillMount() {
    this.getAllAccounts();
    this.getAllInvoiceGroups();
  }

  getAllAccounts() {
    getAllAccounts().then((response) => {
      if (response) {
        this.setState({ accounts: response.accounts });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getAllInvoiceGroups() {
    getAllInvoiceGroups().then((response) => {
      if (response) {
        this.setState({ invoice_groups: response.invoice_groups });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  createReport(e) {
    e.preventDefault();
    var form = e.currentTarget;
    DomUtil.disableFormSubmitButtonClass(form);
    this.setState({ invoices: [] });
    
    getInvoicesReport(this.getCounterparty(),  undefined, this.state.range.from, this.state.range.to, this.state.show_all_in_group).then((invoices) => {
      if (invoices) {
        for(const invoice of invoices) {
          if(invoice.resource_type === 'shipment') {
            invoice.resource = "Shipment " + invoice.resource_id;
          }
          if(invoice.resource_type === 'invoice_group') {
            var invoice_group = this.state.invoice_groups.find(i => i.id === invoice.resource_id);
            if(invoice_group) {
              invoice.resource = invoice_group.name;
            } else {
              invoice.resource = "Invoice group " + invoice.resource_id.substring(0, 6);
            }
          }
        }

        var invoices_statistics = this.getInvoicesStatistics(invoices);
        var sale_per_counterparty_statistics = this.getPerCounterpartyStatistics(invoices, "sale");
        var purchase_per_counterparty_statistics = this.getPerCounterpartyStatistics(invoices, "purchase");

        this.setState({
          invoices: invoices,
          invoices_statistics: invoices_statistics,
          sale_per_counterparty_statistics: sale_per_counterparty_statistics,
          purchase_per_counterparty_statistics: purchase_per_counterparty_statistics
        });
      }
      DomUtil.enableFormSubmitButtonClass(form);
    }).catch(error => {
      ErrorHandler.showError(error);
      DomUtil.enableFormSubmitButtonClass(form);
    });
  }

  getCounterparty() {
    if(this.state.counterparty) {
      return this.state.counterparty.id;
    }
  }

  handleRangeChange(e) {
    this.state.range.selection = e.target.value;
    this.state.range.from = this.getFrom(e.target.value);
    this.state.range.to = this.getTo(e.target.value);
    this.setState({ range: this.state.range });
  }

  handleDateChange(e) {
    this.state.range.selection = "custom";
    this.state.range[e.target.name] = e.target.value;
    this.setState({ range: this.state.range });
  }

  handleCounterpartySelectionChanged(e, newValue) {
    if(newValue) {
      this.setState({ counterparty: newValue });
    } else {
      this.setState({ counterparty: { id: undefined } });
    }
  }

  handleChange(property, e) {
    var value = e.target.value === "" ? undefined : e.target.value;
    this.setState({ [property]: value });
  }

  firstAccountLetter(option) {
    return option.name[0].toUpperCase();
  }

  getCounterpartyOptionLabel(value) {
    var counterparty;
    if(value.id) {
      counterparty = this.state.accounts.find(a => a.id === value.id);
    }
    if(!counterparty) {
      return "";
    }
    return counterparty.name;
  }

  getFrom(selection) {
    var now = new Date();
    var from = undefined;

    switch(selection) {
      case "this_month":
        from = new Date(now.getFullYear(), now.getMonth(), 1);
        break;
      case "last_month":
        from = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        break;
      case "last_three_months":
        from = new Date(now.getFullYear(), now.getMonth() - 3, 1);
        break;
      case "this_year":
        from = new Date(now.getFullYear(), 0, 1);
        break;
      case "last_year":
        from = new Date(now.getFullYear() - 1, 0, 1);
        break;
    }

    return this.toIsoString(from);
  }

  getTo(selection) {
    var now = new Date();
    var to = undefined;

    switch(selection) {
      case "this_month":
        to = now;
        break;
      case "last_month":
        to = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case "last_three_months":
        to = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case "this_year":
        to = now;
        break;
      case "last_year":
        to = new Date(now.getFullYear(), 0, 0);
        break;
    }

    return this.toIsoString(to);
  }

  toIsoString(localDate) {
    if(localDate) {
      var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
      var localISOTime = (new Date(localDate - tzoffset)).toISOString();
      return localISOTime.slice(0,10);
    }
  }

  getGrossTotalInvoiceType(type) {
    var invoicesOfType = this.getInvoicesOfType(type);
    
    var total = 0;
    for(const invoice of invoicesOfType) {
      total += invoice.total_gross_amount;
    }

    return total;    
  }

  getNetTotalInvoiceType(type) {
    var invoicesOfType = this.getInvoicesOfType(type);

    var total = 0;
    for(const invoice of invoicesOfType) {
      total += invoice.total_net_amount;
    }

    return total;    
  }

  getInvoicesOfType(type) {
    if(!this.state.invoices) {
      return [];
    }
    return this.state.invoices.filter((invoice) => invoice.type === type);
  }
 
  getCountOfInvoiceType(type) {
    var invoicesOfType = this.getInvoicesOfType(type);
    if(!invoicesOfType) {
      return "0";
    }
    return invoicesOfType.length.toString();
  }

  getGrossProfit() {
    return this.getGrossTotalInvoiceType("sale") - this.getGrossTotalInvoiceType("purchase");
  }

  getNetProfit() {
    return this.getNetTotalInvoiceType("sale") - this.getNetTotalInvoiceType("purchase");
  }

  getGrossProfitClass() {
    if(this.getGrossProfit() > 0) {
      return "value-positive";
    }
    if(this.getGrossProfit() < 0) {
      return "value-negative";
    }
    return "";
  }

  getNetProfitClass() {
    if(this.getNetProfit() > 0) {
      return "value-positive";
    }
    if(this.getNetProfit() < 0) {
      return "value-negative";
    }
    return "";
  }

  getInvoicesRows() {    
    if(!this.state.invoices) {
      return;
    }
    
    if(!this.state.invoices) {
      return { data: [], totalElements: 0 };
    }
    
    return { data: this.state.invoices, totalElements: this.state.invoices.length, ready: true };
  }

  getInvoicesHeaders() {
    var headers = [];
  
    headers.push({
      field: "resource",
      headerName: "Resource",
      minWidth: 150,
      filter: "agTextColumnFilter",
      filterParams: {
        maxNumConditions: 1,
        filterOptions: TableUtil.getTextColumnFilters()
      },
      rowGroup: true,
      floatingFilter: true,
      sortable:  true,
      hide: true
    });
    
    headers.push(TableUtil.getTextColumn(
      "invoice_number",
      "Invoice number",
      (params) => {
        if(params.value) {          
          return <Link to={`/invoices/all/${params.data.id}`} target="_blank" rel="noopener noreferrer" >{params.value}</Link>;
        }
        return "";
      }
    ));
    
    headers.push(TableUtil.getTextColumn("counterparty.name", "Counterparty"));

    headers.push(TableUtil.getSetColumn(
      "status",
      "Status",
      (params) => {
        if(params.value) {
          return <span className={"badge " + Mapper.toInvoiceStatusBadge(params.value)} >{Mapper.fromInvoiceStatus(params.value)}</span>;
        }
        return <span/>
      },
      Object.keys(Mapper.invoiceStatuses()),
      (value) => Mapper.fromInvoiceStatus(value)
    ));

    headers.push(TableUtil.getDateColumn(
      "invoice_date",
      "Invoice date",
      (params) => Converter.toDate(params.value),
    ));

    headers.push(TableUtil.getDateColumn(
      "due_date",
      "Due date",
      (params) => Converter.toDate(params.value),
    ));

    headers.push(TableUtil.getDateColumn(
      "sent_at",
      "Sent at",
      (params) => Converter.toDatetime(params.value),
    ));

    headers.push(TableUtil.getSetColumn(
      "type",
      "Type",
      (params) => Mapper.fromInvoiceType(params.value),
      Object.keys(Mapper.invoiceTypes()),
      (value) => Mapper.fromInvoiceType(value)
    ));

    headers.push({
      field: "total_gross_amount",
      headerName: "Total gross amount",
      minWidth: 150,
      cellRenderer: this.getAmount,
      filter: "agNumberColumnFilter",
      filterParams: {
        maxNumConditions: 1,
        filterOptions: TableUtil.getNumberColumnFilters()
      },
      floatingFilter: true,
      sortable: true,
      aggFunc: 'sum',
      valueGetter: this.valueGetter
    });
    
    headers.push({
      field: "total_net_amount",
      headerName: "Total net amount",
      minWidth: 150,
      cellRenderer: this.getAmount,
      filter: "agNumberColumnFilter",
      filterParams: {
        maxNumConditions: 1,
        filterOptions: TableUtil.getNumberColumnFilters()
      },
      floatingFilter: true,
      sortable: true,
      aggFunc: 'sum',
      valueGetter: this.valueGetter
    });
    
    return headers;
  }

  valueGetter(params) {
    if(!params.data) {
      return;
    }

    var factor = 1;
    if(params.data.type == 'purchase')  {
      factor = -1;
    }
    
    return params.data[params.colDef.field] * factor;
  }

  getAmount(params) {
    var className;
    if(params.value < 0) {
      className = "invoice-negative"
    }
    return <span className={className}>{Converter.toCurrency("eur", params.value)}</span>;
  }

  getInvoicesStatistics(invoices) {
    var invoices_statistics = [];
    for(const invoice of invoices) {
      var invoice_date = new Date(invoice.invoice_date)
      var statistics_date = new Date(invoice_date.getFullYear(), invoice_date.getMonth(), 1);
      var invoice_statistics = invoices_statistics.find(s => s.date.getTime() === statistics_date.getTime());

      if(!invoice_statistics) {
        invoice_statistics = {
          date: statistics_date,
          total_gross_sale: 0,
          total_net_sale: 0,
          total_gross_purchase: 0,
          total_net_purchase: 0,
          gross_profit: 0,
          net_profit: 0
        };
        invoices_statistics.push(invoice_statistics);
      }

      if(invoice.type === "sale") {
        invoice_statistics.total_gross_sale += invoice.total_gross_amount;
        invoice_statistics.total_net_sale += invoice.total_net_amount;
        invoice_statistics.gross_profit += invoice.total_gross_amount;
        invoice_statistics.net_profit += invoice.total_net_amount;
      } else if(invoice.type === "purchase") {
        invoice_statistics.total_gross_purchase += invoice.total_gross_amount;
        invoice_statistics.total_net_purchase += invoice.total_net_amount;
        invoice_statistics.gross_profit -= invoice.total_gross_amount;
        invoice_statistics.net_profit -= invoice.total_net_amount;
      }
    }

    return invoices_statistics;
  }

  formatDateTickItem(tickItem) {
    return new Date(tickItem).toLocaleString('en-En', { year: "numeric", month: "long"});
  }

  getMockData() {
    var data = [];
    var date = new Date();
    for(var i = 0; i < 6; i++) {
      data.push({ 
        date: date,
        total_gross_sale: 100000,
        total_net_sale: 100000,
        total_gross_purchase: 100000,
        total_net_purchase: 100000,
        gross_profit: 0,
        net_profit: 0
      })
      date = new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000);
    }
    return data.reverse();
  }

  getCounterparties(type) {
    var counterparties = this.state.invoices.filter((invoice) => invoice.type === type).map(a => a.counterparty);
    var distinctParties = Array.from(new Set(counterparties.map(c => c.id))).map(id => counterparties.find(c => c.id === id));

    var partiesWithTotal = [];
    for(const counterparty of distinctParties) {
      var total = this.getNetTotalInvoicesOfCounterpartyAndType(counterparty, type);
      partiesWithTotal.push({
        counterparty: counterparty,
        total: total
      });
    }

    return partiesWithTotal.sort((a, b) => b.total - a.total).map(party => party.counterparty);
  }

  getGrossTotalInvoicesOfCounterpartyAndType(counterparty, type) {
    var invoicesOfCounterpartyAndType = this.getInvoicesOfCounterpartyAndType(counterparty, type);
    
    var total = 0;
    for(const invoice of invoicesOfCounterpartyAndType) {
      total += invoice.total_gross_amount;
    }

    return total;    
  }

  getNetTotalInvoicesOfCounterpartyAndType(counterparty, type) {
    var invoicesOfCounterpartyAndType = this.getInvoicesOfCounterpartyAndType(counterparty, type);

    var total = 0;
    for(const invoice of invoicesOfCounterpartyAndType) {
      total += invoice.total_net_amount;
    }

    return total;    
  }
 
  getCountOfInvoiceCounterpartyAndType(counterparty, type) {
    var invoicesOfCounterpartyAndType = this.getInvoicesOfCounterpartyAndType(counterparty, type);
    if(!invoicesOfCounterpartyAndType) {
      return "0";
    }
    return invoicesOfCounterpartyAndType.length.toString();
  }

  getInvoicesOfCounterpartyAndType(counterparty, type) {
    if(!this.state.invoices) {
      return [];
    }
    return this.state.invoices.filter((invoice) => invoice.counterparty.id === counterparty.id && invoice.type === type);
  }

  getPerCounterpartyStatistics(invoices, type) {
    var per_counterparty = [];
    for(const invoice of invoices) {
      if(invoice.type !== type) {
        continue;
      }

      var of_counterparty = per_counterparty.find(s => s.counterparty.id === invoice.counterparty.id);
      if(!of_counterparty) {
        of_counterparty = {
          name: invoice.counterparty.name,
          counterparty: invoice.counterparty,
          total_gross: 0,
          total_net: 0,
        };
        per_counterparty.push(of_counterparty);
      }

      of_counterparty.total_gross += invoice.total_gross_amount;
      of_counterparty.total_net += invoice.total_net_amount;
    }

    return per_counterparty.sort((a, b) => b.total_net - a.total_net);
  }

  renderActiveShape(props) {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
  
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {`${(percent * 100).toFixed(2)}%`}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
          {`${Converter.toCurrency("eur", value)}`}
        </text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {payload.name}
        </text>
      </g>
    );
  };
  
  render() {
    return (
      <div>
        
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`/reports` + this.props.location.search}>Reports</Link></BreadcrumbItem>
            <BreadcrumbItem active>Invoices revenue</BreadcrumbItem>
          </Breadcrumb>
        
          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Parameters</label>
                </div>
              </div>
            </div>

            <form autoComplete="off" onSubmit={this.createReport.bind(this)}>
              
              <div className="row row-small">
                <div className="col-md-4">  
                <FormControl
                  variant="outlined"
                  margin="dense"
                  fullWidth >
                  <InputLabel>Range *</InputLabel>
                  <Select
                    native
                    required
                    label="Range *"
                    name="range"
                    value={this.state.range.selection}
                    onChange={this.handleRangeChange.bind(this)} >
                      {Object.keys(Mapper.dateRanges()).map(status => {
                        return (<option key={"range_status_" + status} value={status}>{Mapper.fromDateRange(status)}</option>);
                      })}
                  </Select>
                </FormControl>
                </div>

                <div className="col-md-4">
                  <TextField
                    label="From"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="from"
                    value={this.state.range.from}
                    onChange={this.handleDateChange.bind(this)} />
                </div>

                <div className="col-md-4">
                  <TextField
                    label="To"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="to"
                    value={this.state.range.to}
                    onChange={this.handleDateChange.bind(this)} />
                </div>
              </div>

              <div className="row row-small">
                <div className="col-md-4">
                  <Autocomplete
                    key="counterparty"
                    options={this.state.accounts}
                    groupBy={this.firstAccountLetter.bind(this)}
                    getOptionLabel={(option) => this.getCounterpartyOptionLabel(option)}
                    value={this.state.counterparty}
                    onChange={this.handleCounterpartySelectionChanged.bind(this)}
                    renderInput={(params) => 
                      <TextField {...params}
                        label="Counterparty"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />
                    }
                  />
                </div>
              </div>

              <div className="row row-small">
                <div className="col-md-4">
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    fullWidth>
                    <InputLabel>Show all invoices in group</InputLabel>
                    <Select
                      native
                      label="Show all invoices in group"
                      value={this.state.show_all_in_group}
                      name="show_all_in_group"
                      onChange={(e) => {this.handleChange("show_all_in_group", e)}} >
                        <option key={"show_all_in_group_empty"} value=""></option>
                        {Object.keys(Mapper.booleans()).map(type => {
                          return (<option key={"show_all_in_group_"+type} value={type}>{Mapper.fromBoolean(type)}</option>);
                        })}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="row row-small">
                <div className="col-md">
                  <AsyncButton
                    type="submit"
                    variant="outlined"
                    color="primary"
                    icon="check"
                    text="Generate" />
                </div>
              </div>

            </form>
          </div>
        
          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Invoices revenue</label>
                </div>
              </div>
            </div>
            <div className="row row-small">
              <div className="col-md-6 col-lg-6">
                <table className="table table-details" >
                  <tbody>
                    <tr className="row-no-top-border-and-padding">
                      <td width={200} className="header-text"></td>
                      <td width={200} className="header-text">Gross</td>
                      <td width={200} className="header-text">Net</td>
                      <td width={200} className="header-text">Invoices</td>
                    </tr>
                    <tr>
                      <td width={200} className="header-text">Revenues</td>
                      <td>{Converter.toCurrency("eur", this.getGrossTotalInvoiceType("sale"))}</td>
                      <td>{Converter.toCurrency("eur", this.getNetTotalInvoiceType("sale"))}</td>
                      <td>{this.getCountOfInvoiceType("sale")}</td>
                    </tr>
                    <tr>
                      <td width={200} className="header-text">Expenses</td>
                      <td>{Converter.toCurrency("eur", this.getGrossTotalInvoiceType("purchase"))}</td>
                      <td>{Converter.toCurrency("eur", this.getNetTotalInvoiceType("purchase"))}</td>
                      <td>{this.getCountOfInvoiceType("purchase")}</td>
                    </tr>
                    <tr>
                      <td width={200} className="header-text-bold">Profit</td>
                      <td>{<span className={this.getGrossProfitClass()}><b>{Converter.toCurrency("eur", this.getGrossProfit())}</b></span>}</td>
                      <td>{<span className={this.getNetProfitClass()}><b>{Converter.toCurrency("eur", this.getNetProfit())}</b></span>}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        
          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Net profit graph</label>
                </div>
              </div>
            </div>
            {this.state.invoices_statistics &&
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <ResponsiveContainer width="100%" height="100%" minHeight="300px">
                    <ComposedChart data={this.state.invoices_statistics} margin={{ top: 0, right: 0, left: 50, bottom: 0 }} >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" tickFormatter={this.formatDateTickItem} minTickGap={50} />
                      <YAxis tickFormatter={(label) => Converter.toCurrency("eur", label)} />
                      <Tooltip labelFormatter={this.formatDateTickItem} wrapperStyle={{ fontWeight: 600 }} formatter={(label) => Converter.toCurrency("eur", label)} />
                      <Legend wrapperStyle={{fontWeight: 600}} />
                      <Bar type="basis" dataKey="total_net_sale" name="Revenues" fill="#0ca649" />
                      <Bar type="basis" dataKey="total_net_purchase" name="Expenses" fill="#c80000" />
                      <Line type="linear" dataKey="net_profit" name="Profit" stroke="#3182bd" strokeWidth={3} dot={{ strokeWidth: 3, r: 5 }} />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </div>
            }
            {!this.state.invoices_statistics &&
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <ResponsiveContainer width="100%" height="100%" minHeight="300px">
                    <ComposedChart data={this.getMockData()} margin={{ top: 0, right: 0, left: 50, bottom: 0 }} >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" tickFormatter={this.formatDateTickItem} minTickGap={50} />
                      <YAxis tickFormatter={(label) => Converter.toCurrency("eur", label)} />
                      <Tooltip labelFormatter={this.formatDateTickItem} wrapperStyle={{ fontWeight: 600 }} formatter={(label) => Converter.toCurrency("eur", label)} />
                      <Legend wrapperStyle={{fontWeight: 600}} />
                      <Bar type="basis" dataKey="total_net_sale" name="Revenues" fill="#0ca649" visibility={"hidden"} />
                      <Bar type="basis" dataKey="total_net_purchase" name="Expenses" fill="#c80000" visibility={"hidden"} />
                      <Line type="linear" dataKey="net_profit" name="Profit" stroke="#3182bd" strokeWidth={3} dot={{ strokeWidth: 3, r: 5 }} visibility={"hidden"} />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </div>
            }
          </div>
          
          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Revenues per counterparty</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <table className="table table-details" >
                  <tbody>
                    <tr className="row-no-top-border-and-padding">
                      <td width={200} className="header-text"></td>
                      <td width={200} className="header-text">Gross</td>
                      <td width={200} className="header-text">Net</td>
                      <td width={200} className="header-text">Invoices</td>
                    </tr>
                    {this.getCounterparties("sale").map(counterparty => {
                      return (
                      <tr>
                        <td width={200} className="header-text">{counterparty.name}</td>
                        <td>{Converter.toCurrency("eur", this.getGrossTotalInvoicesOfCounterpartyAndType(counterparty, "sale"))}</td>
                        <td>{Converter.toCurrency("eur", this.getNetTotalInvoicesOfCounterpartyAndType(counterparty, "sale"))}</td>
                        <td>{this.getCountOfInvoiceCounterpartyAndType(counterparty, "sale")}</td>
                      </tr>
                      )
                    })}
                    <tr>
                      <td width={200} className="header-text-bold">Total</td>
                      <td>{<span className="value-positive"><b>{Converter.toCurrency("eur", this.getGrossTotalInvoiceType("sale"))}</b></span>}</td>
                      <td>{<span className="value-positive"><b>{Converter.toCurrency("eur", this.getNetTotalInvoiceType("sale"))}</b></span>}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-6 col-lg-6">
                {this.state.sale_per_counterparty_statistics &&
                  <ResponsiveContainer width="100%" height="100%" minHeight="300px">
                    <PieChart>
                      <Pie
                        data={this.state.sale_per_counterparty_statistics}
                        dataKey="total_net"
                        activeIndex={this.state.sale_active_index}
                        activeShape={this.renderActiveShape}
                        cx="50%"
                        cy="50%"
                        innerRadius={75}
                        outerRadius={100}
                        fill="#006D93"
                        onMouseEnter={(e, index) => this.setState({ sale_active_index: index, })}
                        />
                    </PieChart>
                  </ResponsiveContainer>
                }
              </div>
            </div>
          </div>
          
          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Expenses per counterparty</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <table className="table table-details" >
                  <tbody>
                    <tr className="row-no-top-border-and-padding">
                      <td width={200} className="header-text"></td>
                      <td width={200} className="header-text">Gross</td>
                      <td width={200} className="header-text">Net</td>
                      <td width={200} className="header-text">Invoices</td>
                    </tr>
                    {this.getCounterparties("purchase").map(counterparty => {
                      return (
                      <tr>
                        <td width={200} className="header-text">{counterparty.name}</td>
                        <td>{Converter.toCurrency("eur", this.getGrossTotalInvoicesOfCounterpartyAndType(counterparty, "purchase"))}</td>
                        <td>{Converter.toCurrency("eur", this.getNetTotalInvoicesOfCounterpartyAndType(counterparty, "purchase"))}</td>
                        <td>{this.getCountOfInvoiceCounterpartyAndType(counterparty, "purchase")}</td>
                      </tr>
                      )
                    })}
                    <tr>
                      <td width={200} className="header-text-bold">Total</td>
                      <td>{<span className="value-negative"><b>{Converter.toCurrency("eur", this.getGrossTotalInvoiceType("purchase"))}</b></span>}</td>
                      <td>{<span className="value-negative"><b>{Converter.toCurrency("eur", this.getNetTotalInvoiceType("purchase"))}</b></span>}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-6 col-lg-6">
                {this.state.purchase_per_counterparty_statistics &&
                  <ResponsiveContainer width="100%" height="100%" minHeight="300px">
                    <PieChart>
                      <Pie
                        data={this.state.purchase_per_counterparty_statistics}
                        dataKey="total_net"
                        activeIndex={this.state.purchase_active_index}
                        activeShape={this.renderActiveShape}
                        cx="50%"
                        cy="50%"
                        innerRadius={75}
                        outerRadius={100}
                        fill="#006D93"
                        onMouseEnter={(e, index) => this.setState({ purchase_active_index: index, })}
                        />
                    </PieChart>
                  </ResponsiveContainer>
                }
              </div>
            </div>
          </div>
        
          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Invoices overview</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm">
                <RowDataTable
                  name="invoices_revenue_table_state"
                  minHeight={800}
                  rowData={this.getInvoicesRows()}
                  columnDefs={this.getInvoicesHeaders()}
                  overlayNoRowsTemplate={"There are no invoices"}
                />
              </div>
            </div>
          </div>
        
        </div>
      </div >
    );
  }
}

export default InvoicesRevenueReport;
