import React from 'react';
import { getInvoices  } from '../../../services/invoice_service';
import Mapper from '../../../util/mapper';
import Converter from '../../../util/converter';
import RowDataTable from '../../../components/row_data_table';
import TableUtil from '../../../util/table_util';
import { hasType } from '../../../services/authentication_service';

class Invoices extends React.Component {

  constructor(props) {
    super(props)
    this.rowTableRef = React.createRef();
  }

  onPaginationChanged(page, size) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", page);
    searchParams.set("size", size);
    this.props.history.replace(`${location.pathname}?${searchParams.toString()}`);
  }

  onSortChanged(sort) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("sort", sort);
    this.props.history.replace(`${location.pathname}?${searchParams.toString()}`);
  }

  onFilterChanged(filters) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("filters", JSON.stringify(filters));
    this.props.history.replace(`${location.pathname}?${searchParams.toString()}`);
  }
  
  getInvoiceHeaders() {
    var headers = [];
  
    headers.push(TableUtil.getTextColumn("invoice_number", "Invoice number"));
    
    if(hasType("forwarder") || hasType("admin")) {
      headers.push(TableUtil.getTextColumn("counterparty.name", "Counterparty"));
    }

    headers.push(TableUtil.getSetColumn(
      "status",
      "Status",
      (params) => <span className={"badge " + Mapper.toInvoiceStatusBadge(params.value)} >{Mapper.fromInvoiceStatus(params.value)}</span>,
      Object.keys(Mapper.invoiceStatuses()),
      (value) => Mapper.fromInvoiceStatus(value)
    ));

    headers.push(TableUtil.getDateColumn(
      "invoice_date",
      "Invoice date",
      (params) => Converter.toDate(params.value),
    ));

    headers.push(TableUtil.getDateColumn(
      "due_date",
      "Due date",
      (params) => {
        var className;
        if(params.data.status == 'open' && new Date(params.value) < new Date())  {
          className = "invoice-due-date-expired"
        }
        return <span className={className}>{Converter.toDate(params.value)}</span>;
      }
    ));

    if(hasType("forwarder") || hasType("admin")) {
      headers.push(TableUtil.getDateColumn(
        "sent_at",
        "Sent at",
        (params) => Converter.toDatetime(params.value),
      ));
    }

    if(hasType("forwarder") || hasType("admin")) {
      headers.push(TableUtil.getSetColumn(
        "type",
        "Type",
        (params) => Mapper.fromInvoiceType(params.value),
        Object.keys(Mapper.invoiceTypes()),
        (value) => Mapper.fromInvoiceType(value)
      ));
    }

    headers.push(TableUtil.getNumberColumn(
      "total_gross_amount",
      "Total gross amount",
      (params) => Converter.toCurrency("eur", params.value),
    ));
    
    headers.push(TableUtil.getNumberColumn(
      "total_net_amount",
      "Total net amount",
      (params) => Converter.toCurrency("eur", params.value),
    ));
    
    return headers;
  }

  getRows(page, blockSize, sort, filters) {
    return new Promise((resolve, reject) => {
      if(this.props.statuses) {
        filters.push({
          "field": "status",
          "comparator": "in",
          "values": this.props.statuses
        });
      }

      return getInvoices(page, blockSize, sort, JSON.stringify(filters))
      .then((response) => {
        if(!response) {
          reject("no data");
          return;
        }
        resolve({ content: response.content, total_elements: response.total_elements });
      }).catch(error => {
        reject(error);
      });
    });
  };

  getPage() {
    const params = new URLSearchParams(this.props.location.search);
    return params.get('page');
  }

  getPageSize() {
    const params = new URLSearchParams(this.props.location.search);
    return params.get('size');
  }

  getSort() {
    const params = new URLSearchParams(this.props.location.search);
    return params.get('sort');
  }

  getFilters() {
    const params = new URLSearchParams(this.props.location.search);
    var filters = params.get('filters');
    if(filters) {
      return JSON.parse(filters);
    }
  }

  render() {
    return (
      <div className="container-body">

        <div className="row">
          <div className="col-md">
            <div className="form-header">
              <label>Invoices</label>
            </div>
          </div>
        </div>
        
        <div className="row">
          <div className="col-sm">
            <RowDataTable
              ref={this.rowTableRef}
              name={this.props.name}
              rowModelType="serverSide"
              rowData={{ totalElements: 0, ready: false }}
              getRows={this.getRows.bind(this)}
              columnDefs={this.getInvoiceHeaders()}
              overlayNoRowsTemplate={this.props.emptyMessage}
              pagination={true}
              initialPage={this.getPage()}
              initialPageSize={this.getPageSize()}
              initialSort={this.getSort()}
              initialFilters={this.getFilters()}
              defaultPageSize={100}
              onRowClicked={this.props.openInvoice.bind(this)}
              onPaginationChanged={this.onPaginationChanged.bind(this)}
              onSortChanged={this.onSortChanged.bind(this)}
              onFilterChanged={this.onFilterChanged.bind(this)}
            />
          </div>
        </div>

      </div>
    );
  }
}

export default Invoices;
