import React from 'react';
import { logout } from '../../shared/services/authentication_service';
import { getQuoteCount } from '../../shared/services/quote_service';
import { getShipmentCount } from '../../shared/services/shipment_service';
import { getInvoiceCount } from '../../shared/services/invoice_service';
import EventBus from '../../shared/components/event_bus';
import ErrorHandler from '../../shared/util/error_handler';
import ParentMenu from '../../shared/components/parent_menu';

class Menu extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      quote_count: {},
      shipment_count: {},
      invoice_count: {}
    }
  }

  componentDidMount() {
    this.getQuoteCount();
    this.getShipmentCount();
    this.getInvoiceCount();

    EventBus.on("updateQuote", (data) => {
      this.getQuoteCount();
      this.getShipmentCount();
    });
    
    EventBus.on("updateShipment", (data) => {
      this.getShipmentCount();
    });
    
    EventBus.on("updateInvoice", (data) => {
      this.getInvoiceCount();
    });
  }

  getQuoteCount() {    
    getQuoteCount(["pending"]).then((quote_count) => {
      if (quote_count) {
        this.setState({ quote_count: quote_count });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getShipmentCount() {
    getShipmentCount().then((shipment_count) => {
      if (shipment_count) {
        this.setState({ shipment_count: shipment_count });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getInvoiceCount() {
    getInvoiceCount().then((invoice_count) => {
      if (invoice_count) {
        this.setState({ invoice_count: invoice_count });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getActiveQuotesCount() {
    return this.state.quote_count.pending;
  }

  getActiveShipmentsCount() {
    return this.state.shipment_count.opened + this.state.shipment_count.ready + this.state.shipment_count.received + this.state.shipment_count.in_transit + this.state.shipment_count.at_destination + this.state.shipment_count.delivered;
  }

  getOpenInvoiceCount() {
    return this.state.invoice_count.open;
  }

  render() {
    return <ParentMenu
      children={this.props.children}
      location={this.props.location}
      topbar_items={[
        {
          name: "Settings",
          icon: "fas fa-cog",
          link: "settings"
        },
        {
          name: "Sign out",
          icon: "fas fa-sign-out-alt",
          onClick: () => logout()
        }
      ]}
      sidebar_items={[
        {
          title: "Services",
          items: [
            {
              name: "Dashboard",
              icon: "fas fa-chart-area",
              link: ""
            },
            {
              name: "Quotes",
              icon: "fas fa-tag",
              link: "quotes/active",
              sub_items: [
                {
                  name: "Active",
                  link: "quotes/active",
                  counter: this.getActiveQuotesCount()
                },
                {
                  name: "All",
                  link: "quotes/all"
                }
              ]
            },
            {
              name: "Shipments",
              icon: "fas fa-truck",
              link: "shipments/active",
              sub_items: [
                {
                  name: "Active",
                  link: "shipments/active",
                  counter: this.getActiveShipmentsCount()
                },
                {
                  name: "All",
                  link: "shipments/all"
                }
              ]
            },
            {
              name: "Warehouses",
              icon: "fas fa-warehouse",
              link: "warehouses"
            },
            {
              name: "Accounts",
              icon: "fas fa-user-friends",
              link: "accounts"
            }
          ]
        },
        {
          title: "Company",
          items: [
            {
              name: "Account",
              icon: "fas fa-building",
              link: "account"
            },
            {
              name: "Invoices",
              icon: "fas fa-file-alt",
              link: "invoices/open",
              sub_items: [
                {
                  name: "Open",
                  link: "invoices/open",
                  counter: this.getOpenInvoiceCount()
                },
                {
                  name: "All",
                  link: "invoices/all"
                }
              ]
            }
          ]
        }
      ]}
    />;
  }
}

export default Menu;
