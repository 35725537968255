import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import PackagesTable from '../../../../components/packages_table.js';
import alertDialog from '../../../../components/alert.js';
import MatchUtil from '../../../../util/match_util.js';

class LoadColliTab extends React.Component {

  constructor(props) {
    super(props)
    
    this.state = {
      shipment: props.shipment,
      stock: props.stock,
      package_number: ""
    };
  }

  onKeyDown(e) {
    if (e.key === 'Enter') {
      if(e.target.name === 'package_number') {
        this.getPackage(e);
      }
    }
  }

  handleChange(e) {
    var value = e.target.value;
    this.setState({ [e.target.name]: value });
  }

  getPackage(e) {
    e.preventDefault();

    if(!this.state.package_number) {
      return;
    }

    var _package = this.findPackage(this.state.package_number)
    if(!_package) {
      alertDialog(<span>✘&nbsp;&nbsp;Package not found</span>, `Package with number ${this.state.package_number} not found.`, "theme-red");
      this.setState({ package_number: "" })
      return;
    }
    
    alertDialog(<span>✓&nbsp;&nbsp;Package added</span>, `Package with number ${_package.package_number} marked as complete.`, "theme-green");
    _package.dispatch_date = new Date();
    _package.loading_status = 'complete';
    for(const part of _package.parts) {
      part.dispatch_date = new Date();
      part.loading_status = 'complete';
      part.loading_quantity = part.quantity;
    }
  
    this.setState({ package_number: "" })
    this.props.updateShipment(this.state.shipment);
  }

  findPackage(number) {
    var matches = MatchUtil.matchNumber(this.state.shipment.colli, "package_number", number);
    if(matches.length === 1) {
      return matches[0];
    }
  }

  getCount(status) {
    var count = 0;

    for(const _package of this.state.shipment.colli) {
      if(_package.loading_status === status) {
        count++;
      }
    }

    return count;
  }

  render() {
    return (
      <div>        
        <div className="row row-small">
          <div className="col-md-12 col-lg-6">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td width={200} className="header-text">To do</td>
                  <td>{this.getCount("todo")}</td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Complete</td>
                  <td>{this.getCount("complete")}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row row-small">
          <div className="col-md-3">
            <span className='text-padding'>Scan or enter package number..</span>
            <div className='file-input-download-parent'>
              <TextField
                label="Package number"
                variant="outlined"
                fullWidth
                required
                autoFocus
                margin="dense"
                name="package_number"
                value={this.state.package_number}
                onKeyDown={this.onKeyDown.bind(this)}
                onChange={this.handleChange.bind(this)} 
              />
              <Button
                className="button-icon-only button-inline clear-form-button"
                color="primary"
                type="button"
                disabled={!this.state.package_number}
                onClick={this.getPackage.bind(this)}
                startIcon={<Icon>search</Icon>} />
            </div>                      
          </div>
        </div>
        
        <div className="row">
          <div className="col-sm">
            <PackagesTable
                name="shipment_load_packages_table_state"
                data={this.state.shipment}
                headers={[
                  "number",
                  "loading_number",
                  "order_number",
                  "quantity",
                  "loading_quantity",
                  "scan_loading_status",
                  "reference",
                  "part_price",
                  "country_of_origin",
                  "description",
                  "dispatch_date"
                ]}
                groupDefaultExpanded={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default LoadColliTab;
