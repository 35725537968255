import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Theme from './theme';

class TaskDialog extends React.Component {

  constructor(props) {
    super();

    this.state = {
      account: props.account,
      task: props.task,
    };

    this.formRef = React.createRef();
  }

  handlTaskChange(property, e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.state.task[property] = value;
    this.setState({ task: this.state.task });
  }

  submit(e) {
    e.preventDefault();

    if(!this.formRef.current.checkValidity()) {
      this.formRef.current.reportValidity();
      return;
    }
    
    this.props.proceed(this.state.task)
  }

  firstUserLetter(option) {
    return option.full_name[0].toUpperCase();
  }
  
  getUserOptionLabel(value) {
    var user;
    if(this.state.account && value.id) {
      user = this.state.account.users.find(u => u.id === value.id);
    }
    if(!user) {
      return "";
    }
    return user.full_name;
  }

  handleAutoSelectionChanged(property, e, newValue) {
    this.state.task[property] = newValue;
    this.setState({ task: this.state.task });
  }

  render() {
    return (
      <Theme>
        <Dialog fullWidth={true} maxWidth={"md"} open={this.props.show} onClose={this.props.dismiss} >
          <DialogTitle>{this.props.title}</DialogTitle>
          <DialogContent>
            <form autoComplete="off" ref={this.formRef} onSubmit={this.submit.bind(this)}>

              <div className="row">
                <div className="col-md-12">
                  <TextField
                    label="Title"
                    variant="outlined"
                    required
                    fullWidth
                    autoFocus
                    margin="dense"
                    value={this.state.task.title}
                    onChange={(e) => {this.handlTaskChange("title", e)}}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <Autocomplete
                    key="id"
                    options={this.state.account.users.sort((a, b) => -b.full_name[0].localeCompare(a.full_name[0]))}
                    groupBy={this.firstUserLetter.bind(this)}
                    getOptionLabel={(option) => this.getUserOptionLabel(option)}
                    value={this.state.task.assignee}
                    onChange={this.handleAutoSelectionChanged.bind(this, "assignee")}
                    renderInput={(params) => 
                      <TextField {...params}
                        label="Assignee"
                        variant="outlined"
                        fullWidth
                        required
                        margin="dense"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />                      
                    }
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <TextField
                    label="Date"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={this.state.task.date}
                    onChange={(e) => {this.handlTaskChange("date", e)}}
                  />
                </div>
              </div>
              
              </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.dismiss()} color="primary">Cancel</Button>
            <Button onClick={this.submit.bind(this)} color="primary">Save</Button>
          </DialogActions>
        </Dialog>
      </Theme>
    )
  }
}

TaskDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
  title: PropTypes.string,
  account: PropTypes.object,
  task: PropTypes.object,
}

export default confirmable(TaskDialog);
