import React from 'react';
import { Prompt } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import AsyncButton from '../../components/async_button.js';
import { getShipment, updateShipmentLoad } from '../../services/shipment_service.js';
import ErrorHandler from '../../util/error_handler.js';
import PackagesTable from '../../components/packages_table.js';
import alertDialog from '../../components/alert.js';
import confirmInbound from '../../components/confirm_inbound.js';
import alert from '../../components/alert.js';
import confirm from '../../components/confirm.js';
import DomUtil from '../../util/dom_util.js';
import Mapper from '../../util/mapper.js';
import EventBus from '../../components/event_bus.js';
import confirmQuestion from '../../components/confirm_question.js';
import MatchUtil from '../../util/match_util.js';
import PrintUtil from '../../util/print_util.js';
import confirmInput from '../../components/confirm_input.js'

class InboundShipment extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      shipment_id: this.props.match.params.shipment_id,
      shipment: undefined,
      selected_package: undefined,
      part_number: "",
      changes: false
    };

    this.packagesTableRef = React.createRef();
  }

  componentWillMount() {
    this.getShipment();
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeunload);
  }

  beforeunload(e) {
    e.preventDefault();
    e.returnValue = true;
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onKeyDown(e) {
    if (e.key === 'Enter') {
      if(e.target.name === 'package_number') {
        this.getPackage(e);
      }
      if(e.target.name === 'part_number') {
        this.getParts(e);
      }
    }
  }  

  getPackage(e) {
    e.preventDefault();

    if(!this.state.package_number) {
      return;
    }

    var _package = this.findPackage(this.state.package_number)
    if(!_package) {
      alertDialog(<span>✘&nbsp;&nbsp;Package not found</span>, `Package with number ${this.state.package_number} not found.`, "theme-red").then(() => {
        this.setState({ selected_package: undefined, package_number: "", part_number: "" });
      }).catch(() => {
        this.setState({ selected_package: undefined, package_number: "", part_number: "" });
      });
      return;
    }
    
    this.setState({ package_number: _package.package_number, selected_package: _package})
    this.setPackageNumberFilter(_package.package_number);
  }

  findPackage(number) {
    var matches = MatchUtil.matchNumber(this.state.shipment.colli, "package_number", number);
    if(matches.length === 1) {
      return matches[0];
    }
  }

  setPackageNumberFilter(package_number) {
    this.packagesTableRef.current.addFilter("number", package_number);
  }

  clearPackage(e) {
    e.preventDefault();
    this.setState({ selected_package: undefined, package_number: "", part_number: "" })
    this.setPackageNumberFilter(undefined);
  }

  setUnloadingStatus(part) {
    if(Number(part.unloading_quantity) === Number(part.quantity)) {
      part.unloading_status = 'complete';
    } else {
      part.unloading_status = 'deviation';
    }
  }

  getParts(e) {
    e.preventDefault();

    if(!this.state.part_number) {
      return;
    }

    var parts = this.findParts(this.state.selected_package, this.state.part_number);
    if(!parts || parts.length === 0) {
      alertDialog(<span>✘&nbsp;&nbsp;Part number {this.state.part_number} not found</span>, `Part number ${this.state.part_number} not found in pacakge ${this.state.selected_package.package_number}.`, "theme-red").then(() => {
        this.setState({ part_number: "" });
      }).catch(() => {
        this.setState({ part_number: "" });
      });
      return;
    }

    const clone = JSON.parse(JSON.stringify(parts));
    confirmInbound(`Package ${this.state.selected_package.package_number}`, this.state.selected_package, clone, this.generatePackageNumberLabel).then((updated_parts) => {
      this.updateParts(this.state.selected_package, updated_parts);
  
      if(!this.state.changes) {
        window.addEventListener('beforeunload', this.beforeunload);
      }

      this.setState({ shipment: this.state.shipment, part_number: "", changes: true }, () => this.persistColli());
    }).catch(() => {
      this.setState({ part_number: "" });
    });
  }

  updateParts(_package, updated_parts) {
    for(const updated_part of updated_parts) {
      var part = _package.parts.find(p => p.part_number === updated_part.part_number && p.order_number === updated_part.order_number);

      // Add the extra part to the package, it does not exist yet.
      if(!part && updated_part.reference === "EXTRA") {
        const clone = JSON.parse(JSON.stringify(updated_part));
        _package.parts.push(clone);
        part = clone;
      }
      
      part.unloading_quantity = updated_part.unloading_quantity;
      part.new_package_number = updated_part.new_package_number;
      if(part.unloading_quantity || part.unloading_quantity === 0) {
        this.setUnloadingStatus(part);
        if(!part.arrival_date) {
          part.arrival_date = new Date();
        }
      }

      if(part.new_package_number) {
        var new_package = this.findPackage(part.new_package_number);
        if(!new_package) {
          new_package = {
            package_number: part.new_package_number,
            loading_number: _package.loading_number,
            parts: []
          };
          this.state.shipment.colli.push(new_package);
        }

        if(new_package.package_number !== _package.package_number) {
          var existing_part = new_package.parts.find(p => p.part_number === part.part_number && p.order_number === part.order_number);

          if(existing_part) {
            if(existing_part.quantity || existing_part.quantity === 0) {
              if(part.quantity) {
                existing_part.quantity += part.quantity;
              }
            } else {
              existing_part.quantity = part.quantity;
            }
            
            if(existing_part.unloading_quantity || existing_part.unloading_quantity === 0) {
              if(part.unloading_quantity) {
                existing_part.unloading_quantity += part.unloading_quantity;
              }
            } else {
              existing_part.unloading_quantity = part.unloading_quantity;
            }

            if(existing_part.loading_quantity || existing_part.loading_quantity === 0) {
              if(part.loading_quantity) {
                existing_part.loading_quantity += part.loading_quantity;
              }
            } else {
              existing_part.loading_quantity = part.loading_quantity;
            }

            if(!existing_part.arrival_date) {
              existing_part.arrival_date = part.arrival_date;
            }

            if(existing_part.unloading_quantity || existing_part.unloading_quantity === 0) {
              this.setUnloadingStatus(existing_part);
            }
          } else {
            new_package.parts.push(part);
          }

          var partIndex = _package.parts.indexOf(part);
          _package.parts.splice(partIndex, 1);

          if(_package.parts.length === 0) {
            var packageIndex = this.state.shipment.colli.indexOf(_package);
            if(packageIndex >= 0) {
              this.state.shipment.colli.splice(packageIndex, 1);
            }
          }
        }
      }
    }
  }
  
  showReferences() {
    if(!this.state.selected_package) {
      return;
    }
    
    var references = [];
    for(const part of this.state.selected_package.parts) {
      if(part.reference) {
        if(!references.find(r => r === part.reference)) {
          references.push(part.reference);
        }
      }
    }

    alert(`References of package ${this.state.selected_package.package_number}`, 
      <div>
        <span>Unique references:</span>
        <br/>
        {references.map(reference => {
          return (<span>-&nbsp;{reference}<br/></span>);
        })}
      </div>
    );
  }

  markPackageAsComplete() {
    confirm("Please confirm", <span><strong className='warning'>!! Make sure parts of different customers are separated !!</strong><br/><br/>Are you sure you want to mark all 'To do' parts as completed?</span>).then(() => {
      for(const part of this.state.selected_package.parts) {
        if(part.unloading_status == 'todo') {
          part.unloading_quantity = part.quantity;
          this.setUnloadingStatus(part);
          if(!part.arrival_date) {
            part.arrival_date = new Date();
          }
        }
      }

      if(!this.state.changes) {
        window.addEventListener('beforeunload', this.beforeunload);
      }
      
      this.setState({ shipment: this.state.shipment, changes: true }, () => this.persistColli());
    }).catch(() => {
      // required to handle cancel
    });
  }

  findParts(_package, number) {
    return MatchUtil.matchNumber(_package.parts, "part_number", number);
  }

  findSamePartInColli(number) {
    var matching_parts = [];
    for(const _package of this.state.shipment.colli) {
      var matches = MatchUtil.matchNumber(_package.parts, "part_number", number);
      matching_parts.push(...matches);
    }
    var unique_part_numbers =  [...new Set(matching_parts.map(m => m.part_number))];
    if(unique_part_numbers.length === 1) {
      return matching_parts[0];
    }
  }

  getShipment() {
    getShipment(this.state.shipment_id).then((shipment) => {
      if (shipment) {
        this.setState({ shipment: shipment }, () => {
          var persistedColli = this.loadPersistedColli();
          if(persistedColli) {
            this.state.shipment.colli = persistedColli;
            this.setState({ shipment: this.state.shipment, changes: true });
          } else {
            this.setInitialUnloadingStatus();
          }
        });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  setInitialUnloadingStatus() {
    for(const _package of this.state.shipment.colli) {
      for(const part of _package.parts) {
        if(!part.unloading_status) {
          part.unloading_status = "todo";
        }
      }
    }
    this.setState({ shipment: this.state.shipment });
  }

  isLoaded() {
    return this.state.shipment;
  }

  isActivePath() {    
    return this.props.location.pathname.startsWith('/shipments/active');
  }

  getBasePath() {
    if(this.isActivePath()) {
      return '/shipments/active';
    }
    return '/shipments/all';
  }

  getBreadCrumbTitle() {
    if(this.isActivePath()) {
      return 'Active Shipments';
    }
    return 'All Shipments';
  }

  getCount(status) {
    var count = 0;

    for(const _package of this.state.shipment.colli) {
      for(const part of _package.parts) {
        if(part.unloading_status === status) {
          count++;
        }
      }
    }

    return count;
  }

  saveInbound(e) {
    e.preventDefault();
    var form = e.currentTarget;
    confirmQuestion("Please provide", "Are you finished with the inbound processing of this shipment?").then((response) => {
      DomUtil.disableFormSubmitButtonClass(form);
      var shipment = Mapper.toShipmentRequestLoad(this.state.shipment);

      if(response.answer === true) {
        if(this.crossCheckExtraParts()) {
          alertDialog(<span>⚠&nbsp;&nbsp;Extra parts have been matched with missing parts</span>, <div>
            <span>These extra parts have been updated with the details of the missing parts and the status is set to 'To do'. </span>
            <br/>
            <span>Please re-scan these parts and move them to the designated customer package.</span>
          </div>, "theme-orange");
          DomUtil.enableFormSubmitButtonClass(form);
          return;
        }

        var count = this.partsWithStatusTodoCount();
        if(count > 0) {
          confirm(<span>⚠&nbsp;&nbsp;Please confirm</span>, <span>{count} parts have not been scanned, do you want to mark them as missing?</span>, "theme-orange").then(() => {
            this.updateCompletedShipment(form, shipment);
          }).catch(() => {
            DomUtil.enableFormSubmitButtonClass(form);
          });
        } else {
          this.updateCompletedShipment(form, shipment);
        }
      } else {
        this.updateShipment(form, shipment);
      }
    });
  }

  updateCompletedShipment(form, shipment) {
    shipment.status = "delivered";
    for(const _package of shipment.colli) {
      for(const part of _package.parts) {
        if(!part.unloading_quantity) {
          part.unloading_quantity = 0;
        }
      }
    }
    this.updateShipment(form, shipment)
  }

  updateShipment(form, shipment) {
    updateShipmentLoad(this.state.shipment_id, shipment).then(() => {
      this.setState({ changes: false }, () => {
        this.clearPersistedColli();
        EventBus.dispatch("updateShipment");
        this.props.history.push(`${this.getBasePath()}/${this.state.shipment_id}${this.props.location.search}`);
      });
    }).catch(error => {
      ErrorHandler.showError(error);
      DomUtil.enableFormSubmitButtonClass(form);
    });
  }

  partsWithStatusTodoCount() {
    var count = 0;
    for(const _package of this.state.shipment.colli) {
      for(const part of _package.parts) {
        if(part.unloading_status === 'todo') {
          count += 1;
        }
      }
    }
    return count;
  }

  crossCheckExtraParts() {
    var extraMatched = false;
    
    for(const packageWithExtra of this.state.shipment.colli) {
      for(const extraPart of packageWithExtra.parts) {
        if(extraPart.reference === "EXTRA") {

          for(const packageWithMissing of this.state.shipment.colli) {
            if(packageWithExtra.package_number === packageWithMissing.package_number) {
              continue; // Dont process the extra package
            }

            var matches = MatchUtil.matchNumber(packageWithMissing.parts, "part_number", extraPart.part_number);
            for(const missingPart of matches) {
              if(!missingPart.unloading_quantity || missingPart.unloading_quantity < missingPart.quantity) {
                extraMatched = true;
                const clone = JSON.parse(JSON.stringify(missingPart));

                var missing_quantity;
                if(!missingPart.unloading_quantity) {
                  missing_quantity = missingPart.quantity;
                  missingPart.unloading_quantity = 0;
                } else {
                  missing_quantity = missingPart.quantity - missingPart.unloading_quantity;
                }

                if(missing_quantity > extraPart.unloading_quantity) {
                  missing_quantity = extraPart.unloading_quantity;
                }

                missingPart.quantity = missingPart.quantity - missing_quantity;
                if(missingPart.quantity === 0) {
                  var partIndex = packageWithMissing.parts.indexOf(missingPart);
                  packageWithMissing.parts.splice(partIndex, 1);
                } else if(missingPart.quantity === missingPart.unloading_quantity) {
                  missingPart.unloading_status = 'complete';
                }

                clone.unloading_quantity = undefined;
                clone.unloading_status = 'todo';
                clone.quantity = missing_quantity;
                packageWithExtra.parts.push(clone);
                
                extraPart.unloading_quantity = extraPart.unloading_quantity - missing_quantity;
                if(extraPart.unloading_quantity === 0) {
                  var partIndex = packageWithExtra.parts.indexOf(extraPart);
                  packageWithExtra.parts.splice(partIndex, 1);
                }
              }
            }
          }
        }
      }
    }

    this.setState({ shipment: this.state.shipment, selected_package: undefined, package_number: "", part_number: "", changes: true }, () => this.persistColli());

    return extraMatched;
  }

  generatePackageNumberLabel(e) {
    e.preventDefault();
    var target = e.currentTarget;
    DomUtil.disableClass(target);

    var now = new Date();
    var year = now.getFullYear().toString().substr(-2);
    var month = (now.getMonth() + 1).toString().padStart(2, '0');
    var day = now.getDate().toString().padStart(2, '0');

    var hours = now.getHours().toString().padStart(2, '0');
    var minutes = now.getMinutes().toString().padStart(2, '0');

    var first = Math.floor((Math.random() * 9) + 1).toString();
    var second = Math.floor((Math.random() * 9) + 1).toString();
    var third = Math.floor((Math.random() * 9) + 1).toString();

    var new_package_number = year + month + day + " " + hours + minutes + " " + first + second + third;

    var params = new Map();
    params.set("PackageNumber", new_package_number);
    params.set("BarcodeData", new_package_number);

    PrintUtil.printLabel("/labels/package_number.zpl", "/labels/package_number.xml", params).then(() => {
      DomUtil.enableClass(target);
    }).catch((error) => {
      console.log(error);
      alert("Unable to print label", error);
      DomUtil.enableClass(target);
    });
  }

  printPackageVerifiedLabel(e) {
    e.preventDefault();

    if(!this.state.selected_package) {
      return;
    }

    var target = e.currentTarget;
    DomUtil.disableClass(target);
    
    var references = [];
    for(const part of this.state.selected_package.parts) {
      if(part.reference) {
        if(!references.find(r => r === part.reference)) {
          references.push(part.reference);
        }
      }
    }
    
    var params = new Map();
    params.set("PackageNumber", this.state.selected_package.package_number);
    params.set("BarcodeData", this.state.selected_package.package_number);
    params.set("References", references.join('\n'));
    params.set("ReferencesZebra", references.join('\\&'));
    params.set("ReferencesSize", Math.min(220 / references.length, 70));
    params.set("ReferencesLines", Math.max(references.length, 3));
    params.set("ShipmentNumber", this.state.shipment_id);

    PrintUtil.printLabel("/labels/inbound_confirmation.zpl", "/labels/inbound_confirmation.xml", params, 3).then(() => {
      DomUtil.enableClass(target);
    }).catch((error) => {
      console.log(error);
      alert("Unable to print label", error);
      DomUtil.enableClass(target);
    });
  }

  hasTodoParts() {
    if(!this.state.selected_package) {
      return true;
    }

    for(const part of this.state.selected_package.parts) {
      if(part.unloading_status === "todo") {
        return true;
      }
    }

    return false;
  }

  movePartsToOtherPackage(e) {
    e.preventDefault();

    if(!this.state.selected_package) {
      return;
    }
    
    confirmInput("Please specify", "Please specify the package number to move the parts to:", "New package number", "text", undefined).then(new_package_number => {
      var updated_parts = JSON.parse(JSON.stringify(this.state.selected_package.parts));
      for(const part of updated_parts) {
        part.new_package_number = new_package_number;
      }

      this.updateParts(this.state.selected_package, updated_parts);
  
      if(!this.state.changes) {
        window.addEventListener('beforeunload', this.beforeunload);
      }

      this.setPackageNumberFilter(undefined);
      this.setState({ shipment: this.state.shipment, selected_package: undefined, package_number: "", part_number: "", changes: true }, () => this.persistColli());
    }).catch(() => {
      // no-op
    });
  }

  addExtraPart(e) {
    e.preventDefault();

    var _package = this.state.selected_package;
    if(!_package) {
      _package = {
        package_number: "unknown",
        parts: []
      };
    }
    
    confirmInput("Please scan part", "Please scan the part number:", "Part number", "text", undefined).then(part_number => {
      var matching_part = this.findSamePartInColli(part_number);
      if(matching_part) {
        part_number = matching_part.part_number;
      }
      
      this.setState({part_number: part_number});

      var parts = this.findParts(_package, part_number);
      if(parts && parts.length !== 0) {
        alertDialog(<span>✘&nbsp;&nbsp;Part number {this.state.part_number} present in package</span>, `Part number ${this.state.part_number} is found in pacakge ${_package.package_number}.`, "theme-red").then(() => {
          this.setState({ part_number: "" });
        }).catch(() => {
          this.setState({ part_number: "" });
        });
        return;
      }

      var part = {
        part_number: part_number,
        package_number: _package.package_number,
        order_number: "EXTRA",
        reference: "EXTRA",
        quantity: 0,
        origin: matching_part ? matching_part.origin : undefined,
        description:  matching_part ? matching_part.description : undefined
      };

      confirmInbound(`Package ${_package.package_number}`, _package, [ part ], this.generatePackageNumberLabel).then((updated_parts) => {
        this.updateParts(_package, updated_parts);
    
        if(!this.state.changes) {
          window.addEventListener('beforeunload', this.beforeunload);
        }

        this.setState({ shipment: this.state.shipment, part_number: "", changes: true }, () => this.persistColli());
      }).catch(() => {
        this.setState({ part_number: "" });
      });
      
    }).catch(() => {
      // no-op
    });
  }

  persistColli() {
    if(!this.state.shipment || !this.state.shipment.colli) {
      return;
    }
    localStorage.setItem(this.state.shipment.id + "-inbound-colli", JSON.stringify(this.state.shipment.colli));
  }

  loadPersistedColli() {
    return JSON.parse(localStorage.getItem(this.state.shipment.id + "-inbound-colli"));
  }

  clearPersistedColli() {
    localStorage.removeItem(this.state.shipment.id + "-inbound-colli");
  }  

  render() {
    return (
      <div>
        
        <Prompt when={this.state.changes} message="Are you sure you want to leave this page, data is not saved." />

        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.getBasePath()}/${this.props.location.search}`}>{this.getBreadCrumbTitle()}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.getBasePath()}/${this.state.shipment_id}${this.props.location.search}`}>{this.state.shipment_id}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Inbound</BreadcrumbItem>
          </Breadcrumb>
          
          {this.isLoaded() &&
            <div>
              <div className="container-body">
                <div className="row">
                  <div className="col-md">
                    <div className="form-header">
                      <label>Inbound Shipment {this.state.shipment_id}</label>
                    </div>
                  </div>
                </div>

                <form autoComplete="off" onSubmit={this.saveInbound.bind(this)}>
                  <div className="row row-small">
                    <div className="col-md-12 col-lg-12">
                      <div className='button-center-form'>
                        <AsyncButton
                          type="submit"
                          variant="outlined"
                          color="primary"
                          icon="check"
                          text="Save" />
                        <AsyncButton
                          variant="outlined"
                          color="primary"
                          icon="print"
                          text="New package label"
                          onClick={this.generatePackageNumberLabel.bind(this)} />
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div className="container-body">
                <div className="row">
                  <div className="col-md">
                    <div className="form-header">
                      <label>Colli</label>
                    </div>
                  </div>
                </div>
                
                <div className="row row-small">
                  <div className="col-md-12 col-lg-6">
                    <table className="table table-details" >
                      <tbody>
                        <tr className="row-no-top-border-and-padding">
                          <td width={200} className="header-text">To do</td>
                          <td>{this.getCount("todo")}</td>
                        </tr>
                        <tr>
                          <td width={200} className="header-text">Complete</td>
                          <td>{this.getCount("complete")}</td>
                        </tr>
                        <tr>
                          <td width={200} className="header-text">Deviation</td>
                          <td>{this.getCount("deviation")}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                {!this.state.selected_package &&
                  <div className="row row">
                    <div className="col-md-2">
                      <span className='text-padding'>Scan or enter package number..</span>
                      <div className='file-input-download-parent'>
                        <TextField
                          label="Package number"
                          variant="outlined"
                          fullWidth
                          required
                          autoFocus
                          margin="dense"
                          name="package_number"
                          value={this.state.package_number}
                          onKeyDown={this.onKeyDown.bind(this)}
                          onChange={this.handleChange.bind(this)} 
                        />
                        <Button
                          className="button-icon-only button-inline clear-form-button"
                          color="primary"
                          type="button"
                          disabled={!this.state.package_number}
                          onClick={this.getPackage.bind(this)}
                          startIcon={<Icon>search</Icon>} />
                      </div>                      
                    </div>
                    <div className="col-md-8">
                      <div>
                        <span className='text-padding'>Actions:</span>
                        <Button
                          className="button-inline"
                          variant="outlined"
                          color="primary"
                          onClick={this.addExtraPart.bind(this)}
                          startIcon={<Icon>add</Icon>} >
                            Extra part
                        </Button>
                      </div>
                    </div>
                  </div>
                }
                {this.state.selected_package &&
                  <div className="row row">
                    <div className="col-md-2">
                      <span className='text-padding'>Selected package:</span>
                      <div className='file-input-download-parent'>
                        <TextField
                          label="Package number"
                          variant="outlined"
                          fullWidth
                          required
                          margin="dense"
                          value={this.state.selected_package.package_number}
                          disabled={true}
                        />
                        <Button
                          className="button-icon-only button-inline clear-form-button"
                          color="secondary"
                          type="button"
                          onClick={this.clearPackage.bind(this)}
                          startIcon={<Icon>clear</Icon>} />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <span className='text-padding'>Scan or enter part number..</span>
                      <div className='file-input-download-parent'>
                        <TextField
                          label="Part number"
                          variant="outlined"
                          fullWidth
                          required
                          autoFocus
                          margin="dense"
                          name="part_number"
                          value={this.state.part_number}
                          onKeyDown={this.onKeyDown.bind(this)}
                          onChange={this.handleChange.bind(this)} 
                        />
                        <Button
                          className="button-icon-only button-inline clear-form-button"
                          color="primary"
                          type="button"
                          disabled={!this.state.part_number}
                          onClick={this.getParts.bind(this)}
                          startIcon={<Icon>search</Icon>} />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div>
                        <span className='text-padding'>Package actions:</span>
                        <Button
                          className="button-inline"
                          variant="outlined"
                          color="primary"
                          onClick={this.showReferences.bind(this)}
                          startIcon={<Icon>visibility</Icon>} >
                            Show references
                        </Button>
                        <Button
                          className="button-inline"
                          variant="outlined"
                          color="primary"
                          onClick={this.markPackageAsComplete.bind(this)}
                          startIcon={<Icon>check</Icon>} >
                            Mark complete
                        </Button>
                        <AsyncButton
                          className="button-inline"
                          variant="outlined"
                          color="primary"
                          icon="print"
                          text="Verified label"
                          onClick={this.printPackageVerifiedLabel.bind(this)} />
                        <Button
                          className="button-inline"
                          variant="outlined"
                          color="primary"
                          disabled={this.hasTodoParts()}
                          onClick={this.movePartsToOtherPackage.bind(this)}
                          startIcon={<Icon>drive_file_move</Icon>} >
                            Move all parts
                        </Button>
                        <Button
                          className="button-inline"
                          variant="outlined"
                          color="primary"
                          onClick={this.addExtraPart.bind(this)}
                          startIcon={<Icon>add</Icon>} >
                            Extra part
                        </Button>
                      </div>
                    </div>
                  </div>
                }
                
                <div className="row">
                  <div className="col-sm">
                    <PackagesTable
                        ref={this.packagesTableRef}
                        name="shipment_inbound_packages_table_state"
                        data={this.state.shipment}
                        headers={[
                          "number",
                          "loading_number",
                          "order_number",
                          "quantity",
                          "unloading_quantity",
                          "scan_unloading_status",
                          "reference",
                          "part_price",
                          "country_of_origin",
                          "description",
                          "arrival_date"
                        ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
          
          {!this.isLoaded() &&
            <div className="container-body">
              <div className="row">
                <div className="col-md">
                  <div className="form-header">
                    <label>Inbound Shipment {this.state.shipment_id}</label>
                  </div>
                </div>
              </div>          
              <div className="row row-small">
                <div className="col-md-12 col-lg-8">
                  <Skeleton count={5}/>
                </div>
              </div>
            </div>
          }

        </div>
      </div>
    );
  }
}

export default InboundShipment;
