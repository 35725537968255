import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getShipment, deleteShipment, addShipmentDocuments, getShipmentTasks } from '../../services/shipment_service';
import { getInvoicesOfResource } from '../../services/invoice_service.js';
import { getWarehouses } from '../../services/warehouse_service.js';
import { getCurrentAccount } from '../../services/account_service.js';
import { hasType, isReadOnly } from '../../services/authentication_service.js';
import ErrorHandler from '../../util/error_handler';
import Mapper from '../..//util/mapper';
import FlowView from '../../components/flow_view.js';
import AsyncButton from '../../components/async_button.js';
import DomUtil from '../../util/dom_util.js';
import confirm from '../../components/confirm.js'
import confirmDocumentUpload from '../../components/confirm_documents_upload.js'
import EventBus from '../../components/event_bus.js';
import GeneralTab from './components/general_tab';
import CargoTab from './components/cargo_tab';
import TransportTab from './components/transport_tab';
import ContainersTab from './components/containers_tab';
import PalletsTab from './components/pallets_tab';
import ColliTab from './components/colli_tab';
import DocumentsTab from './components/documents_tab';
import InvoicesTab from './components/invoices_tab.js';
import TasksTab from './components/tasks_tab.js';
import { hasModule } from '../../services/environment_service';

class ShowShipment extends React.Component {

  constructor(props) {
    super(props)

    const params = new URLSearchParams(props.location.search);
    let current_tab = params.get('tab') ? params.get('tab') : "general";

    this.state = {
      current_tab: current_tab,
      tabs: [ "general"],
      shipment_id: this.props.match.params.shipment_id,
      account: undefined,
      shipment: undefined,
      invoices: undefined,
      warehouses: undefined,
      tasks: []
    };
  }

  componentDidMount() {
    this.getShipment();
    if(hasModule("invoicing") && (hasType("customer") || hasType("forwarder") || hasType("admin"))) {
      this.getInvoices();
    }
    if(hasModule("warehouse") && (hasType("agent") || hasType("forwarder") || hasType("admin"))) {
      this.getWarehouses();
    }
    if(hasModule("tasks") && (hasType("forwarder") || hasType("admin"))) {
      this.getCurrentAccount();
      this.getTasks();
    }
    this.isActivePath();
  }
  
  getTabs(shipment) {
    var tabs = [  "general", "cargo", "transport" ];

    if(this.isSeaShipment(shipment)) {
      tabs.push("containers");
    }

    tabs.push("pallets");
    tabs.push("colli");

    if(this.isShipmentOfType(shipment, [ "customer", "agent", "forwarder", "admin" ])) {
      tabs.push("documents");
    }

    if(hasModule("invoicing") && (this.isShipmentOfType(shipment, [ "customer", "forwarder", "admin" ]))) {
      tabs.push("invoices");
    }

    if(hasModule("tasks") && (this.isShipmentOfType(shipment, [ "forwarder", "admin" ]))) {
      tabs.push("tasks");
    }

    return tabs;
  }

  getTabName(tab) {
    switch(tab) {
      case "general":
        return "General";
      case "cargo":
        return "Cargo Details";
      case "transport":
        return "Transport";
      case "containers":
        return "Containers";
      case "pallets":
        return "Pallets";
      case "colli":
        return "Colli";
      case "documents":
        return "Documents";
      case "invoices":
        return "Invoices";
      case "tasks":
        return `Tasks (${this.getPendingTaskCount()})`;
      default:
        return;
    }
  }
  
  getTabClassName(name) {
    var className = "nav-link";

    if(name === this.state.current_tab) {
      className += " active";
    }
    
    return className;
  }

  getPendingTaskCount() {
    if(!this.state.tasks) {
      return 0;
    }
    return this.state.tasks.filter((task) => task.status === 'pending').length;
  }

  updateTab(tab) {
    this.setState({ current_tab: tab }, () => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("tab", tab);
      this.props.history.replace(`${location.pathname}?${searchParams.toString()}`);
    });
  }

  getStyle(tab) {
    if(tab === this.state.current_tab) {
      return { display: "block" };
    } else {
      return { display: "none" };
    }
  }

  getShipment() {
    getShipment(this.state.shipment_id).then((shipment) => {
      if (shipment) {
        this.setState({ shipment: shipment, tabs: this.getTabs(shipment) });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  shipmentUpdated(shipment) {
    this.setState({ shipment: shipment });
  }
  
  tasksUpdated(tasks) {
    this.setState({ tasks: tasks });    
    EventBus.dispatch("updateTask");
  }

  getInvoices() {
    getInvoicesOfResource("shipment", this.state.shipment_id).then((invoices) => {
      if (invoices) {
        this.setState({ invoices: invoices });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getWarehouses() {
    getWarehouses().then((warehouses) => {
      if (warehouses) {
        this.setState({ warehouses: warehouses });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getCurrentAccount() {
    getCurrentAccount().then((account) => {
      if (account) {
        this.setState({ account: account });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getTasks() {
    getShipmentTasks(this.state.shipment_id).then((response) => {
      if (response) {
        this.setState({ tasks: response.tasks });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  isActivePath() {    
    return this.props.location.pathname.startsWith('/shipments/active');
  }

  getBasePath() {
    if(this.isActivePath()) {
      return '/shipments/active';
    }
    return '/shipments/all';
  }

  getBreadCrumbTitle() {
    if(this.isActivePath()) {
      return 'Active Shipments';
    }
    return 'All Shipments';
  }

  getflowSteps() {
    var steps = [];
    Object.entries(Mapper.shipmentStatuses()).forEach(status => {
      steps.push({ key: status[0], name: status[1] });
    });
    return steps;
  }

  isSeaShipment(shipment) {
    if(!shipment) {
      return false;
    }
    return shipment.transport_type === "lcl" || shipment.transport_type === "fcl";
  }

  isShipmentOfType(shipment, types) {
    if(!shipment) {
      return false;
    }
    return types.includes(shipment.type);
  }

  editShipment(e) {
    e.preventDefault();
    this.props.history.push(`${this.getBasePath()}/${this.state.shipment_id}/edit${this.props.location.search}`);
  }

  inboundShipment(e) {
    e.preventDefault();
    this.props.history.push(`${this.getBasePath()}/${this.state.shipment_id}/inbound${this.props.location.search}`);
  }

  assignPackagesToShipment(e) {
    e.preventDefault();
    this.props.history.push(`${this.getBasePath()}/${this.state.shipment_id}/assign${this.props.location.search}`);
  }

  loadShipment(e) {
    e.preventDefault();
    this.props.history.push(`${this.getBasePath()}/${this.state.shipment_id}/load${this.props.location.search}`);
  }

  invoiceShipment(e) {
    e.preventDefault();
    this.props.history.push(`${this.getBasePath()}/${this.state.shipment_id}/invoicing${this.props.location.search}`);
  }

  cloneShipment() {
    this.props.history.push(`${this.getBasePath()}/create?clone_id=${this.state.shipment_id}`);
  }
  
  deleteShipment(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirm("Please confirm", "Are you sure to delete this shipment?").then(() => {
      DomUtil.disableClass(button);

      deleteShipment(this.state.shipment_id).then(() => {
        EventBus.dispatch("updateShipment");
        this.props.history.push(`${this.getBasePath()}${this.props.location.search}`);
      }).catch((error) => {
        ErrorHandler.showError(error);
        DomUtil.enableClass(button);
      });
    }).catch(() => {
      // required to handle cancel
    });
  }

  uploadDocument(e) {
    e.preventDefault();
    
    confirmDocumentUpload(this.state.shipment_id).then((data) => {
      var request = { documents: data.documents }
      addShipmentDocuments(this.state.shipment_id, request).then((shipment) => {
        EventBus.dispatch("updateShipment");
        this.setState({ shipment: shipment });
      }).catch((error) => {
        ErrorHandler.showError(error);
      });
    });
  }

  isActivePath() {    
    return this.props.location.pathname.startsWith('/shipments/active');
  }

  getBasePath() {
    if(this.isActivePath()) {
      return '/shipments/active';
    }
    return '/shipments/all';
  }
  
  isNotCompleted() {
    return this.state.shipment && this.state.shipment.status !== "completed";
  }
  
  hasStatus(status) {
    return this.state.shipment && this.state.shipment.status === status;
  }

  isInbound() {
    if(!this.state.warehouses) {
      return;
    }
    if(this.state.shipment && this.state.shipment.delivery_location && this.state.shipment.delivery_location.type === 'warehouse') {
      return this.state.warehouses.find(w => w.warehouse_code == this.state.shipment.delivery_location.warehouse_code) !== undefined;
    }
    return false;
  }

  isOutbound() {
    if(!this.state.warehouses) {
      return;
    }
    if(this.state.shipment && this.state.shipment.pickup_location && this.state.shipment.pickup_location.type === 'warehouse') {
      return this.state.warehouses.find(w => w.warehouse_code == this.state.shipment.pickup_location.warehouse_code) !== undefined;
    }
    return false;
  }

  render() {
    return (
      <div>

        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.getBasePath()}${this.props.location.search}`}>{this.getBreadCrumbTitle()}</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.props.match.params.shipment_id}</BreadcrumbItem>
          </Breadcrumb>

          <FlowView
            status={this.state.shipment ? this.state.shipment.status : undefined}
            flow={this.getflowSteps()}
          />

          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Shipment {this.state.shipment_id}</label>
                </div>
              </div>
            </div>
            
            { !isReadOnly() && this.isShipmentOfType(this.state.shipment, [ "customer", "agent" ]) &&
              <div className="row row-small">
                <div className="col-md">
                  { (this.isShipmentOfType(this.state.shipment, ["agent"]) && this.state.shipment && this.state.shipment.status !== "completed") &&
                      <AsyncButton
                        variant="outlined"
                        color="secondary"
                        onClick={this.editShipment.bind(this)}
                        icon="edit"
                        text="Edit" />
                  }
                  { (this.isShipmentOfType(this.state.shipment, ["agent"]) && hasModule("warehouse") && this.isInbound() && this.hasStatus("at_destination")) &&
                      <AsyncButton
                        variant="outlined"
                        color="secondary"
                        onClick={this.inboundShipment.bind(this)}
                        icon="input"
                        text="Inbound" />
                  }
                  { (this.isShipmentOfType(this.state.shipment, ["agent"]) && hasModule("warehouse") && this.isOutbound() && this.hasStatus("opened")) &&
                      <AsyncButton
                        variant="outlined"
                        color="secondary"
                        onClick={this.assignPackagesToShipment.bind(this)}
                        icon="inventory"
                        text="Assign packages" />
                  }
                  { (this.isShipmentOfType(this.state.shipment, ["agent"]) && hasModule("warehouse") && this.isOutbound() && this.hasStatus("ready")) &&
                      <AsyncButton
                        variant="outlined"
                        color="secondary"
                        onClick={this.loadShipment.bind(this)}
                        icon="output"
                        text="Load" />
                  }
                  <AsyncButton
                    variant="outlined"
                    color="secondary"
                    onClick={this.uploadDocument.bind(this)}
                    icon="upload_file"
                    text="Upload" />
                </div>
              </div>
            }
            
            { !isReadOnly() && this.isShipmentOfType(this.state.shipment, ["forwarder", "admin"]) &&
              <div className="row row-small">
                <div className="col-md">
                  <AsyncButton
                    variant="outlined"
                    color="secondary"
                    onClick={this.editShipment.bind(this)}
                    icon="edit"
                    text="Edit" />
                  { (hasModule("warehouse") && this.isInbound() && this.hasStatus("at_destination")) &&
                      <AsyncButton
                        variant="outlined"
                        color="secondary"
                        onClick={this.inboundShipment.bind(this)}
                        icon="input"
                        text="Inbound" />
                  }
                  { (hasModule("warehouse") && this.isOutbound() && this.hasStatus("opened")) &&
                      <AsyncButton
                        variant="outlined"
                        color="secondary"
                        onClick={this.assignPackagesToShipment.bind(this)}
                        icon="inventory"
                        text="Assign packages" />
                  }
                  { (hasModule("warehouse") && this.isOutbound() && this.hasStatus("ready")) &&
                      <AsyncButton
                        variant="outlined"
                        color="secondary"
                        onClick={this.loadShipment.bind(this)}
                        icon="output"
                        text="Load" />
                  }
                  {hasModule("invoicing") &&
                    <AsyncButton
                      variant="outlined"
                      color="secondary"
                      onClick={this.invoiceShipment.bind(this)}
                      icon="request_quote"
                      text="Invoicing" />
                  }
                  <AsyncButton
                    variant="outlined"
                    color="secondary"
                    onClick={this.cloneShipment.bind(this)}
                    icon="content_copy"
                    text="Clone" />
                  <AsyncButton
                    variant="outlined"
                    color="secondary"
                    onClick={this.uploadDocument.bind(this)}
                    icon="upload_file"
                    text="Upload" />
                  <AsyncButton
                    className="button-danger-outlined"
                    variant="outlined"
                    onClick={this.deleteShipment.bind(this)}
                    icon="delete"
                    text="Delete" />
                </div>
              </div>
            }
          </div>

          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <ul className="nav nav-tabs">
                    {this.state.tabs.map(tab => {
                      return (
                        <li key={tab} className="nav-item">
                          <a className={this.getTabClassName(tab)} onClick={this.updateTab.bind(this, tab)}>{this.getTabName(tab)}</a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="tabs">
              <div style={ this.getStyle("general") } >
                <GeneralTab 
                  shipment={this.state.shipment}
                  shipmentUpdated={this.shipmentUpdated.bind(this)}
                />
              </div>
              <div style={ this.getStyle("cargo") } >
                <CargoTab shipment={this.state.shipment} />
              </div>
              <div style={ this.getStyle("transport") } >
                <TransportTab shipment={this.state.shipment} />
              </div>
              { this.isSeaShipment(this.state.shipment) && 
                <div style={ this.getStyle("containers") } >
                  <ContainersTab shipment={this.state.shipment} />
                </div>
              }
              <div style={ this.getStyle("pallets") } >
                <PalletsTab shipment={this.state.shipment} />
              </div>
              <div style={ this.getStyle("colli") } >
                <ColliTab shipment={this.state.shipment} />
              </div>
              <div style={ this.getStyle("documents") } >
                <DocumentsTab shipment={this.state.shipment} />
              </div>
              <div style={ this.getStyle("invoices") } >
                <InvoicesTab
                  shipment={this.state.shipment}
                  invoices={this.state.invoices}
                />
              </div>
              <div style={ this.getStyle("tasks") } >
                <TasksTab
                  shipment_id={this.state.shipment_id}
                  account={this.state.account}
                  tasks={this.state.tasks}
                  tasksUpdated={this.tasksUpdated.bind(this)}
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default ShowShipment;
