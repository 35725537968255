import React from 'react';
import { Link } from 'react-router-dom';
import AsyncButton from '../../components/async_button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DomUtil from '../../util/dom_util';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getAddress, updateAddress, deleteAddress } from '../../services/account_service';
import { getCountries } from '../../services/location_service';
import ErrorHandler from '../../util/error_handler';
import Mapper from '../../util/mapper'
import confirm from '../../components/confirm'
import LoadingTextField from '../../components/loading_text_field'
import LoadingSelectField from '../../components/loading_select_field'

class ShowAddress extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      address_id: this.props.match.params.address_id,
      address: undefined,
      countries: undefined,
    };
  }

  componentDidMount() {
    this.getAddress();
    this.getCountries();
  }

  getAddress() {
    getAddress(this.state.address_id).then((address) => {
      if (address) {
        this.setState({ address: address });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getCountries() {
    getCountries().then((countries) => {
      if (countries) {
        this.setState({ countries: countries });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }
  
  firstLetter(option) {
    return option.name[0].toUpperCase();
  }

  handleChange(e) {
    this.state.address[e.target.name] = e.target.value;
    this.setState({ address: this.state.address });
  }

  handleAutoCompleteChange(e, newValue) {
    if(newValue) {
      this.state.address.country = newValue;
    } else {
      this.state.address.country = { name: "" };
    }
    this.setState({ address: this.state.address });
  }

  updateAddress(e) {
    e.preventDefault();
    var form = e.currentTarget;
    DomUtil.disableFormSubmitButtonClass(form);
    var update = Mapper.toAddressCreation(this.state.address);
    updateAddress(this.state.address_id, update).then(() => {
      this.props.history.push(`/account/` + this.props.location.search);
    }).catch(error => {
      ErrorHandler.showError(error);
      DomUtil.enableFormSubmitButtonClass(form);
    });
  }

  deleteAddress(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirm("Please confirm", `Are you sure you want to delete this address?`).then((result) => {
      DomUtil.disableClass(button);
      deleteAddress(this.state.address_id).then(() => {
        this.props.history.push(`/account/` + this.props.location.search);
      }).catch(() => {
        DomUtil.enableClass(button);
      });
    });
  }

  render() {
    return (
      <div>

        <div className="container-content">

        <Breadcrumb>
          <BreadcrumbItem><Link to={`/account/` + this.props.location.search}>Account</Link></BreadcrumbItem>
          <BreadcrumbItem active>Address</BreadcrumbItem>
        </Breadcrumb>
              
        <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Address</label>
                </div>
              </div>
            </div>

            <form autoComplete="off" onSubmit={this.updateAddress.bind(this)}>
              
              <div className="row row-small">
                <div className="col-md-4">
                  { this.state.address &&
                  <TextField
                    label="Name"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    name="name"
                    value={this.state.address.name}
                    onChange={this.handleChange.bind(this)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  }
                  { !this.state.address &&
                    <LoadingTextField
                    label="Name"
                      shrink={true}
                    />
                  }
                </div>
              </div>
              
              <div className="row row-small">
                <div className="col-md-4">
                  { this.state.address &&
                  <TextField
                    label="Address line"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    name="address"
                    value={this.state.address.address}
                    onChange={this.handleChange.bind(this)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  }
                  { !this.state.address &&
                    <LoadingTextField
                      label="Address line"
                      shrink={true}
                    />
                  }
                </div>
              </div>

              <div className="row row-small">
                <div className="col-md-4">
                  { this.state.address &&
                    <TextField
                      label="Postal code"
                      variant="outlined"
                      required
                      fullWidth
                      margin="dense"
                      name="postal_code"
                      value={this.state.address.postal_code}
                      onChange={this.handleChange.bind(this)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  }
                  { !this.state.address &&
                    <LoadingTextField
                      label="Postal code"
                      shrink={true}
                    />
                  }
                </div>
              </div>

              <div className="row row-small">
                <div className="col-md-4">
                  { this.state.address &&
                    <TextField
                      label="City"
                      variant="outlined"
                      required
                      fullWidth
                      margin="dense"
                      name="city"
                      value={this.state.address.city}
                      onChange={this.handleChange.bind(this)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  }
                  { !this.state.address &&
                    <LoadingTextField
                      label="City"
                      shrink={true}
                    />
                  }
                </div>
              </div>

              <div className="row row-small">
                <div className="col-md-4">
                  { this.state.address && this.state.countries &&
                    <Autocomplete
                      key="country_code"
                      options={this.state.countries.sort((a, b) => -b.name[0].localeCompare(a.name[0]))}
                      groupBy={this.firstLetter.bind(this)}
                      getOptionLabel={(option) => option.name}
                      onChange={this.handleAutoCompleteChange.bind(this)}
                      value={this.state.address.country}
                      renderInput={(params) => 
                        <TextField {...params}
                          label="Country"
                          variant="outlined"
                          required
                          fullWidth
                          margin="dense"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                          }}
                        />                      
                      }
                    />
                  }
                  { !this.state.address &&
                    <LoadingSelectField
                      label="Country"
                      shrink={true}
                    />
                  }
                </div>
              </div>

              <div className="row row-small">
                <div className="col-md">
                  <AsyncButton
                    type="submit"
                    variant="outlined"
                    color="primary"
                    disabled={this.state.address === undefined && this.state.countries === undefined}
                    icon="check"
                    text="Update" />
                  <AsyncButton
                    className="button-danger-outlined"
                    variant="outlined"
                    color="delete"
                    onClick={this.deleteAddress.bind(this)}
                    disabled={this.state.address === undefined && this.state.countries === undefined}
                    icon="delete"
                    text="Delete" />
                </div>
              </div>

            </form>
          
          </div>

        </div>
      </div>
    );
  }
}

export default ShowAddress;
