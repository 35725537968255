import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { LicenseManager } from  'ag-grid-enterprise'
import 'ag-grid-enterprise';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import ErrorHandler from '../util/error_handler';

LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-060451}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{CargoDesk_B.V.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{CargoDesk}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{CargoDesk}_need_to_be_licensed___{CargoDesk}_has_been_granted_a_Deployment_License_Add-on_for_{3}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{21_May_2025}____[v3]_[01]_MTc0Nzc4MjAwMDAwMA==3be5b4c7519a2a0f870235e77fbb264c");

class RowDataTable extends React.Component {

  constructor(props) {
    super(props)
    
    this.state = {
      rowModelType: this.props.rowModelType,
      columnDefs: this.props.columnDefs,
      treeData: this.props.treeData,
      getDataPath: this.props.getDataPath,
      groupDefaultExpanded: this.props.groupDefaultExpanded,
      autoGroupColumnDef: this.props.autoGroupColumnDef,
      overlayNoRowsTemplate: this.props.overlayNoRowsTemplate,
      pagination: this.props.pagination,
      suppressColumnMoveAnimation: true,
    };

    this.gridRef = React.createRef();
    this.gridWrapperRef = React.createRef();
  }

  componentDidMount() {
    this.setRowData();
  }

  componentDidUpdate(prevProps) {
    if(this.isRowDataUpdated(prevProps)) {
      if(prevProps.rowData && prevProps.rowData.totalElements > 0) {
        this.props.rowData.totalElements = prevProps.rowData.totalElements;
      }
      this.setRowData();
    }
  }

  isRowDataUpdated(prevProps) {
    return JSON.stringify(prevProps.rowData) !== JSON.stringify(this.props.rowData)
  }

  setRowData() {
    if(this.props.rowData) {
      this.setState({ rowData: this.props.rowData })
    }
  }

  getGridWrapperStyle() {
    if(!this.props.rowData) {
      return;
    }

    var minHeight = this.props.minHeight ? this.props.minHeight : 300;
    var maxHeight = window.innerHeight - minHeight;
    var headerHeight = 160;
    var rowHeight = 42;

    var rows = 0;
    if(this.props.pagination && this.gridRef.current && this.gridRef.current.api) {
      var pageNumber = this.gridRef.current.api.paginationGetCurrentPage();
      var pageSize = this.gridRef.current.api.paginationGetPageSize();
      var totalElements = this.props.rowData.totalElements;

      var paged = (pageNumber + 1) * pageSize;
      if(paged < totalElements) {
        rows = pageSize;
      } else {
        rows = totalElements - (pageNumber * pageSize);
      }
    } else {
      rows = this.props.rowData.totalElements
    }

    var height = (rows * rowHeight) + headerHeight;

    if(height > maxHeight) {
      height = maxHeight;
    }
    if(height < minHeight) {
      height = minHeight;
    }

    return { height: height + 'px', width: '100%' };
  }

  onPaginationChanged(params) {
    if(params.newPageSize && this.props.pagination) {
      this.setTableSettings(params);
    }
    this.updateTableHeight();

    if(params.newPage || params.newPageSize) {
      if(this.props.onPaginationChanged) {
        var page = params.api.paginationGetCurrentPage();
        var size = params.api.paginationGetPageSize();
        this.props.onPaginationChanged(page, size);
      }
    }
  }

  isDataReady() {
    return this.props.rowData && this.props.rowData.ready;
  }

  getSort() {
    if(this.gridRef.current && this.gridRef.current.api) {
      return this.toSort(this.gridRef.current.api.getColumnState());
    }
  }

  getFilters() {
    if(this.gridRef.current && this.gridRef.current.api) {
      return Object.entries(this.gridRef.current.api.getFilterModel()).map(filter => {
        return this.createFilter(filter);
      });
    }
    return [];
  }

  addFilter(property, value) {
    this.gridRef.current.api.setColumnFilterModel(property, {
      filterType: 'text',
      type: 'contains',
      filter: value,
   });
    this.gridRef.current.api.onFilterChanged();
  }

  onSortChanged(params) {
    this.setTableSettings(params);
    if(this.props.onSortChanged) {
      this.props.onSortChanged(this.getSort());
    }
  }

  onFilterChanged(params) {
    if(this.props.onFilterChanged) {
      this.props.onFilterChanged(params.api.getFilterModel());
    }
  }

  setTableSettings(params) {
    if(!this.isDataReady()) {
      return;
    }

    var columnState = params.api.getColumnState();
    var pageSize = params.api.paginationGetPageSize();
    if(pageSize == 0) {
      pageSize = this.getCurrentPageSize();
    }
    params.api.paginationSetPageSize(pageSize);
    params.api.setCacheBlockSize(pageSize);

    var tableState = JSON.stringify({
      columnState: columnState,
      pageSize: pageSize
    });
    localStorage.setItem(this.props.name, tableState);
  }

  onFirstDataRendered(params) {
    this.updateTableHeight();
    
    if(this.props.initialPage) {
      params.api.paginationGoToPage(Number(this.props.initialPage));
    }
    if(this.props.initialPageSize) {
      params.api.paginationSetPageSize(Number(this.props.initialPageSize));
    }

    this.props.rowData.ready = true;
  }

  onGridReady(params) {
    var state = this.getTableState();
    params.api.applyColumnState({ state: state.columnState, applyOrder: true } );
    
    const datasource = this.getServerSideDatasource();
    params.api.setGridOption('serverSideDatasource', datasource);
    
    if (this.props.initialSort) {
      var sort = [];
      var items = this.props.initialSort.split(",");
      for(var i = 0; i < items.length - 1; i += 2){
        sort.push({colId: items[i], sort: items[i + 1], sortIndex: i})
      }

      params.api.applyColumnState({
        state: sort,
        defaultState: { sort: null },
      });
    }
    if (this.props.initialFilters) {
      params.api.setFilterModel(this.props.initialFilters);
    }
  }
  
  getTableState() {
    var tableState = JSON.parse(localStorage.getItem(this.props.name));
    if (tableState) {
      return tableState;
    }
    return {};
  }

  getCurrentPageSize() {
    var tableState = this.getTableState();
    if(tableState.pageSize) {
      return tableState.pageSize;
    }
    return this.props.defaultPageSize;
  }

  createFilter(filter) {
    if(filter[1].filterType === "text" || filter[1].filterType === "number") {
      return {
        field: filter[0],
        comparator: filter[1].type,
        value: filter[1].filter
      }
    }

    if(filter[1].filterType === "set") {
      return {
        field: filter[0],
        comparator: "in",
        values: filter[1].values
      }
    }

    if(filter[1].filterType === "date") {
      if(filter[1].dateFrom) {
        var localDate = new Date(filter[1].dateFrom);        
        var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        var localISOTime = (new Date(localDate - tzoffset)).toISOString();
        var date = localISOTime.slice(0,10);
      }

      return {
        field: filter[0],
        comparator: filter[1].type,
        value: date
      }
    }
  }

  toSort(sortModel) {
    return sortModel
      .filter(s => s.sort !== null)
      .map(sort => {
        return sort.colId + "," + sort.sort;
      })
      .join(",");
  }

  getServerSideDatasource() {
    return {
      getRows: (params) => {
        var pageSize = this.getCurrentPageSize();
        var blockSize = params.request.endRow - params.request.startRow;
        var page = params.request.endRow / pageSize - 1;
        var sort = this.toSort(params.request.sortModel);
        
        var filters = Object.entries(params.request.filterModel).map(filter => {
          return this.createFilter(filter);
        });

        this.props.getRows(page, blockSize, sort, filters).then((response) => {
          if (response) {

            if (response.content.length === 0) {
              params.api.showNoRowsOverlay();
            } else {
              params.api.hideOverlay();
            }

            this.props.rowData.totalElements = response.total_elements;
            
            params.success({
              rowData: response.content,
              rowCount: response.total_elements
            });
          } else {
            params.fail();
          }
        }).catch(error => {
          ErrorHandler.showError(error);
          params.fail();
        });
      },
    };
  };

  updateTableHeight() {
    if(this.gridWrapperRef.current && this.gridWrapperRef.current.style && this.gridWrapperRef.current.style.height) {
      this.gridWrapperRef.current.style.height = this.getGridWrapperStyle().height;
    }
  }

  onRowClicked(event) {
    if(this.props.onRowClicked) {
      this.props.onRowClicked(event);
    }
  }

  getGridOptions() {
    if(this.props.onRowClicked) {
      return { suppressCellFocus: true }
    };
  }

  reloadData() {
    this.gridRef.current.api.refreshServerSide({ purge: true });
  }

  setGridOption(field, property) {
    this.gridRef.current.api.setGridOption(field, property);
  }
  
  render() {
    const sidebar = {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                  suppressRowGroups: true,
                  suppressValues: true,
                  suppressPivots: true,
                  suppressPivotMode: true
                }
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel'
            }
        ],
        position: 'right',
        defaultToolPanel: ''
    };

    return (
      <div ref={this.gridWrapperRef} className="grid-wrapper ag-theme-quartz" style={this.getGridWrapperStyle()} >
        <AgGridReact
            ref={this.gridRef}
            suppressColumnMoveAnimation={true}
            rowModelType={this.state.rowModelType}
            rowData={this.state.rowData ? this.state.rowData.data : undefined}
            columnDefs={this.state.columnDefs}
            autoSizeStrategy={{ type: 'fitGridWidth' }}
            treeData={this.state.treeData}
            getDataPath={this.state.getDataPath}
            groupDefaultExpanded={this.state.groupDefaultExpanded}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
            suppressAggFuncInHeader={true}
            suppressDragLeaveHidesColumns={true}
            pagination={this.state.pagination}
            paginationPageSize={this.getCurrentPageSize()}
            cacheBlockSize={this.getCurrentPageSize()}
            paginationPageSizeSelector={[10, 20, 50, 100, 300]}
            onSortChanged={this.onSortChanged.bind(this)}
            onFilterChanged={this.onFilterChanged.bind(this)}
            onColumnMoved={this.setTableSettings.bind(this)}
            onColumnVisible={this.setTableSettings.bind(this)}
            onColumnResized={this.setTableSettings.bind(this)}
            onPaginationChanged={this.onPaginationChanged.bind(this)}
            onFirstDataRendered={this.onFirstDataRendered.bind(this)}
            onGridReady={this.onGridReady.bind(this)}
            suppressBrowserResizeObserver={true}
            sideBar={sidebar}
            onRowClicked={this.onRowClicked.bind(this)}
            gridOptions={this.getGridOptions()}
        />
      </div>
    );
  }
}

export default RowDataTable;
